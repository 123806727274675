import axios from "axios";
import $ from "jquery";
import { TTP_API_URL, CLIENT_CREDENTIAL } from "config";

export const getTTPUser = ({ userId, token }) => {
  const filter = [
    {
      property: "id",
      value: userId,
      operator: "eq",
    },
  ];

  const fields = [
    "*",
    "communities",
    "avatar",
    "description",
    "contactSocialNetworks",
    "cover",
    "url",
    "role",
    "roles",
    "userSettings",
  ];

  const requestUrl = `${TTP_API_URL}/organization/user`;

  /*let params = {
    access_token: token,
    filter: JSON.stringify(filter),
    fields: fields.join(",")
  };*/

  return $.ajax({
    type: "GET",
    async: false,
    url: requestUrl,
    data: `access_token=${token}&fields=${fields.join(
      ","
    )}&filter=${JSON.stringify(filter)}`,
  });

  /*return axios.get(requestUrl, {
    //cancelToken: getLatestArticlesSourceToken.token,
    params
  });*/
};

export const getClientCredential = () => {
  const requestUrl = `${TTP_API_URL}/token`;

  return $.ajax({
    type: "POST",
    async: false,
    url: requestUrl,
    data: CLIENT_CREDENTIAL,
  });
};

export const setSelectedOrganization = ({ token, organizationId }) => {
  const requestUrl = `${TTP_API_URL}/organization/user/save-selected-organization`;

  let formData = new FormData();
  formData.append("access_token", token);
  formData.append("organizationId", organizationId);

  return axios.post(requestUrl, formData);
};

export const getSelectedOrganization = (token) => {
  const requestUrl = `${TTP_API_URL}/organization/user/get-selected-organization`;

  return $.ajax({
    type: "GET",
    async: false,
    url: requestUrl,
    data: `access_token=${token}&fields=${[
      "id",
      "uen",
      "name",
      "url",
      "abbreviation",
    ].join(",")}`,
  });

  /*return axios.get(requestUrl, {
    params: {
      access_token: token,
      fields: ["id", "uen", "name", "url", "abbreviation"].join(",")
    }
  });*/
};

export const getActiveApps = (token, organizationId) => {
  const requestUrl = `${TTP_API_URL}/organization/organization/apps-activation-status`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organizationId,
    },
  });
};

export const getPublicOrganization = (token, subdomain) => {
  const filter = [
    { property: "subdomain", value: subdomain, operator: "like" },
  ];

  const fields = [
    "id",
    "uen",
    "name",
    "address1",
    "address2",
    "zipCode",
    "city",
    "country",
    "avatarWebPath",
    "phone",
    "email",
    "latitude",
    "longitude",
    "abbreviation",
    "schedule",
  ];

  const requestUrl = `${TTP_API_URL}/organization/organization`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      fields: fields.join(","),
      filter: JSON.stringify(filter),
    },
  });
};

export const saveCommunityLayoutSettings = ({
  token,
  communityId,
  layoutSettings,
}) => {
  const requestUrl = `${TTP_API_URL}/organization/organization-settings/save-hub-layout-preferences?access_token=${token}&organization_id=${communityId}`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("organization_id", communityId);
  formData.append("layout_settings", JSON.stringify(layoutSettings));

  return axios.post(requestUrl, formData);
};

export const saveCommunityDrawersSettings = ({
  token,
  communityId,
  drawers,
}) => {
  const requestUrl = `${TTP_API_URL}/organization/organization-settings/save-hub-drawers-preferences?access_token=${token}&organization_id=${communityId}`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("organization_id", communityId);
  formData.append("drawers", JSON.stringify(drawers));

  return axios.post(requestUrl, formData);
};

export const getCurrentOrganization = ({ token, communityId }) => {
  const filter = [{ property: "id", value: communityId, operator: "eq" }];

  const fields = ["id", "name", "hubPreferences"];

  const requestUrl = `${TTP_API_URL}/organization/organization`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      fields: fields.join(","),
      filter: JSON.stringify(filter),
      workspace: "user",
    },
  });
};
