import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { getAppRoutes, getPageRoutes } from "router/getRoutes";
import { login } from "../../actions/thunks";

// import ScrollToTop from "Common/ScrollToTop";
import Layout from "Layout/Layout";
// import SelectCommunity from "SelectCommunity/SelectCommunity";
//import Home from "Home/Home";

export class App extends Component {
  state = {
    loaded: false,
  };

  static propTypes = {
    navCommunity: PropTypes.object,
    user: PropTypes.object,
    login: PropTypes.func.isRequired,
  };

  componentDidMount(prevProps) {
    if (this.props.user === null) {
      $("#app-loader")
        .fadeOut()
        .promise()
        .done(() => $("#app-loader").remove());
    }
  }

  render() {
    const { navCommunity } = this.props;

    // if (window.location.origin === TTP_HUB_URL && !navCommunity) {
    //   return <SelectCommunity />;
    // }

    return (
      <>
        <Switch>
          {getPageRoutes()}
          <Route path="*">
            <Layout>{getAppRoutes()}</Layout>
          </Route>
        </Switch>
        <ToastContainer autoClose={3000} />
      </>
    );
  }
}

// Connected Component
const mapStateToProps = (state) => ({
  navCommunity: state.auth.navCommunity,
  user: state.auth.user,
  loggedAs: state.auth.loggedAs,
  router: state.router,
});

const mapDispatchToProps = (dispatch) => ({
  login: () => dispatch(login()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
