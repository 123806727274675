import React, { PureComponent } from "react";
import Modal from "react-modal";

import IconTrash from "../Icons/IconTrash";
import IconClose from "../Icons/IconClose";
import IconLoader from "../Icons/IconLoader";

import styles from "./DeleteConfirm.module.scss";
import _ from "i18n";

export default class DeleteConfirm extends PureComponent {
  render() {
    const {
      isOpen,
      onCancel,
      onConfirm,
      deleting,
      deletingError,
      text = "",
    } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onCancel}
        className={{
          base: styles.modalContent,
          afterOpen: styles.modalContentAfterOpen,
          beforeClose: styles.modalContentBeforeClose,
        }}
        overlayClassName={styles.modalOverlay}
        closeTimeoutMS={200}
        contentLabel="Delete Confirmation"
      >
        <div className={`${styles.confirm}`}>
          <div className={styles.header}>
            {_("Confirmation of deletion")}
            <div className={styles.icon}>
              <IconTrash size={26} />
            </div>
          </div>
          <div className={styles.close} onClick={onCancel}>
            <IconClose size={14} />
          </div>
          <div className={styles.body}>
            {text || _("Are you sure you want to delete this item ?")}
          </div>
          <div className={styles.footer}>
            <button className={styles.no} onClick={onCancel}>
              {_("No")}
            </button>
            {!deleting && !deletingError && (
              <button className={styles.yes} onClick={onConfirm}>
                {_("Yes")}
              </button>
            )}
            {deleting && !deletingError && (
              <div className={styles.deleting}>
                <IconLoader size={20} />
              </div>
            )}
            {!deleting && deletingError && <div>Error</div>}
          </div>
        </div>
      </Modal>
    );
  }
}
