import React, { Component } from "react";
import { connect } from "react-redux";
import { openMediaViewer } from "actions";

import MediaComponent from "./MediaComponent";

export class MediaContainer extends Component {
  handleOpenMediaReveal = (e) => {
    const { openMediaViewer, media, openViewer } = this.props;

    openMediaViewer({
      medias: [media],
      isOpen: true,
      currentMedia: media,
    });
    openViewer(media);
  };
  render() {
    const { community, media, language, template, col } = this.props;

    return (
      <MediaComponent
        media={media}
        community={community}
        language={language}
        template={template}
        col={col}
        openMediaViewer={this.handleOpenMediaReveal}
      />
    );
  }
}

// Connected Component
const mapStateToProps = (state) => ({
  language: state.params.lng,
});

const mapDispatchToProps = (dispatch) => ({
  openMediaViewer: (viewer) => dispatch(openMediaViewer(viewer)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MediaContainer);
