const initialState = {
  fetching: false,
  fetched: false,
  items: [],
  error: null,
  nbResult: 0,
  saving: false,
  savingError: null,
  deleting: false,
  deletingError: null,
};
export const pagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case `RESET_PAGES`: {
      return initialState;
    }
    case `FETCH_PAGES_PENDING`: {
      return { ...state, fetched: false, fetching: true };
    }
    case `FETCH_PAGES_FULFILLED`: {
      const { data, nbResult } = action.payload.data;
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: data,
        nbResult: nbResult,
      };
    }
    case `FETCH_PAGES_REJECTED`: {
      let error = action.payload;
      switch (error.response.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        error,
        items: [],
        nbResult: 0,
      };
    }
    case `SAVE_PAGE_PENDING`: {
      return { ...state, saving: true, savingError: null };
    }
    case `SAVE_PAGE_FULFILLED`: {
      const { data } = action.payload;
      return {
        ...state,
        saving: false,
        savingError: null,
        items: state.items.concat(data),
        nbResult: state.nbResult + 1,
      };
    }
    case `SAVE_PAGE_REJECTED`: {
      let error = action.payload;
      switch (error.response.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        saving: false,
        savingError: error,
      };
    }
    case "DELETE_PAGE_PENDING": {
      return { ...state, deleting: true, deleted: false, deletingError: null };
    }

    case `DELETE_PAGE_FULFILLED`: {
      const { deletedId } = action.payload.data;

      return {
        ...state,
        deleted: true,
        deleting: false,
        deletingError: null,
        items: [...state.items].filter((item) => item.id !== deletedId),
        nbResult: state.nbResult - 1,
      };
    }

    case "DELETE_PAGE_REJECTED": {
      return {
        ...state,
        deleted: false,
        deleting: false,
        deletingError: action.payload || "Unknown Deleting Error",
      };
    }
    default:
      return state;
  }
};
