import React, { PureComponent } from "react";

import { TTP_API_URL } from "config";
import styles from "./Media.module.scss";
import { getDateLabel, addLandaSize } from "utils";
import classnames from "classnames";
import _ from "i18n";

export default class MediaComponent extends PureComponent {
  state = {
    play: false,
  };

  getPDFPathFromPPT = (media) => {
    const path = (media.fullMediaUrl
      ? media.fullMediaUrl
      : TTP_API_URL + "/" + media.webPath
    ).substring(
      0,
      media[media.fullMediaUrl ? "fullMediaUrl" : "webPath"].lastIndexOf("/")
    );

    return `${path}/PDF/${media.id}.pdf`;
  };

  openBlank = (url) => {
    window.open(url, "_blank");
    //win.focus();
  };

  renderAlbumMedia(path, url) {
    const { language, media, template, openMediaViewer } = this.props;
    const { createdAt, docType } = media;
    const titleAttr = `title${
      language.charAt(0).toUpperCase() + language.slice(1)
    }`;
    return (
      <a
        className={classnames(
          styles.media,
          styles.docPreview,
          docType === "PPT" ? styles.pptPreview : ""
        )}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div
          className={styles.mediaContent}
          style={{ backgroundImage: `url(${path})` }}
        >
          <div className={styles.infos}>
            <h3>{media[titleAttr]}</h3>
            <span className={styles.date}>{getDateLabel(createdAt)}</span>
          </div>
        </div>
      </a>
    );
  }

  renderAlbumMediaT5(path, url) {
    const { language, media } = this.props;
    const titleAttr = `title${
      language.charAt(0).toUpperCase() + language.slice(1)
    }`;
    return (
      <a
        className={classnames(styles.media, styles.mediaT5)}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div
          className={styles.mediaContent}
          style={{ backgroundImage: `url(${path})` }}
        />
        <div className={styles.info}>
          <h4>{media[titleAttr]}</h4>
          <span>Type: {media.docType}</span>
        </div>
      </a>
    );
  }

  renderDocs(path, url) {
    const { language, media, template, openMediaViewer } = this.props;
    const { createdAt, docType } = media;
    const titleAttr = `title${
      language.charAt(0).toUpperCase() + language.slice(1)
    }`;
    if (template === "T2") {
      return (
        <div
          className={classnames(
            styles.media,
            styles.docPreview,
            docType === "PPT" ? styles.pptPreview : ""
          )}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          onClick={openMediaViewer.bind(this)}
        >
          <div
            className={styles.mediaContent}
            style={{ backgroundImage: `url(${path})` }}
          >
            <div className={styles.infos}>
              <h3>{media[titleAttr]}</h3>
              <span className={styles.date}>{getDateLabel(createdAt)}</span>
            </div>
          </div>
        </div>
      );
    }
    return (
      <a
        className={styles.media}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className={styles.docs}>
          <img src={`/img/${docType.toLowerCase()}.png`} alt="" />
          <div className={styles.docInfo}>
            <h3>{media[titleAttr]}</h3>
            <span className={styles.date}>{getDateLabel(createdAt)}</span>
          </div>
        </div>
      </a>
    );
  }

  renderAlbumOverflow(album) {
    const { language } = this.props;

    const titleAttr = `title${
      language.charAt(0).toUpperCase() + language.slice(1)
    }`;
    const descAttr = `description${
      language.charAt(0).toUpperCase() + language.slice(1)
    }`;
    const nameAttr = `name${
      language.charAt(0).toUpperCase() + language.slice(1)
    }`;
    return (
      <div className={styles.overflow}>
        <h1>{album[titleAttr]}</h1>
        <p>{album[descAttr] || ""}</p>
        <span className={styles.countDocs}>
          <i className="icon icon-docs" />
          <span>
            {album.countMedias} {_("Documents")}
          </span>
        </span>
        {album.tags ? (
          <ul className={styles.tags}>
            {album.tags.map((tag) => (
              <li>{tag[nameAttr]}</li>
            ))}
          </ul>
        ) : null}
      </div>
    );
  }

  render() {
    const { media, template, col } = this.props;
    const { preview, fullMediaUrl, webPath, docType } = media;

    const url =
      docType !== "PPT"
        ? fullMediaUrl
          ? fullMediaUrl
          : `${TTP_API_URL}/${webPath}`
        : this.getPDFPathFromPPT(media);

    let path = null;
    if (
      docType === "VIDEO" &&
      preview &&
      (preview.fullMediaUrl || preview.webPath)
    ) {
      path = addLandaSize(
        preview.fullMediaUrl
          ? preview.fullMediaUrl
          : TTP_API_URL + "/" + preview.webPath,
        720
      );
    } else {
      path = addLandaSize(url, 720);
    }

    if (template === "T5") {
      return this.renderAlbumMediaT5(path, url);
    }

    if (docType === "PPT" || docType === "PDF") {
      return this.renderDocs(path, url);
    }

    if (template === "T2") {
      return this.renderAlbumMedia(path, url);
    }

    let helpCol = null;

    if (col) {
      helpCol = col > 12 ? 24 : col;
    }

    return (
      <a
        className={classnames(
          styles.media,
          helpCol ? styles[`col-${helpCol}`] : ""
        )}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {docType === "VIDEO" && this.state.play ? (
          <video
            src={media.fullMediaUrl}
            controls
            autoPlay
            muted
            style={{
              maxHeight: "100%",
              width: "100%",
            }}
            onMouseLeave={(e) => {
              this.setState({ play: false });
            }}
          />
        ) : (
          <div
            className={styles.mediaContent}
            style={{ backgroundImage: `url(${path})` }}
            onMouseEnter={(e) => {
              this.setState({ play: true });
            }}
          >
            {docType === "VIDEO" ? (
              <div className={styles.play}>
                <img src="/img/video.png" alt="" />
              </div>
            ) : null}
          </div>
        )}
        {helpCol && helpCol === 24 && media.album
          ? this.renderAlbumOverflow(media.album)
          : null}
      </a>
    );
  }
}

// Conn
