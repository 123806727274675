import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Route } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";

import { getHistory } from "./router/getHistory";
import { createStore } from "./store/createStore";
// import { handleTalkWidget } from "./talk";
import App from "./components/App/App";

// Load App styles
import "./styles/global.scss";

// Create Redux store
const store = createStore();

// Install Talk Widget
// handleTalkWidget(store);

// Get browser history
const history = getHistory();

// Render App
ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Route component={App} />
      </ConnectedRouter>
    </Provider>
  </StrictMode>,
  document.getElementById("app")
);
