import React from "react";
import { connect } from "react-redux";
import _ from "i18n";
import { toast } from "react-toastify";
import { validateEmail } from "utils";
import { TTP_API_URL } from "config";
import axios from "axios";
import styles from "./ArticleOrganization.module.scss";

class Subscribe extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      status: "",
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.subscribe = this.subscribe.bind(this);
  }

  handleInputChange({ target }) {
    this.setState({
      email: target.value,
    });
  }

  subscribe() {
    const { email } = this.state;
    const { organizationId, lng } = this.props;
    let errors = [];

    if (
      email.trim().length === 0 ||
      (email && email.trim().length !== 0 && !validateEmail(email.trim()))
    ) {
      errors.push(_("You must provide a valid email address."));
    }

    let ErrorsContainer = ({ errors }) => (
      <div>
        <span>{_("Errors") + " :"}</span>
        <ul>
          {errors.map((e, i) => (
            <li key={i}>{e}</li>
          ))}
        </ul>
      </div>
    );

    if (errors && errors.length > 0) {
      toast.error(<ErrorsContainer errors={errors} />, { autoClose: true });
      return;
    }

    const requestUrl = `${TTP_API_URL}/mailing/prospect`;

    this.setState({
      status: "pending",
    });

    let formData = new FormData();
    formData.append("email", email);
    formData.append("firstName", "");
    formData.append("lastName", "");
    formData.append("client", organizationId);
    formData.append("language", lng);

    let self = this;

    let InfosContainer = ({ errors }) => (
      <div>
        <span>
          {errors.map((e, i) => (
            <li key={i}>{e}</li>
          ))}
        </span>
      </div>
    );
    axios.post(requestUrl, formData).then((rep) => {
      self.setState({
        status: "ready",
        email: "",
      });
      toast.info(
        <InfosContainer errors={[_("Your registration was successful")]} />,
        { autoClose: true }
      );
    });
  }

  render() {
    const { isAuth, organizationId } = this.props;

    const { email } = this.state;

    return (
      <div className={styles.subscribeSection}>
        <div className={styles.subscribeSection__text}>
          {_("Subscribe to our newsletter")}
        </div>
        <div className={styles.subscribeSection__subtext}>
          {_("Receive all the news published by our community")}
        </div>
        <input
          className={styles.subscribeSection__input}
          value={email}
          onChange={this.handleInputChange}
        />
        <button
          className={styles.subscribeSection__btn}
          type="primary"
          size="lg"
          onClick={this.subscribe}
        >
          {_("Register")}
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuth: state.auth.user,
    lng: state.params.lng,
  };
};

export default connect(mapStateToProps)(Subscribe);
