import React, { PureComponent } from "react";

export default class OrganizationInfos extends PureComponent {
  render() {
    const { community } = this.props;
    return (
      <div className={`fiduciary-infos cell ${this.props.size}`}>
        <h1 className="fiduciary-name">
          {community.abbreviation ? community.abbreviation : community.name}
        </h1>
        <ul className="fiduciary-contact">
          {!community.address1 &&
          !community.zipCode &&
          !community.city ? null : (
            <li>
              <i className="icon icon-location-pin" />
              <span>
                {community.address1 ? community.address1 : ""}
                {community.address2 ? " " + community.address2 : ""}
                {community.zipCode ? " - " + community.zipCode : ""}
                {community.city ? " " + community.city : ""}
              </span>
            </li>
          )}
          {!community.phone ? null : (
            <li>
              <i className="icon icon-phone" />
              <span>Tel. {community.phone}</span>
            </li>
          )}
          {!community.fax ? null : (
            <li>
              <i className="icon icon-printer" />
              <span>Fax. {community.fax}</span>
            </li>
          )}
          {!community.email ? null : (
            <li>
              <i className="icon icon-paper-plane" />
              <span>{community.email}</span>
            </li>
          )}
        </ul>
        <ul className="other-infos">
          <li>LDE</li>
          {!community.uen || !community.country ? null : (
            <li>{`TVA ${community.country} ${community.uen}`}</li>
          )}
          {/*}<li>RPM Liège division Arlon</li>
          <li>Assurances du Notariat scrl</li>*/}
        </ul>
      </div>
    );
  }
}
