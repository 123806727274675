import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import { getHistory } from "../router/getHistory";

import {
  authReducer,
  languageReducer,
  currentNavPageReducer,
  sourcesTokensReducer,
  notificationsReducer,
  notifsReducer,
  pagesReducer,
  mediaViewerReducer,
} from "../reducers";

const history = getHistory();

export const createReducer = () =>
  combineReducers({
    auth: authReducer,
    params: combineReducers({
      lng: languageReducer,
      currentNavPage: currentNavPageReducer,
      sourcesTokens: sourcesTokensReducer,
      notification: notificationsReducer,
    }),
    router: connectRouter(history),
    notifs: notifsReducer,
    viewer: mediaViewerReducer,
    pages: pagesReducer,
  });
