import React, { PureComponent } from "react";
import _ from "i18n";
import { TTP_API_URL } from "config";

export default class Subheader extends PureComponent {
  componentDidMount() {
    window.onscroll = () => {
      if (
        document.body.scrollTop > 65 ||
        document.documentElement.scrollTop > 65
      ) {
        document.getElementById("portal-header").className = "fixed";
      } else {
        document.getElementById("portal-header").className = "";
      }
      const portalHeader = document.getElementById("portal-header");
      if (portalHeader) {
        const btns = portalHeader.getElementsByTagName("li");
        if (btns && btns.length > 0) {
          for (let i = 0; i < btns.length; i++) {
            const targetDiv = document.getElementById(
              btns[i].id.replace("-btn", "")
            );
            if (
              targetDiv &&
              window.pageYOffset > targetDiv.offsetTop - 85 &&
              window.pageYOffset <
                targetDiv.offsetTop + targetDiv.offsetHeight - 85
            ) {
              btns[i].classList.add("activated");
            } else if (btns[i].classList.contains("activated")) {
              btns[i].classList.remove("activated");
            }
          }
        }
      }
    };
  }

  scrollToBloc(divId, e) {
    const targetDiv = document.getElementById(divId);
    if (!targetDiv) {
      return null;
    }
    window.scrollTo({
      top: targetDiv.offsetTop - 75,
      left: 0,
      behavior: "smooth",
    });
  }

  render() {
    const { community, blocs } = this.props;
    const { avatarUrl, name } = community;

    return (
      <section id="portal-header">
        <div className="fid-logo">
          {!avatarUrl ? null : <img src={avatarUrl} alt={name} />}
        </div>
        <ul className="portal-sub-menu">
          {blocs.news.activated && (
            <li
              id="fiduciary-news-btn"
              className="new-btn"
              onClick={this.scrollToBloc.bind(this, "fiduciary-news")}
            >
              {_("News")}
            </li>
          )}
          {blocs.team.activated && (
            <li
              id="fiduciary-team-btn"
              className="new-btn"
              onClick={this.scrollToBloc.bind(this, "fiduciary-team")}
            >
              {_("Team")}
            </li>
          )}
          {blocs.documents.activated && (
            <li
              id="portal-docs-btn"
              className="new-btn"
              onClick={this.scrollToBloc.bind(this, "portal-docs")}
            >
              {_("Documents")}
            </li>
          )}
          {blocs.contact.activated && (
            <li
              id="portal-contact-btn"
              className="new-btn"
              onClick={this.scrollToBloc.bind(this, "portal-contact")}
            >
              {_("Contacts")}
            </li>
          )}
        </ul>
      </section>
    );
  }
}
