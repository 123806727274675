import { applyMiddleware, compose } from "redux";
import { routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import promise from "redux-promise-middleware";
import { composeWithDevTools } from "redux-devtools-extension";

import { getHistory } from "../router/getHistory";
import { APP_ENV } from "../config";

const history = getHistory();

const composeEnhancers = APP_ENV === "prod" ? compose : composeWithDevTools;

// PROD Middlewares
const prodMiddlewares = [routerMiddleware(history), thunk, promise()];

// DEV Middlewares
const devMiddlewares = [...prodMiddlewares, createLogger()];

const middlewares = APP_ENV === "prod" ? prodMiddlewares : devMiddlewares;

export const createEnhancer = () => {
  return composeEnhancers(applyMiddleware(...middlewares));
};
