import React, { PureComponent, Fragment } from "react";

import _ from "i18n";
import { TTP_API_URL } from "config";

import { purifyString } from "utils";
import classnames from "classnames";
import styles from "./Viewer.module.scss";

export default class Content extends PureComponent {
  getPDFPathFromPPT = (media) => {
    const path = (media.fullMediaUrl
      ? media.fullMediaUrl
      : TTP_API_URL + "/" + media.webPath
    ).substring(
      0,
      media[media.fullMediaUrl ? "fullMediaUrl" : "webPath"].lastIndexOf("/")
    );

    return `${path}/PDF/${media.id}.pdf`;
  };

  renderMedia() {
    const { currentMedia } = this.props.viewer;
    const { asMagazine } = this.props;

    const path =
      currentMedia.docType !== "PPT"
        ? currentMedia.fullMediaUrl
          ? currentMedia.fullMediaUrl
          : `${TTP_API_URL}/${currentMedia.webPath}`
        : this.getPDFPathFromPPT(currentMedia);

    switch (currentMedia.docType) {
      case "VIDEO":
        return (
          <video
            src={path}
            controls
            autoPlay
            style={{
              minWidth: "500px",
            }}
          />
        );

      case "PDF":
      case "PPT":
        return asMagazine ? (
          <iframe
            className={styles.pdf_viewer}
            style={{
              width: "100%",
              height: "100vh",
            }}
            title={currentMedia.title}
            src={`/pdf-flipbook/index.html?file=${encodeURIComponent(
              path
            )}&flip=true`}
          />
        ) : (
          <iframe
            className={styles.pdf_viewer}
            style={{
              width: "100%",
              height: "100vh",
            }}
            title={currentMedia.title}
            src={`/pdf-viewer/web/viewer.html?file=${encodeURIComponent(path)}`}
          />
        );
      default:
        return <img alt="" src={path} /*style={styleDimension}*/ />;
    }
  }

  render() {
    const { language, viewer, navCommunity } = this.props;
    const { currentMedia, medias } = viewer;

    if (!currentMedia || !medias) {
      return null;
    }

    const { organization, docType, fullMediaUrl, webPath, tags } = currentMedia;

    const path =
      docType !== "PPT"
        ? fullMediaUrl
          ? fullMediaUrl
          : `${TTP_API_URL}/${webPath}`
        : this.getPDFPathFromPPT(currentMedia);

    const titleAttr = `title${
      language.charAt(0).toUpperCase() + language.slice(1)
    }`;

    const nameAttr = `name${
      language.charAt(0).toUpperCase() + language.slice(1)
    }`;

    const descAttr = `description${
      language.charAt(0).toUpperCase() + language.slice(1)
    }`;

    const isDoc = docType === "PDF" || docType === "PPT";

    return (
      <Fragment>
        <div className="cell rmodal__body">
          <div
            className={classnames(
              styles.media_container,
              isDoc ? styles.isDoc : "",
              "article-content rmodal__left"
            )}
          >
            {isDoc ? null : (
              <div className={styles.download}>
                <a href={path} download>
                  {_("Download")}
                  <i className="icon-cloud-download" />
                </a>
              </div>
            )}
            <div
              className={classnames(
                styles.media,
                docType === "PDF" || docType === "PPT" ? styles.pdf : ""
              )}
              style={
                !organization && (docType === "PDF" || docType === "PPT")
                  ? { height: "85%" }
                  : null
              }
            >
              {this.renderMedia()}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
