import React, { PureComponent } from "react";
import Subscribe from "./Subscribe";
import { getCommunityDisplayName, getCommunityLogoUrl } from "utils";

import styles from "./ArticleOrganization.module.scss";

export default class ArticleOrganization extends PureComponent {
  render() {
    const { communityId, organization } = this.props;

    return (
      <div className={styles.articleOrganization}>
        {organization && (
          <>
            <div className={styles.articleOrganization__content}>
              {/* <div className={styles.articleOrganization__logo}>
                <img src={getCommunityLogoUrl(organization)} />
              </div> */}
            </div>
            <div className={styles.articleOrganization__title}>
              {getCommunityDisplayName(organization)}
            </div>
          </>
        )}
        <div className={styles.articleOrganization__subscribe}>
          <Subscribe organizationId={communityId} />
        </div>
      </div>
    );
  }
}
