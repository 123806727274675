import React, { PureComponent } from "react";
import styles from "./Close.module.scss";

export default class CloseViewer extends PureComponent {
  render() {
    return (
      <div className={styles.close}>
        <button className={styles.btn} onClick={this.props.closeViewer} />
      </div>
    );
  }
}
