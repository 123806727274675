import React, { PureComponent } from "react";
import Slider from "react-slick";

import EventComponent from "../Event/EventComponent";

import { TTP_EVENT_URL } from "config";

import styles from "./Cycle.module.scss";
import classnames from "classnames";
import { getDateLabel } from "utils";
import _ from "i18n";

export default class CycleComponent extends PureComponent {
  render() {
    const { language, cycle } = this.props;

    const step = 3;
    const {
      id,
      isReplayable,
      startDateTime,
      endDateTime,
      allEventsPrices,
      memberPrice,
      eventCycles,
    } = cycle;

    const settings = {
      dots: true,
      dotsClass: classnames(styles.dots, "slick-dots"),
      infinite: false,
      arrows: true,
      speed: 500,
      autoplay: false,
      className: styles.slider,
    };

    let start = getDateLabel(startDateTime).split(",")[0];
    const end = getDateLabel(endDateTime).split(",")[0];

    const spStart = start.split(" ");

    if (spStart[2] === end.split(" ")[2]) {
      start = start.substr(0, 6);
    }

    let duration = 0;
    for (let i = 0; i < eventCycles.length; i++) {
      const { eventsAbstract } = eventCycles[i];
      duration +=
        new Date(eventsAbstract.endDateTime).getTime() -
        new Date(eventsAbstract.startDateTime).getTime();
    }

    const hours = Math.floor(duration / 1000 / 60 / 60);
    duration -= hours * 1000 * 60 * 60;

    const minutes = Math.floor(duration / 1000 / 60);

    const imgAttr = `pictureUrl${
      language.charAt(0).toUpperCase() + language.slice(1)
    }`;

    const nameAttr = `name${
      language.charAt(0).toUpperCase() + language.slice(1)
    }`;

    const listItems = [];

    if (eventCycles) {
      const newPrice = Math.ceil(memberPrice / eventCycles.length);
      eventCycles.forEach((event) => {
        listItems.push(
          <li
            className={`cell smal-12 medium-4`}
            key={`event-container-${event.id}`}
          >
            <EventComponent
              event={event.eventsAbstract}
              language={language}
              fromCycle={true}
              newPrice={newPrice}
            />
          </li>
        );
      });
    }

    let blocs = [];

    for (let i = 0; i < listItems.length; i += step) {
      blocs[i / step] = [];
      for (let j = i; j < Math.min(listItems.length, i + step); j++) {
        blocs[i / step].push(listItems[j]);
      }
    }

    return (
      <div className={styles.cycle}>
        <div
          className={classnames(styles.banner, "grid-x")}
          style={{
            backgroundImage: `linear-gradient(rgba(41, 57, 77, 0.7) 0%, rgba(219, 105, 57, 0.7) 100%), url("https://s3.tamtam.pro/v2${cycle[imgAttr]}")`,
          }}
        >
          <div className={classnames(styles.details, "cell small-12 medium-4")}>
            <div className={styles.general}>
              <div className={classnames(styles.price, styles.card)}>
                <strong>{memberPrice} €</strong>
                {allEventsPrices && allEventsPrices.member ? (
                  <span className={styles.barred}>
                    {allEventsPrices.member} €
                  </span>
                ) : null}
              </div>
              <div className={styles.timing}>
                <ul>
                  <li className={styles.card}>
                    <i className="icon icon-graduation" />
                    <label>
                      {eventCycles.length} {_("Training")}
                    </label>
                  </li>
                  <li className={styles.card}>
                    <i className="icon icon-clock" />
                    <label>
                      {hours}h {minutes}min
                    </label>
                  </li>
                </ul>
                {isReplayable && isReplayable === 1 ? (
                  <div className={classnames(styles.training, styles.card)}>
                    <i className="icon icon-control-play" />
                    <label>{_("Includes option: Replay")}</label>
                  </div>
                ) : null}
              </div>
            </div>
            <h3 className={styles.title}>{cycle[nameAttr]}</h3>
            <ul className={styles.infos}>
              <li>
                <i className="icon icon-calendar" />
                <span>{`${_("From")} ${start} ${_("to")} ${end}`}</span>
              </li>
              {isReplayable && isReplayable === 1 ? (
                <li>
                  <i className="icon icon-globe" />
                  <span>{_("Webinar")}</span>
                </li>
              ) : null}
              {isReplayable && isReplayable === 1 ? (
                <li>
                  <i className="icon icon-control-play" />
                  <span>
                    {_(
                      "Possible to review the formations for a maximum of 72 hours after the date of the live"
                    )}
                  </span>
                </li>
              ) : null}
            </ul>
            <div className={styles.controls}>
              <a
                className={classnames(styles.btn, styles.btnPrimary)}
                href={`${TTP_EVENT_URL}/plan-selector?cycleId=${id}`}
                target="_blank"
              >
                {_("Buy the cycle")}
              </a>
              <a
                className={classnames(styles.btn, styles.btnSecondary)}
                href={`${TTP_EVENT_URL}/cycle/${id}/reception`}
                target="_blank"
              >
                {_("Cycle details")}
              </a>
            </div>
          </div>
          <div
            id="slick-slider"
            className={classnames(styles.slider, "cell small-12 medium-8")}
          >
            <div className={styles.explore}>
              <a href={`${TTP_EVENT_URL}/cycle/${id}/events`} target="_blank">
                {_("Explore")}
              </a>
            </div>
            <Slider {...settings}>
              {blocs.map((bloc) => (
                <div className="slick-block" key={Math.random()}>
                  <ul className={classnames(styles.list, "grid-x")}>{bloc}</ul>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    );
  }
}
