export const APP_ENV = process.env.REACT_APP_APP_ENV;
export const TTP_API_URL = process.env.REACT_APP_TTP_API_URL;
export const TTP_HUB_URL = process.env.REACT_APP_TTP_HUB_URL;
export const TTP_MEDIA_URL = process.env.REACT_APP_TTP_MEDIA_URL;
export const TTP_PORTAL_URL = process.env.REACT_APP_TTP_PORTAL_URL;
export const TTP_BLOG_URL = process.env.REACT_APP_TTP_BLOG_URL;
export const TTP_FORUM_URL = process.env.REACT_APP_TTP_FORUM_URL;
export const TTP_FLUX_URL = process.env.REACT_APP_TTP_FLUX_URL;
export const TTP_WEBTOOL_URL = process.env.REACT_APP_TTP_WEBTOOL_URL;
export const TTP_EVENT_URL = process.env.REACT_APP_TTP_EVENT_URL;
export const TTP_EMAILING_URL = process.env.REACT_APP_TTP_EMAILING_URL;
export const TTP_HOME_URL = process.env.REACT_APP_TTP_HOME_URL;
export const TTP_FFF_BLOG = process.env.REACT_APP_TTP_FFF_BLOG;
export const TTP_DEG_BLOG = process.env.REACT_APP_TTP_DEG_BLOG;
export const TTP_BE_ACCOUNTANTS_BLOG =
  process.env.REACT_APP_TTP_BE_ACCOUNTANTS_BLOG;
export const TTP_TALK_URL = process.env.REACT_APP_TTP_TALK_URL;
export const TTP_BILLTOBOX_URL = process.env.REACT_APP_TTP_BILLTOBOX_URL;
export const TTP_EBOX_URL = process.env.REACT_APP_TTP_EBOX_URL;
export const TTP_DASHBOARD_URL = process.env.REACT_APP_TTP_DASHBOARD_URL;
export const TTP_ACCOUNTING_URL = process.env.REACT_APP_TTP_ACCOUNTING_URL;

export const TTP_LOGO_URL = "/img/logo.png";
export const TTP_LOGO2_URL = "/img/logo2.svg";

export const AUTH_COOKIE_NAME = `ttp_auth_${APP_ENV}`;

export const COMMUNITY_NAME_MAX_LENGTH = 20;
export const COMMUNITY_DEFAULT_LOGO_URL = "/img/community-default-logo.png";

export const PAGES = {
  HOME_PAGE: "HOME_PAGE",
  SETTING_PAGE: "SETTING_PAGE",
};

export const CLIENT_CREDENTIAL = {
  grant_type: "client_credentials",
  client_id: "10011",
  client_secret: "BlogSecretS#K$",
  scope: "ttp",
};

export const SOCIAL_NETWORKS_HOSTS = {
  FACEBOOK: "https://www.facebook.com",
  TWITTER: "https://www.twitter.com",
  LINKEDIN: "https://www.linkedin.com",
};
