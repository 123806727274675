import React, { PureComponent } from "react";

import styles from "./PageForm.module.scss";

import { TTP_HUB_URL } from "config";

export class Preview extends PureComponent {
  render() {
    const { token, page } = this.props;

    return (
      <div className={styles.previewContainer}>
        <iframe
          src={`${TTP_HUB_URL}/pages/0?token=${token}&page=${page}`}
        ></iframe>
      </div>
    );
  }
}
