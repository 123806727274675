import React, { PureComponent } from "react";

//import { TTP_API_URL } from "config";
import styles from "./AlbumCondition.module.scss";

import _ from "i18n";

export default class AlbumCondition extends PureComponent {
  state = {
    play: false,
  };

  render() {
    const { language, album, selectAlbum, selected } = this.props;
    const { medias, countMedias } = album;

    if (!countMedias || countMedias === 0) {
      return null;
    }

    const nbMedias = countMedias || medias.length || 0;
    const titleAttr = `title${
      language.charAt(0).toUpperCase() + language.slice(1)
    }`;

    /*const { fullMediaUrl, webPath, docType, preview } = medias[0];

    let path = null;
    if (
      (docType === "VIDEO" || docType === "PPT" || docType === "PDF") &&
      preview &&
      (preview.fullMediaUrl || preview.webPath)
    ) {
      path = preview.fullMediaUrl
        ? preview.fullMediaUrl
        : TTP_API_URL + "/" + preview.webPath;
    } else if (docType === "IMAGE") {
      path = fullMediaUrl ? fullMediaUrl : TTP_API_URL + "/" + webPath;
    }*/

    return (
      <div
        className={`${styles.album} ${selected ? styles.album__selected : ""}`}
        onClick={selectAlbum.bind(this, album)}
      >
        {selected && (
          <div className={styles.checkIcon}>
            <img src="img/icons/check.svg" width="32" height="32" alt="" />
          </div>
        )}
        <div className={styles.album__content}>
          <h3>{album[titleAttr]}</h3>
          <div className={styles.overlay}>
            <span className={styles.countMedias}>
              {nbMedias} {nbMedias === 1 ? _("Document") : _("Documents")}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

// Conn
