import {
  fetchAuthUser,
  setLanguage,
  setAuthToken,
  setAuth,
  setExpiresIn,
  setTokenCreatedAt,
  setAuthLoggedAs,
  setActiveApps,
  setNavCommunity,
  fetchPublicCommunity,
  setAuthUser as setAuthUserAction,
} from "actions";

import {
  getTTPUser,
  setSelectedOrganization,
  getSelectedOrganization,
  getPublicOrganization,
  getActiveApps,
  getClientCredential,
} from "api";

import {
  getAuthInfosFromCookie,
  authHasExpired,
  getLocalStorageAuth,
  setLocalStorageAuth,
  //redirectToHome,
  logout,
} from "utils";

export const setAuthUser = (user) => {
  return (dispatch, getState) => {
    dispatch(setAuthToken(user.token));
    dispatch(setExpiresIn(user.expiresIn));
    dispatch(setTokenCreatedAt(user.createdAt));
    dispatch(
      fetchAuthUser(
        getTTPUser({ userId: user.id, token: user.token }).then(
          (res) => {
            const user =
              typeof res === "string" ? JSON.parse(res).data[0] : res.data[0];
            dispatch(setLanguage(user.language));
            dispatch(setAuthUserAction(user));
            dispatch(fetchNavCommunity());
            return user;
          },
          (err) => err
        )
      )
    );
  };
};

export const login = () => {
  return (dispatch, getState) => {
    let authInfos = getAuthInfosFromCookie();
    if (!authInfos || authHasExpired(authInfos)) {
      setLocalStorageAuth(null);
      console.log("...login");
      logout();
    }

    const auth = getLocalStorageAuth();
    if (auth && auth.user && authInfos.token === auth.token) {
      dispatch(setAuth(auth));

      const lng = localStorage.getItem("lng");
      if (!lng || lng.length === 0) {
        dispatch(setLanguage(auth.user.language));
      }
      const { navCommunity } = getState().auth;
      if (!navCommunity) {
        dispatch(fetchNavCommunity());
      }
    } else if (authInfos) {
      dispatch(setAuthUser(authInfos));
      dispatch(setAuthLoggedAs("MEMBER"));
    } else {
      dispatch(setAuthLoggedAs("GUEST"));
    }
  };
};

export const changeNavCommunity = (organizationId) => (dispatch, getState) => {
  const { token } = getState().auth;
  if (!token) {
    return false;
  }
  setSelectedOrganization({
    token,
    organizationId,
  });
};

export const fetchNavCommunity = () => (dispatch, getState) => {
  const { token, navCommunity, user } = getState().auth;

  if (!token || !user) {
    return false;
  }

  return getSelectedOrganization(token).then((resp) => {
    const help = typeof resp === "string" ? JSON.parse(resp) : { ...resp };
    if (
      help &&
      help.data &&
      help.data.organization &&
      (!navCommunity || navCommunity.id !== help.data.organization.id)
    ) {
      const { organization } = help.data;
      const chosed = user.communities.filter(
        (com) => organization.id === com.id
      );

      if (!chosed || chosed.length === 0) {
        dispatch(setAuthLoggedAs("CLIENT"));
      }

      dispatch(
        setNavCommunity(
          user && user.communities && chosed && chosed.length > 0
            ? chosed[0]
            : organization
        )
      );
    }
  });
};

export const fetchActiveApps = () => (dispatch, getState) => {
  const { token, navCommunity } = getState().auth;
  if (!token) {
    return false;
  }
  return dispatch(setActiveApps(getActiveApps(token, navCommunity.id)));
};

export const fetchPublicPortal = () => {
  return (dispatch, getState) => {
    const { token } = getState().auth;
    const subdomain = window.location.host;

    if (!token || token === "") {
      return getClientCredential().done((resp) => {
        const token = resp.token.access_token;
        dispatch(setAuthToken(token));
        return dispatch(
          fetchPublicCommunity(getPublicOrganization(token, subdomain))
        );
      });
    }

    return dispatch(
      fetchPublicCommunity(getPublicOrganization(token, subdomain))
    );
  };
};
