import { getDefaultLanguage } from "utils";
import { PAGES } from "config";
import _ from "i18n";

// Language reducer
const defaultLanguage = localStorage.getItem("lng") || getDefaultLanguage();
export const languageReducer = (state = defaultLanguage, action) => {
  switch (action.type) {
    case "SET_LANGUAGE": {
      return action.language;
    }
    default:
      return state;
  }
};

// Current Navigation Page Reducer
export const currentNavPageReducer = (state = PAGES.HOME_PAGE, action) => {
  switch (action.type) {
    case "SET_CURRENT_NAV_PAGE": {
      return action.currentNavPage;
    }
    default:
      return state;
  }
};

// Masonry reducer
export const masonryReducer = (state = null, action) => {
  switch (action.type) {
    case "SET_MASONRY": {
      return action.masonry !== undefined ? action.masonry : state;
    }
    default:
      return state;
  }
};

//Personal Blog reducer
const initialState = {
  updating: false,
  updated: false,
  error: null,
};
export const personalBlogReducer = (state = initialState, action) => {
  switch (action.type) {
    case "INIT_SAVE_BLOG": {
      return initialState;
    }
    case "CREATE_PERSONAL_BLOG_PENDING": {
      return { ...state, updating: true };
    }
    case "CREATE_PERSONAL_BLOG_FULFILLED": {
      return { ...state, updated: true, updating: false };
    }
    case "CREATE_PERSONAL_BLOG_REJECTED": {
      let error = action.payload;
      if (error.response) {
        switch (error.response.status) {
          case 404:
            error = {
              title: error.response.data.title,
              code: 404,
            };
            break;
          default:
        }
      }
      return {
        ...state,
        updated: false,
        updating: false,
        error,
      };
    }
    default:
      return state;
  }
};

//Notifications reducer
const NotificationInitialState = {
  notificationId: 0,
  message: null,
  code: 0,
};

export const notificationsReducer = (
  state = NotificationInitialState,
  action
) => {
  let rejectedKey = "_REJECTED";
  let actionType = action.type;
  let isRejected = actionType.slice(-rejectedKey.length) === rejectedKey;
  if (isRejected) {
    let results = {};
    if (action.payload && action.payload.response) {
      let message = action.payload.response.data
        ? action.payload.response.data.title
        : "";
      let notificationId = state.notificationId + 1;
      switch (action.payload.response.status) {
        case 400:
        case 404:
        case 700:
          notificationId = 0;
          break;
        case 401:
        case 403:
          message = _("Access is denied");
          break;
        case 500:
          message = _("An unknown error occurred");
          break;
        case 413:
          message = _("The image file you have selected is too large");
          break;
        default:
          message = _("An unknown error occurred");
          break;
      }
      results = {
        ...state,
        message: message,
        notificationId: notificationId,
      };
    } else {
      let notificationId = state.notificationId + 1;
      let message = "";

      if (action.payload.config && action.payload.config.Warning === "413") {
        message = _("The image file you have selected is too large");
      } else {
        message = _("An unknown error occurred");
      }
      results = {
        ...state,
        message: message,
        notificationId: notificationId,
      };
    }

    return results;
  }
  return state;
};

//Notifications reducer
const flashInitialState = {
  openFlash: false,
  message: "",
  status: "SUCCESS",
};

export const flashMessageReducer = (state = flashInitialState, action) => {
  switch (action.type) {
    case "DISPLAY_FLASH_MESSAGE": {
      const { status, message } = action;
      return { ...state, status, message, openFlash: true };
    }
    case "HIDE_FLASH_MESSAGE": {
      return { ...state, openFlash: false };
    }
    default:
      return state;
  }
};

//SourceToken Reducers
export const sourcesTokensReducer = (state = [], action) => {
  switch (action.type) {
    case "PUSH_SOURCE_TOKEN": {
      let newItem = {
        sourceName: action.sourceName,
        sourceToken: action.sourceToken,
      };
      let filtredItem = state.filter(
        (item) => item.sourceName === action.sourceName
      );
      let mapedItems = state.map((item) =>
        item.sourceName === action.sourceName ? newItem : item
      );
      let tab = [...state, newItem];
      let results = filtredItem.length > 0 ? mapedItems : tab;
      return results;
    }
    case "CLEAR_SOURCES_TOKENS": {
      return [];
    }
    default:
      return state;
  }
};
//Private blog Reducers
const initialPrivateBlogState = {
  blogUrl: "",
  isPrivateBlog: false,
  communityId: null,
};
export const privateBlogReducer = (state = initialPrivateBlogState, action) => {
  switch (action.type) {
    case "SET_IS_PRIVATE_BLOG": {
      return { ...state, isPrivateBlog: action.payload };
    }
    case "SET_PRIVATE_BLOG_COMMUNITY": {
      return { ...state, communityId: action.payload };
    }
    default:
      return state;
  }
};

// SearchLayer reducer
export const visibleSearchLayerReducer = (state = false, action) => {
  switch (action.type) {
    case "SHOW_SEARCH_LAYER": {
      return action.visible;
    }
    default:
      return state;
  }
};

// SearchLayer reducer
export const searchSelectReducer = (state = [], action) => {
  switch (action.type) {
    case "SET_SEARCH_SELECT": {
      return [...action.payload];
    }
    default:
      return state;
  }
};

//visibleAreas blog Reducers
const initialVisibleAreas = {
  pellet: true,
  directory: true,
  dashboard: true,
  powerTeam: true,
  dms: true,
};
export const visibleAreasReducer = (state = initialVisibleAreas, action) => {
  switch (action.type) {
    case "SHOW_PELLET": {
      return { ...state, pellet: action.isVisible };
    }
    case "SHOW_DIRECTORY": {
      return { ...state, directory: action.isVisible };
    }
    case "SHOW_DASHBOARD": {
      return { ...state, dashboard: action.isVisible };
    }
    case "SHOW_POWER_TEAM": {
      return { ...state, powerTeam: action.isVisible };
    }
    case "SHOW_DMS": {
      return { ...state, dms: action.isVisible };
    }
    default:
      return state;
  }
};

//Private blog Reducers
const initialPublicPortalState = {
  fetching: false,
  fetched: false,
  error: null,
  nbResult: 0,
  community: null,
};
export const publicPortalReducer = (
  state = initialPublicPortalState,
  action
) => {
  switch (action.type) {
    case "INIT_PUBLIC_COMMUNITY": {
      return initialPublicPortalState;
    }
    case "FETCH_PUBLIC_COMMUNITY_PENDING": {
      return { ...state, fetched: false, fetching: true };
    }
    case "FETCH_PUBLIC_COMMUNITY_FULFILLED": {
      const { data, nbResult } = action.payload.data;

      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        community: Array.isArray(data) ? data[0] : data,
        nbResult: nbResult,
      };
    }
    case "FETCH_PUBLIC_COMMUNITY_REJECTED": {
      let error = action.payload;
      switch (error.response.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        error,
      };
    }

    default:
      return state;
  }
};

// Drawer Iframe Reducer
const drawerIframeDefaultState = { isOpen: false, src: "" };
export const drawerIframeReducer = (
  state = drawerIframeDefaultState,
  action
) => {
  switch (action.type) {
    case "SET_DRAWER_IFRAME": {
      if (action.url === "") {
        return drawerIframeDefaultState;
      }
      return { isOpen: true, src: action.url };
    }
    case "CLOSE_DRAWER_IFRAME": {
      return { ...state, isOpen: false };
    }

    default:
      return state;
  }
};
