const initialState = {
  medias: null, // media object
  currentMedia: null,
  isOpen: false,
  area: null,
};

export const mediaViewerReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CLOSE_MEDIA_VIEWER": {
      return initialState;
    }
    case "OPEN_MEDIA_VIEWER": {
      return { ...state, ...action.viewer };
    }
    case "SET_CURRENT_MEDIA_VIEWER": {
      return { ...state, currentMedia: action.media };
    }
    default:
      return state;
  }
};
