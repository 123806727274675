import * as actions from "../actions";
import * as api from "../../api";

export const fetchPages = (params) => {
  return (dispatch, getState) => {
    const { token, navCommunity } = getState().auth;

    const communityId = navCommunity && navCommunity.id;

    // if (!token) {
    //   return api.getClientCredential().done((resp) => {
    //     const clientToken = resp.token.access_token;
    //     dispatch(actions.setAuthToken(clientToken));
    //     return dispatch(
    //       actions.fetchPages(
    //         api.fetchPages({ token: clientToken, communityId, ...params })
    //       )
    //     );
    //   });
    // }

    return dispatch(
      actions.fetchPages(api.fetchPages({ token, communityId, ...params }))
    );
  };
};

export const savePage = (params) => {
  return (dispatch, getState) => {
    const { token, navCommunity, user } = getState().auth;

    return dispatch(
      actions.savePage(
        api.savePage({
          token,
          communityId: navCommunity.id,
          userId: user ? user.id : null,
          ...params,
        })
      )
    );
  };
};

export const deletePage = (params) => {
  return (dispatch, getState) => {
    const { token } = getState().auth;

    return dispatch(actions.deletePage(api.deletePage({ token, ...params })));
  };
};
