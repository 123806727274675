import axios from "axios";
import { TTP_API_URL } from "config";
import { throwCatchedError, updateSourceToken } from "utils";

export const getOrganization = ({ token, communityId }) => {
  const filter = [{ property: "id", value: communityId, operator: "eq" }];

  const fields = [
    "id",
    "uen",
    "name",
    "address1",
    "address2",
    "zipCode",
    "city",
    "country",
    "avatarWebPath",
    "phone",
    "email",
    "latitude",
    "longitude",
    "abbreviation",
    "schedule",
    "portalPreferences",
  ];

  const requestUrl = `${TTP_API_URL}/organization/organization`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      fields: fields.join(","),
      filter: JSON.stringify(filter),
      workspace: "user",
    },
  });
};

export const getTeam = ({
  token,
  limit,
  offset = 0,
  communityId,
  navCommunity,
}) => {
  const fields = ["*", "avatar"];

  let requestUrl = `${TTP_API_URL}/organization/user`;

  const filter = [
    {
      property: "enabled",
      value: 1,
      operator: "eq",
    },
  ];

  if (communityId) {
    filter.push({
      property: "organization.id",
      value: communityId,
      operator: "eq",
    });
  } else if (navCommunity) {
    requestUrl += `?organization_id=${navCommunity.id}`;
  }
  const sort = [
    {
      property: "createdAt",
      dir: "desc",
    },
  ];

  return axios
    .get(requestUrl, {
      params: {
        access_token: token,
        filter: JSON.stringify(filter),
        sort: JSON.stringify(sort),
        fields: fields.join(","),
        limit,
        start: offset,
      },
    })
    .catch(function (thrown) {
      throwCatchedError(thrown);
    });
};

export const getArticles = ({
  token,
  limit,
  language = "fr",
  communityId,
  offset = 0,
  search = null,
  isPublic = true,
  conditions = [],
  loggedAs = "GUEST",
}) => {
  const filter = [
    {
      property: "status",
      value: "PUBLISHED",
      operator: "like",
    },
    {
      property: "language",
      value: language,
      operator: "like",
    },
  ];

  let csScope = ["INTRA_SHARE", "EXTRA_SHARE", "INTRA_CROSS", "EXTRA_CROSS"];

  switch (loggedAs) {
    case "CLIENT":
      csScope = [...["EXTRA_SHARE", "INTRA_CROSS", "EXTRA_CROSS"]];
      break;
    default:
  }

  filter.push({
    property: "csScope",
    value: csScope,
    operator: "in",
  });

  if (communityId) {
    filter.push({
      property: "organization.id",
      value: communityId,
      operator: "eq",
    });
  }
  if (search) {
    filter.push({
      property: "word",
      operator: "like",
      value: search,
    });
  }

  const sort = [
    {
      property: "publishedAt",
      dir: "desc",
    },
  ];
  const fields = [
    "*",
    "url",
    "author",
    "introduction",
    "main_media",
    "tags",
    "category",
    "image_reduced",
    "organization",
    "avatars",
    "albums",
  ];
  if (isPublic) {
    fields.push("content", "contentState");
  } else {
    fields.push("social");
  }
  const requestUrl = `${TTP_API_URL}/blog/article`;

  return axios
    .get(requestUrl, {
      params: {
        access_token: token,
        filter: JSON.stringify([...filter, ...conditions]),
        sort: JSON.stringify(sort),
        fields: fields.join(","),
        limit,
        start: offset,
      },
    })
    .catch(function (thrown) {
      throwCatchedError(thrown);
    });
};

export const getMediasInTheNews = ({ token, communityId }) => {
  let requestUrl = `${TTP_API_URL}/media/media`;

  const filter = [
    {
      property: "organization.id",
      operator: "eq",
      value: communityId,
    },
    {
      property: "inTheNews",
      operator: "eq",
      value: 1,
    },
  ];
  const sort = [
    {
      property: "createdAt",
      dir: "desc",
    },
  ];
  let fields = ["*", "webPath", "preview"];

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filter),
      sort: JSON.stringify(sort),
      fields: fields.join(","),
      limit: 5,
    },
  });
};
