import React, { PureComponent } from "react";

import { TTP_API_URL } from "config";
import styles from "./Emailing.module.scss";
import classnames from "classnames";

export default class EmailingComponent extends PureComponent {
  render() {
    const {
      nl,
      //openEmailingReveal,
    } = this.props;

    const { title, number, tmpUrl } = nl;
    if (!tmpUrl) {
      return null;
    }
    const emailTmpUrl = tmpUrl;
    return (
      <div className={classnames(styles.nl)}>
        <iframe
          frameborder="0"
          gesture="media"
          allow="encrypted-media"
          allowfullscreen
          src={emailTmpUrl}
          title="hub"
        />
        <div className={styles.infos}>
          <a href={emailTmpUrl} target="_blank">
            <h3>{title}</h3>
            <h4>
              N°: <strong>{number}</strong>
            </h4>
          </a>
        </div>
      </div>
    );
  }
}

// Conn
