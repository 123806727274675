import axios from "axios";
import { TTP_API_URL } from "config";
import { filter } from "lodash";

export const fetchArticles = ({
  token,
  limit = 50,
  offset = 0,
  filters = [],
  language,
  communityId,
}) => {
  const requestUrl = `${TTP_API_URL}/blog/article`;

  const fields = [
    "*",
    "url",
    "introduction",
    "author",
    "main_media",
    "category",
    "tags",
    "image_reduced",
    "organization",
    "avatars",
    "pages",
    "theme",
    "type",
    "social",
    "comment",
    "contentState",
    "albums",
  ];

  filters.push({
    property: "language",
    operator: "eq",
    value: language,
  });

  if (
    communityId &&
    !filters.find((fil) => fil.property == "organization.id")
  ) {
    filters.push({
      property: "organization.id",
      operator: "eq",
      value: communityId,
    });
  }

  let sort = [];
  sort.push({
    property: "publishedAt",
    dir: "desc",
  });

  const params = {
    access_token: token,
    fields: fields.join(","),
    filter: JSON.stringify(filters),
    sort: JSON.stringify(sort),
    limit,
    start: offset,
  };

  return axios.get(requestUrl, { params });
};
