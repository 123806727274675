import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import qs from "qs";

import { getAuthInfosFromCookie } from "utils";
import _ from "i18n";

//import styles from "./Page.module.scss";
import { getClientCredential, fetchPages } from "../../api";
import { PagePreview } from "./PagePreview";
import NotFound from "../NotFound/NotFound";

export class Page extends Component {
  state = {
    loading: true,
    token: null,
    page: null,
  };

  componentDidMount() {
    const { match, location } = this.props;
    const { pageId } = match.params;

    const { token, page } = qs.parse(location.search.substr(1));

    if (token && page) {
      return this.setState({
        token: decodeURIComponent(token),
        page: JSON.parse(decodeURIComponent(page)),
        loading: false,
      });
    }

    if (pageId) {
      if (token) {
        this.setState({ token });
        fetchPages({ token, pageId /*, communityId*/ })
          .then((res) => {
            const pages = res.data.data;

            if (pages && pages.length > 0) {
              const page = pages[0];

              this.setState({ page, loading: false });
            }
          })
          .catch(() => {
            console.log("fetchPages error");
          });
      } else {
        getClientCredential().done((resp) => {
          const help = typeof resp === "string" ? JSON.parse(resp) : resp;

          const token = help.token.access_token;
          this.setState({ token });

          fetchPages({ token, pageId /*, communityId*/ })
            .then((res) => {
              const pages = res.data.data;

              if (pages && pages.length > 0) {
                const page = pages[0];

                this.setState({ page, loading: false });
              }
            })
            .catch(() => {
              console.log("fetchPages error");
            });
        });
      }
    }
  }

  render() {
    const { loading, page, token } = this.state;
    const { auth } = this.props;
    const { community, user } = auth;

    if (loading) {
      return (
        <div
          style={{
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          {_("Loading...")}
        </div>
      );
    }

    if (page) {
      let userId = user ? user.id : page.userId || null;
      if (page.forConnected && page.forConnected === 1) {
        const authInfos = getAuthInfosFromCookie();
        if (authInfos && authInfos.id) {
          userId = authInfos.id;
        } else {
          return <NotFound />;
        }
      }
      return [
        <PagePreview
          page={page}
          token={token}
          auth={auth}
          communityId={
            community
              ? community.id
              : page && page.organization
              ? page.organization.id
              : page && page.communityId
              ? page.communityId
              : null
          }
          userId={userId}
        />,
      ];
    }

    return null;
  }
}

// Connected Component
const mapStateToProps = (state) => ({
  lng: state.params.lng,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  // fetchPages: (params) => dispatch(fetchPages(params)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Page));
