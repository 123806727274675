import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Modal from "react-modal";
import { toast } from "react-toastify";

import { fetchPages, deletePage } from "../../actions/thunks";
import {
  convertDateFromUTC,
  getDateLabel,
  copyTextToClipboard,
} from "../../utils";

import { getCurrentOrganization } from "api";
import { TTP_HUB_URL, TTP_DASHBOARD_URL } from "config";
import _ from "i18n";

import styles from "./PageList.module.scss";
import DeleteConfirm from "../Confirm/DeleteConfirm";

const modalStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(41, 57, 77, 0.4)",
    zIndex: 10,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: "transparent",
    border: "none",
    overflow: "none",
    padding: 0,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "#fff",
  },
};

const IconLink = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
    <g clipPath="url(#clip0)">
      <path
        d="M7.40017 6.06907C7.24648 6.22276 7.24648 6.46924 7.40017 6.62292C8.03521 7.25797 8.03521 8.29028 7.40017 8.92533L4.06545 12.26C3.43041 12.8951 2.39809 12.8951 1.76305 12.26L1.26139 11.7584C0.626346 11.1233 0.626346 10.091 1.26139 9.45598L4.59611 6.12127C4.74979 5.96758 4.74979 5.7211 4.59611 5.56741C4.44242 5.41373 4.19594 5.41373 4.04225 5.56741L0.707539 8.90213C0.252278 9.35739 0 9.96344 0 10.6072C0 11.2509 0.249379 11.857 0.707539 12.3122L1.2092 12.8139C1.67896 13.2837 2.2966 13.5185 2.91425 13.5185C3.5319 13.5185 4.14954 13.2837 4.6193 12.8139L7.95402 9.47918C8.89354 8.53966 8.89354 7.00859 7.95402 6.06907C7.80323 5.91538 7.55385 5.91538 7.40017 6.06907Z"
        fill="currentColor"
      />
      <path
        d="M13.2958 1.68774L12.7942 1.18608C11.8547 0.246565 10.3236 0.246565 9.38407 1.18608L6.04936 4.5208C5.10985 5.46032 5.10985 6.99139 6.04936 7.93091C6.20305 8.08459 6.44953 8.08459 6.60322 7.93091C6.7569 7.77722 6.7569 7.53074 6.60322 7.37705C5.96817 6.74201 5.96817 5.7097 6.60322 5.07465L9.93792 1.73994C10.573 1.10489 11.6053 1.10489 12.2403 1.73994L12.742 2.24159C13.377 2.87664 13.377 3.90895 12.742 4.544L9.40727 7.87871C9.25358 8.0324 9.25358 8.27888 9.40727 8.43256C9.48266 8.50796 9.58416 8.54855 9.68275 8.54855C9.78134 8.54855 9.88283 8.51086 9.95822 8.43256L13.2929 5.09785C13.7482 4.64259 14.0005 4.03654 14.0005 3.3928C14.0005 2.74905 13.7511 2.143 13.2958 1.68774Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const IconEye = (props) => (
  <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M15.8215 7.38423C14.988 6.02114 13.8674 4.9259 12.4598 4.09855C11.052 3.27116 9.56549 2.85742 8 2.85742C6.43451 2.85742 4.94793 3.27101 3.54018 4.09855C2.1324 4.9259 1.01183 6.02114 0.178505 7.38423C0.0595122 7.59254 0 7.7979 0 8.00033C0 8.20276 0.0595122 8.40813 0.178505 8.61638C1.01183 9.97953 2.1324 11.0749 3.54018 11.902C4.94793 12.7295 6.43451 13.1429 8 13.1429C9.56552 13.1429 11.052 12.7309 12.4598 11.9065C13.8674 11.0821 14.9882 9.98538 15.8215 8.61638C15.9405 8.40813 16 8.20286 16 8.00033C16 7.7979 15.9405 7.59254 15.8215 7.38423ZM6.08469 4.94223C6.6174 4.40952 7.25577 4.14308 7.99991 4.14308C8.1189 4.14308 8.22016 4.18486 8.30353 4.26814C8.38678 4.35145 8.42863 4.45268 8.42863 4.57171C8.42863 4.69082 8.38678 4.79199 8.30372 4.87518C8.22047 4.95858 8.11915 5.00024 8.00012 5.00024C7.48825 5.00024 7.05062 5.18187 6.68757 5.54488C6.3244 5.90812 6.14292 6.34556 6.14292 6.85744C6.14292 6.97662 6.10114 7.07772 6.01786 7.1611C5.93446 7.24453 5.83332 7.28616 5.7143 7.28616C5.59518 7.28616 5.49401 7.24444 5.41064 7.1611C5.32724 7.07772 5.28558 6.97649 5.28558 6.85744C5.28558 6.11346 5.55202 5.47496 6.08469 4.94223ZM11.8795 10.9157C10.686 11.6387 9.39289 12.0004 8 12.0004C6.60714 12.0004 5.31401 11.6389 4.12057 10.9157C2.92714 10.1924 1.93457 9.22066 1.14293 8.00033C2.04768 6.59553 3.18161 5.54485 4.54469 4.84856C4.18162 5.46752 4.00002 6.13722 4.00002 6.85741C4.00002 7.95871 4.39146 8.90043 5.17412 9.68328C5.95679 10.4661 6.89885 10.8574 8 10.8574C9.10124 10.8574 10.043 10.4659 10.8259 9.68328C11.6087 8.90065 12 7.95871 12 6.85741C12 6.13722 11.8184 5.46742 11.4553 4.84856C12.8183 5.54485 13.9523 6.59562 14.857 8.00033C14.0655 9.22066 13.073 10.1924 11.8795 10.9157Z"
      fill="currentColor"
    />
  </svg>
);

const IconEdit = (props) => (
  <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clipPath="url(#clip0)">
      <path
        d="M14.9636 1.03649C13.5863 -0.344196 11.3466 -0.344196 9.96928 1.03649L0.812414 10.19C0.740823 10.2615 0.696504 10.3536 0.682868 10.4525L0.00445532 15.4775C-0.0159993 15.6207 0.0351373 15.7639 0.134001 15.8627C0.219229 15.9479 0.338548 15.9991 0.457867 15.9991C0.478321 15.9991 0.498776 15.9991 0.519231 15.9957L3.54652 15.5866C3.79879 15.5525 3.97607 15.3207 3.94198 15.0684C3.90788 14.8161 3.67606 14.6388 3.42379 14.6729L0.996506 15.0002L1.47037 11.4956L5.15903 15.1843C5.24425 15.2695 5.36357 15.3207 5.48289 15.3207C5.60221 15.3207 5.72153 15.2729 5.80676 15.1843L14.9636 6.03084C15.6318 5.36266 16 4.47629 16 3.53196C16 2.58764 15.6318 1.70127 14.9636 1.03649ZM10.1466 2.1615L11.6841 3.69901L3.32834 12.0547L1.79083 10.5172L10.1466 2.1615ZM5.4863 14.2093L3.98288 12.7059L12.3386 4.35015L13.842 5.85357L5.4863 14.2093ZM14.4829 5.19561L10.8045 1.51718C11.2716 1.13195 11.8545 0.920584 12.4682 0.920584C13.167 0.920584 13.8216 1.19331 14.3159 1.68423C14.8102 2.17514 15.0795 2.8331 15.0795 3.53196C15.0795 4.14901 14.8682 4.72856 14.4829 5.19561Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const IconDelete = (props) => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.671 2.30263H11.342V1.75987C11.342 0.789474 10.5525 0 9.58213 0H6.41766C5.44727 0 4.65779 0.789474 4.65779 1.75987V2.30263H1.32884C1.08213 2.30263 0.884766 2.5 0.884766 2.74671C0.884766 2.99342 1.08213 3.19079 1.32884 3.19079H2.13148V13.625C2.13148 14.9342 3.19727 16 4.50648 16H11.4933C12.8025 16 13.8683 14.9342 13.8683 13.625V3.19079H14.671C14.9177 3.19079 15.115 2.99342 15.115 2.74671C15.115 2.5 14.9177 2.30263 14.671 2.30263ZM5.54595 1.75987C5.54595 1.27961 5.9374 0.888158 6.41766 0.888158H9.58213C10.0624 0.888158 10.4538 1.27961 10.4538 1.75987V2.30263H5.54595V1.75987ZM12.9802 13.625C12.9802 14.4441 12.3124 15.1118 11.4933 15.1118H4.50648C3.6874 15.1118 3.01963 14.4441 3.01963 13.625V3.19079H12.9835V13.625H12.9802Z"
      fill="currentColor"
    />
    <path
      d="M7.99876 13.52C8.24547 13.52 8.44283 13.3227 8.44283 13.076V5.22728C8.44283 4.98057 8.24547 4.7832 7.99876 4.7832C7.75205 4.7832 7.55469 4.98057 7.55469 5.22728V13.0727C7.55469 13.3194 7.75205 13.52 7.99876 13.52Z"
      fill="currentColor"
    />
    <path
      d="M5.10228 13.0294C5.34899 13.0294 5.54636 12.832 5.54636 12.5853V5.71361C5.54636 5.4669 5.34899 5.26953 5.10228 5.26953C4.85557 5.26953 4.6582 5.4669 4.6582 5.71361V12.5853C4.6582 12.832 4.85886 13.0294 5.10228 13.0294Z"
      fill="currentColor"
    />
    <path
      d="M10.8972 13.0294C11.1439 13.0294 11.3413 12.832 11.3413 12.5853V5.71361C11.3413 5.4669 11.1439 5.26953 10.8972 5.26953C10.6505 5.26953 10.4531 5.4669 10.4531 5.71361V12.5853C10.4531 12.832 10.6505 13.0294 10.8972 13.0294Z"
      fill="currentColor"
    />
  </svg>
);

const IconArrowLeft = (props) => (
  <svg {...props} width="14" height="14" viewBox="0 0 14 13" fill="none">
    <path
      d="M0.259444 6.71393L5.55299 11.9378C5.89429 12.2756 6.44627 12.2713 6.78408 11.9291C7.1219 11.5869 7.11842 11.0358 6.77536 10.698L2.99241 6.96469L13.1294 6.96469C13.6108 6.96469 14 6.57552 14 6.09406C14 5.6126 13.6108 5.22339 13.1294 5.22339L2.99241 5.22339L6.77536 1.49007C7.11838 1.15225 7.121 0.601139 6.78408 0.25898C6.61345 0.0865905 6.38881 -0.000463774 6.16418 -0.000463754C5.94302 -0.000463735 5.7219 0.0831096 5.55299 0.250299L0.259444 5.4742C0.0940155 5.63786 -5.53185e-07 5.86078 -5.32787e-07 6.0941C-5.12397e-07 6.32734 0.0931554 6.54936 0.259444 6.71393Z"
      fill="currentColor"
    />
  </svg>
);

export class PageList extends Component {
  state = {
    previewPage: null,
    pageToDelete: null,
    deleting: false,
    deletingError: null,
    isFetchOrganization: false,
  };

  componentDidMount() {
    if (this.props.community) {
      this.props.fetchPages();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.community !== this.props.community) {
      this.props.fetchPages();
    }
  }

  handleCopyLink = (page) => {
    copyTextToClipboard(`${TTP_HUB_URL}/pages/${page.id}`, () => {
      console.log("ok");
      toast.success(_("Page link copied to clipboard"));
    });
  };

  handleEditPage = (page) => {
    const { history } = this.props;
    const encodedPage = encodeURIComponent(JSON.stringify(page));

    history.push(`/form/${page.id}?page=${encodedPage}`);
  };

  handleClickDeletePage = (page) => {
    const { community, token } = this.props;

    this.setState({ isFetchOrganization: true });
    getCurrentOrganization({ token, communityId: community.id })
      .then((resp) => {
        this.setState({ isFetchOrganization: false });
        if (resp.data.data.length > 0) {
          const { hubPreferences } = resp.data.data[0];
          if (
            hubPreferences.layout_settings &&
            hubPreferences.layout_settings.length > 0
          ) {
            hubPreferences.layout_settings.forEach((layout) => {
              const tab = layout.config.filter((item) =>
                item.url.endsWith("/pages/" + page.id)
              );
              if (tab.length > 0) {
                toast.error(_("This page is used by portal"));
              } else {
                this.setState({ pageToDelete: page });
              }
            });
          } else {
            this.setState({ pageToDelete: page });
          }
        }
      })
      .catch(() => {
        this.setState({ isFetchOrganization: false });
      });
  };

  renderHeader() {
    return (
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <div className={styles.headerIcon}>
            <img src="/img/icons/articles.svg" alt="page" />
          </div>
          <div className={styles.headerInfo}>
            <div>
              <h3>{"Pages listing"}</h3>
              <p>
                {"Lorem ipsum dolor sit amet, consectetur adipiscing elit."}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderCreateNew() {
    const { history } = this.props;

    return (
      <div className={styles.createPage} onClick={() => history.push("/form")}>
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
          <path
            d="M17.0231 7.7461H10.2558V0.978695C10.2558 0.670412 9.83717 0.00195312 8.99996 0.00195312C8.16276 0.00195312 7.74414 0.670448 7.74414 0.978695V7.74613H0.976741C0.668495 7.7461 0 8.16472 0 9.00188C0 9.83905 0.668495 10.2577 0.976741 10.2577H7.74418V17.0251C7.74418 17.3334 8.16276 18.0019 9 18.0019C9.83724 18.0019 10.2558 17.3334 10.2558 17.0251V10.2577H17.0233C17.3315 10.2577 18 9.83912 18 9.00188C18 8.16464 17.3314 7.7461 17.0231 7.7461Z"
            fill="currentColor"
          />
        </svg>

        <span>Créer une page</span>
      </div>
    );
  }

  renderList() {
    const { pages, history, community, user, token } = this.props;
    const { isFetchOrganization } = this.state;

    return pages.map((page) => {
      let url = `${TTP_HUB_URL}/pages/${page.id}?token=${token}`;

      switch (page.resource) {
        case "DASHBOARD":
          url = `${TTP_DASHBOARD_URL}`;
          break;
        default:
      }
      return (
        <div key={page} className={styles.page}>
          <div className={styles.pageImage}>
            <iframe
              frameborder="0"
              gesture="media"
              allow="encrypted-media"
              title="hub"
              src={url}
              //sandbox="allow-same-origin allow-scripts"
            />
            <div className={styles.pageInfo}>
              <div className={styles.pageDate}>
                Créée le {getDateLabel(convertDateFromUTC(page.createdAt))}
              </div>
              <div className={styles.pageName}>{page.title}</div>
              <div className={styles.pageActionsContainer}>
                <div className={styles.pageAction}>
                  <IconEye
                    onClick={() =>
                      this.setState({
                        previewPage: {
                          ...page,
                          communityId: community ? community.id : null,
                          userId: user ? user.id : null,
                        },
                      })
                    }
                  />
                </div>
                <div className={styles.pageAction}>
                  <IconEdit onClick={() => this.handleEditPage(page)} />
                </div>
                <div
                  className={`${styles.pageAction} ${styles.pageActionDelete}`}
                >
                  {isFetchOrganization ? (
                    <svg
                      height="20px"
                      viewBox="0 0 48 48"
                      width="32px"
                      className={styles.rotating}
                    >
                      <path
                        d="M42.28,11.851C38.238,5.42,31.105,1.135,22.95,1.135c-10.888,0-19.97,7.621-22.272,17.813  c-0.009,0-0.015-0.003-0.024-0.003c-0.018,0.104-0.027,0.2-0.044,0.302c-0.113,0.536-0.205,1.078-0.28,1.626l-0.022-0.024  c0,0-0.06,0.407-0.115,1.075c-0.005,0.057-0.018,0.113-0.022,0.17h0.009c-0.146,1.901-0.22,5.65,1.003,8.858  c0.006,0.016,0.012,0.029,0.018,0.045c0.209,0.671,0.434,1.29,0.66,1.835l0.008-0.008c0.486,1.202,0.891,1.856,0.891,1.856h0.01  c3.835,7.238,11.417,12.184,20.18,12.184c10.212,0,18.836-6.707,21.769-15.948h-4.018c-2.77,7.102-9.668,12.138-17.751,12.138  c-6.564,0-12.33-3.333-15.751-8.387l-0.007,0.014c0,0-0.049-0.078-0.128-0.214c-0.345-0.524-0.659-1.07-0.954-1.626  c-0.456-1.05-2.626-6.417-1.992-11.603c0.037-0.252,0.065-0.507,0.111-0.755c0.024-0.137,0.055-0.273,0.084-0.409  c1.811-8.638,9.46-15.128,18.638-15.128c7.108,0,13.281,3.904,16.551,9.674L32,22.095h4.407H37.5h4.408h3.821H47.9v-10.39V6.25  L42.28,11.851z"
                        fill="currentColor"
                      />
                    </svg>
                  ) : (
                    <IconDelete
                      onClick={() => this.handleClickDeletePage(page)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className={styles.pageLink}
            onClick={() => this.handleCopyLink(page)}
          >
            <IconLink /> {_("Copy link")}
          </div>
        </div>
      );
    });
  }

  renderFetching() {
    return (
      <div className={styles.PageList}>
        {this.renderHeader()}
        <section className={styles.section}>
          <div className={`${styles.sectionContent} ${styles.pagesContainer}`}>
            {[...Array(10).keys()].map((page) => {
              return (
                <div
                  key={page}
                  className={styles.createPage}
                  style={{ border: "none", backgroundColor: "#EEE" }}
                ></div>
              );
            })}
          </div>
        </section>
      </div>
    );
  }

  renderPreview() {
    const { token } = this.props;
    const { previewPage } = this.state;

    if (!previewPage || !token) {
      return;
    }

    const encodedToken = encodeURIComponent(token);
    const encodedPage = encodeURIComponent(JSON.stringify(previewPage));

    return (
      <Modal
        isOpen={!!previewPage}
        onRequestClose={() => this.setState({ previewPage: null })}
        closeTimeoutMS={200}
        contentLabel="Page preview"
        style={modalStyles}
      >
        <div className={styles.modalContent}>
          <div className={styles.modalClose}>
            <IconArrowLeft
              onClick={() => this.setState({ previewPage: null })}
            />
            {previewPage.title}
          </div>
          <div className={styles.modalIframeContainer}>
            <iframe
              src={`${TTP_HUB_URL}/pages/0?token=${encodedToken}&page=${encodedPage}`}
            ></iframe>
          </div>
        </div>
      </Modal>
    );
  }

  handleDeletePage = () => {
    const { pageToDelete } = this.state;
    this.props
      .deletePage({ pageId: pageToDelete.id })
      .then(() => {
        this.setState({
          pageToDelete: null,
        });
        toast.success(_("Page deleted successfully"));
      })
      .catch((e) => {
        toast.error(_("Could not delete page"));
      });
  };

  cancelDeletePage = () => {
    this.setState({
      pageToDelete: null,
    });
  };

  renderDeleteModal() {
    const { pageToDelete } = this.state;
    const { deleting, deletingError } = this.props;

    if (!pageToDelete) {
      return;
    }

    return (
      <DeleteConfirm
        isOpen={!!pageToDelete}
        onCancel={this.cancelDeletePage}
        onConfirm={this.handleDeletePage}
        deleting={deleting}
        deletingError={deletingError}
        text={
          _("Are you sure you want to delete the page") +
          ` "${pageToDelete.title}" ?`
        }
      />
    );
  }

  render() {
    const { fetching } = this.props;

    if (fetching) {
      return this.renderFetching();
    }

    return (
      <div className={styles.PageList}>
        {this.renderHeader()}
        <section className={styles.section}>
          <div className={`${styles.sectionContent} ${styles.pagesContainer}`}>
            {this.renderCreateNew()}
            {this.renderList()}
          </div>
        </section>
        {this.renderPreview()}
        {this.renderDeleteModal()}
      </div>
    );
  }
}

// Connected Component
const mapStateToProps = (state) => ({
  token: state.auth && state.auth.token,
  community: state.auth && state.auth.navCommunity,
  user: state.auth && state.auth.user,
  lng: state.params.lng,
  pages: state.pages.items,
  fetching: state.pages.fetching,
  deleting: state.pages.deleting,
  deletingError: state.pages.deletingError,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPages: (params) => dispatch(fetchPages(params)),
  deletePage: (params) => dispatch(deletePage(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PageList));
