import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import axios from "axios";
import debounce from "lodash/debounce";
import { nanoid } from "nanoid";
import qs from "qs";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import classnames from "classnames";

import { TTP_API_URL } from "config";
import { savePage } from "../../actions/thunks";
import _ from "i18n";
import styles from "./PageForm.module.scss";
import { Preview } from "./Preview";
import AlbumCondition from "../Album/AlbumCondition";
import { withRouter } from "react-router-dom";
import IconLoader from "../Icons/IconLoader";
import Switch from "../Switch/Switch";

const resources = [
  { value: "BLOG", label: _("Blog") },
  { value: "MEDIA", label: _("Media") },
  { value: "ALBUM", label: _("Media from Album") },
  { value: "EVENT", label: _("Event") },
  { value: "EMAILING", label: _("Emailing") },
  { value: "DASHBOARD", label: _("Dashboard") },
  { value: "WEBTOOL", label: _("Webtool") },
  { value: "FORUM", label: _("Forum"), isDisabled: true },
];

const properties = {
  BLOG: [
    { value: "category", label: _("Category") },
    { value: "tag", label: _("Tag") },
    { value: "theme", label: _("Theme") },
    { value: "type", label: _("Type") },
  ],
  MEDIA: [
    { value: "category", label: _("Category") },
    { value: "tag", label: _("Tag") },
    { value: "type", label: _("Type") },
  ],
  EVENT: [{ value: "type", label: _("Type") }],
  EMAILING: [{ value: "language", label: _("Language") }],
  ALBUM: [{ value: "id" }],
  WEBTOOL: [{ value: "category", label: _("Category") }],
};

const operators = [
  { value: "eq", label: _("Equal") },
  { value: "neq", label: _("Different") },
];

const languages = [
  { value: "fr", label: "Français" },
  { value: "nl", label: "Nederlands" },
  { value: "en", label: "English" },
];

const mediaTypes = [
  { label: "Image", id: "IMAGE" },
  { label: "Video", id: "VIDEO" },
  { label: "Document", id: "PDF,PPT" },
  { label: "Album", id: "ALBUM" },
];

const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: "5px",
    backgroundColor: state.isDisabled
      ? "#e6e6e6"
      : state.isFocused
      ? "#F3FAFF"
      : "#fff",
    //height: "2.8rem",
    boxShadow: "none",
    border: state.isFocused ? "1px solid #18A0FB" : "1px solid #F1F2F4",
    "&:hover": {
      borderColor: state.isFocused ? "#18A0FB" : "#DFE2E6",
    },
    // marginBottom: "1rem",
    padding: 0,
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: ".875rem",
    color: "#bbb",
    fontWeight: 300,
  }),
  menuList: (provided, state) => ({
    ...provided,
    paddingTop: "0",
    paddingBottom: "0",
  }),
  menu: (provided, state) => ({
    ...provided,
    borderRadius: "5px",
    // height: "15rem",
    // top: "-16rem",
    backgroundColor: "#FFFFFF",
    overflow: "hidden",
  }),
  option: (provided, state) => ({
    ...provided,
    textAlign: "left",
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: "#F1F2F4",
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    fontSize: ".75rem",
    textTransform: "uppercase",
    color: "inherit",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
  }),
};

const days = ["MON", "TUE", "WED", "TUR", "FRI", "SAT", "SUN"];

class PageForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
      subtitle: "",
      language: languages.find(({ value }) => value === this.props.lng),
      resource: resources.find(({ value }) => value === "BLOG"),
      conditions: [
        { id: "DEFAULT", property: null, operator: null, value: null },
      ],
      template: "T1",
      previewPage: null,
      albums: [],
      loading: false,
      asMagazine: false,
    };

    this.categories = []; // Cache categories
    this.tags = []; // Cache Tags
    this.mediaCategories = []; // Cache mediaCategories
    this.webtoolCategories = []; // Cache webtoolCategories
  }

  componentDidMount() {
    const { location, auth } = this.props;
    const { navCommunity } = auth;

    let { page } = qs.parse(location.search.substr(1));

    if (page) {
      page = JSON.parse(page);

      console.log({ page });

      const filters = page.filters ? JSON.parse(page.filters) : [];

      const conditions = filters.map((filter) => {
        let property = properties[page.resource].find(
          ({ value }) => value === filter.property
        );
        if (filter.operator === "in") {
          filter.operator = "eq";
        } else if (filter.operator === "nin") {
          filter.operator = "neq";
        }
        const operator = operators.find(
          ({ value }) => value === filter.operator
        );
        let value = "";
        if (filter.property === "docType") {
          const tmpValue = Array.isArray(filter.value)
            ? filter.value.join(",")
            : filter.value;
          const mediaTypeValue = mediaTypes.filter((c) => c.id === tmpValue);
          value = {
            id: tmpValue,
            label: mediaTypeValue[0].label,
          };
        } else {
          value = { id: filter.value };
        }

        if (filter.property === "category.id") {
          property = properties[page.resource].find(
            ({ value }) => value === "category"
          );
        }
        if (filter.property === "tag.id") {
          property = properties[page.resource].find(
            ({ value }) => value === "tag"
          );
        }
        if (filter.property === "type.id" || filter.property === "docType") {
          property = properties[page.resource].find(
            ({ value }) => value === "type"
          );
        }
        if (filter.property === "theme.id") {
          property = properties[page.resource].find(
            ({ value }) => value === "theme"
          );
        }

        return {
          id: nanoid(),
          property,
          operator,
          value,
        };
      });

      if (page.resource === "ALBUM") {
        this.fetchDataAlbums();
      }

      if (conditions.length === 0) {
        conditions.push({
          id: "DEFAULT",
          property: null,
          operator: null,
          value: null,
        });
      }

      this.setState({
        title: page.title || "",
        subtitle: page.subtitle || "",
        language: languages.find(({ value }) => value === page.language),
        resource: resources.find(({ value }) => value === page.resource),
        conditions,
        template: page.template,
        asMagazine: page.asMagazine,
      });
    }

    this.updatePagePreview();
  }

  componentDidUpdate(prevProps, prevState) {
    const { language, resource, conditions, template, asMagazine } = prevState;
    if (
      language !== this.state.language ||
      resource !== this.state.resource ||
      conditions !== this.state.conditions ||
      template !== this.state.template ||
      asMagazine !== this.state.asMagazine
    ) {
      this.updatePagePreview();
    }
  }

  updatePagePreview = debounce(() => {
    const previewPage = this.getPageData();
    this.setState({ previewPage });
  }, 500);

  handleTitleChange = ({ target }) => {
    this.setState({ title: target.value });
  };

  handleSubtitleChange = ({ target }) => {
    this.setState({ subtitle: target.value });
  };

  handleResourceChange = (resource) => {
    this.setState({ resource });
    this.resetConditions();
    if (resource.value === "ALBUM") {
      this.fetchDataAlbums();
    }
  };

  handleAlbumChange = (album) => {
    this.setState(({ conditions }) => {
      return {
        conditions: conditions.map((c) => {
          if (c.value && c.value.id === album.id) {
            return {
              ...{
                property: { value: "id" },
                operator: { value: "eq", label: "Equal" },
              },
              value: null,
            };
          }
          return {
            ...{
              property: { value: "id" },
              operator: { value: "eq", label: "Equal" },
            },
            value: album,
          };
        }),
      };
    });
  };

  handleDisplayAsMagChange = () => {
    this.setState({ asMagazine: !this.state.asMagazine });
  };

  resetConditions = () => {
    this.setState({
      conditions: [
        { id: "DEFAULT", property: null, operator: null, value: null },
      ],
    });
  };

  handleConditionPropertyChange = (property, condition) => {
    this.setState(({ conditions }) => {
      return {
        conditions: conditions.map((c) => {
          if (c.id !== condition.id) {
            return c;
          }
          return { ...condition, property, operator: null, value: null };
        }),
      };
    });
  };

  handleConditionOperatorChange = (operator, condition) => {
    this.setState(({ conditions }) => {
      return {
        conditions: conditions.map((c) => {
          if (c.id !== condition.id) {
            return c;
          }

          return { ...condition, operator, value: null };
        }),
      };
    });
  };

  handleConditionValueChange = (value, condition) => {
    this.setState(({ conditions }) => {
      return {
        conditions: conditions.map((c) => {
          if (c.id !== condition.id) {
            return c;
          }

          return { ...condition, value };
        }),
      };
    });
  };

  fetchDataAlbums = () => {
    const { auth } = this.props;
    const { language, conditions } = this.state;
    const lng = language.value;

    this.setState({ albums: [], loading: true });

    const { token, navCommunity } = auth;
    const requestUrl = `${TTP_API_URL}/media/album`;
    const filters = [
      {
        property: "organization",
        value: navCommunity.id,
        operator: "eq",
      },
    ];
    const sort = [
      {
        property: "createdAt",
        dir: "desc",
      },
    ];
    const fields = ["*" /*, "preview"*/, "color", "docType", "creator"];

    if (this.lastAlbumRequestSource) {
      this.lastAlbumRequestSource.cancel();
    }

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.lastAlbumRequestSource = source;

    axios
      .get(requestUrl, {
        cancelToken: source.token,
        params: {
          access_token: token,
          organization_id: navCommunity ? navCommunity.id : null,
          fields: fields.join(","),
          filter: JSON.stringify(filters),
          sort: JSON.stringify(sort),
          limit: 50,
        },
      })
      .then((resp) => {
        this.setState({ loading: false, albums: resp.data.data });
      })
      .catch(() => {
        this.setState({ loading: false, albums: [] });
      });
  };

  fetchBlogCategories = debounce((inputValue, callback) => {
    const { auth /*, lng*/ } = this.props;
    //const nameAttr = `name${lng.charAt(0).toUpperCase() + lng.slice(1)}`;

    if (!auth || !auth.navCommunity) {
      return callback([]);
    }

    const { token, navCommunity } = auth;

    // if (this.categories && this.categories.length > 0) {
    //   const filteredCatagories = this.categories.filter(
    //     (c) =>
    //       c[nameAttr] &&
    //       c[nameAttr].toLowerCase().includes(inputValue.toLowerCase())
    //   );
    //   return callback(filteredCatagories);
    // }

    const requestUrl = `${TTP_API_URL}/blog/category`;
    const filters = [];
    const fields = [];

    if (this.lastCategoryRequestSource) {
      this.lastCategoryRequestSource.cancel();
    }

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.lastCategoryRequestSource = source;

    axios
      .get(requestUrl, {
        cancelToken: source.token,
        params: {
          access_token: token,
          organization_id: navCommunity ? navCommunity.id : null,
          fields: fields.join(","),
          filter: JSON.stringify(filters),
        },
      })
      .then((resp) => {
        this.categories = resp.data.data;
        this.setState(({ conditions }) => ({
          conditions: conditions.map((c) => {
            if (
              c.property &&
              c.property.value === "category" &&
              c.operator &&
              c.operator.value &&
              c.value &&
              Object.keys(c.value).length === 1 &&
              Object.keys(c.value)[0] === "id"
            ) {
              let value = c.value;
              if (c.operator.value === "eq") {
                const category = this.categories.find(
                  (cat) => cat.id === c.value.id
                );
                value = category;
              }

              return { ...c, value };
            }

            return c;
          }),
        }));

        callback(resp.data.data);
      })
      .catch(() => callback([]));
  }, 200);

  fetchTags = debounce((inputValue, callback) => {
    const { auth, lng } = this.props;

    if (!auth || !auth.navCommunity) {
      return callback([]);
    }

    const { token, navCommunity } = auth;

    const requestUrl = `${TTP_API_URL}/blog/tag`;
    const filters = [];
    const fields = [];

    if (inputValue && inputValue.length > 0) {
      filters.push({
        property: `name${lng.charAt(0).toUpperCase() + lng.slice(1)}`,
        value: inputValue,
        operator: "like",
      });
    } else {
      const { conditions } = this.state;
      conditions.map((c) => {
        if (
          c.property &&
          c.property.value === "tag" &&
          c.operator &&
          c.operator.value &&
          c.value &&
          Object.keys(c.value).length === 1 &&
          Object.keys(c.value)[0] === "id"
        ) {
          filters.push({
            property: "id",
            value: c.value.id,
            operator: "eq",
          });
        }
      });
    }

    if (this.lastTagRequestSource) {
      this.lastTagRequestSource.cancel();
    }

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.lastTagRequestSource = source;

    axios
      .get(requestUrl, {
        cancelToken: source.token,
        params: {
          access_token: token,
          organization_id: navCommunity ? navCommunity.id : null,
          fields: fields.join(","),
          filter: JSON.stringify(filters),
        },
      })
      .then((resp) => {
        this.tags = resp.data.data;
        this.setState(({ conditions }) => ({
          conditions: conditions.map((c) => {
            if (
              c.property &&
              c.property.value === "tag" &&
              c.operator &&
              c.operator.value &&
              c.value &&
              Object.keys(c.value).length === 1 &&
              Object.keys(c.value)[0] === "id"
            ) {
              let value = c.value;
              if (c.operator.value === "eq") {
                const tag = this.tags.find((cat) => cat.id === c.value.id);
                value = tag;
              }

              return { ...c, value };
            }

            return c;
          }),
        }));

        callback(resp.data.data);
      })
      .catch(() => callback([]));
  }, 200);

  fetchBlogThemes = debounce((inputValue, callback) => {
    const { auth } = this.props;
    const { language, conditions } = this.state;
    const lng = language.value;

    if (!auth || !auth.navCommunity) {
      return callback([]);
    }

    const { token, navCommunity } = auth;

    const requestUrl = `${TTP_API_URL}/blog/theme`;
    const filters = [
      {
        property: "isDefault",
        value: 0,
        operator: "eq",
      },
      {
        property: "organization",
        value: navCommunity.id,
        operator: "eq",
      },
      {
        property: "language",
        value: lng,
        operator: "eq",
      },
    ];
    const fields = ["*"];

    if (this.lastThemeRequestSource) {
      this.lastThemeRequestSource.cancel();
    }

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.lastThemeRequestSource = source;

    axios
      .get(requestUrl, {
        cancelToken: source.token,
        params: {
          access_token: token,
          organization_id: navCommunity ? navCommunity.id : null,
          fields: fields.join(","),
          filter: JSON.stringify(filters),
        },
      })
      .then((resp) => {
        const themes = resp.data.data;
        this.setState(({ conditions }) => ({
          conditions: conditions.map((c) => {
            if (
              c.property &&
              c.property.value === "theme" &&
              c.operator &&
              c.operator.value &&
              c.value &&
              Object.keys(c.value).length === 1 &&
              Object.keys(c.value)[0] === "id"
            ) {
              let value = c.value;
              if (c.operator.value === "eq") {
                const theme = themes.find((t) => t.id === c.value.id);
                value = theme;
              }

              return { ...c, value };
            }

            return c;
          }),
        }));

        callback(resp.data.data);
      })
      .catch(() => callback([]));
  }, 200);

  fetchBlogTypes = debounce((inputValue, callback) => {
    const { auth } = this.props;
    const { language } = this.state;
    const lng = language.value;

    if (!auth || !auth.navCommunity) {
      return callback([]);
    }

    const { token, navCommunity } = auth;

    const requestUrl = `${TTP_API_URL}/blog/type`;
    const filters = [
      {
        property: "organization",
        value: navCommunity.id,
        operator: "eq",
      },
      {
        property: "language",
        value: lng,
        operator: "eq",
      },
    ];
    const fields = ["*"];

    if (this.lastTypeRequestSource) {
      this.lastTypeRequestSource.cancel();
    }

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.lastTypeRequestSource = source;

    axios
      .get(requestUrl, {
        cancelToken: source.token,
        params: {
          access_token: token,
          organization_id: navCommunity ? navCommunity.id : null,
          fields: fields.join(","),
          filter: JSON.stringify(filters),
        },
      })
      .then((resp) => {
        const types = resp.data.data;
        this.setState(({ conditions }) => ({
          conditions: conditions.map((c) => {
            if (
              c.property &&
              c.property.value === "type" &&
              c.operator &&
              c.operator.value &&
              c.value &&
              Object.keys(c.value).length === 1 &&
              Object.keys(c.value)[0] === "id"
            ) {
              let value = c.value;
              if (c.operator.value === "eq") {
                const type = types.find((t) => t.id === c.value.id);
                value = type;
                console.log("GOT IT ", type);
              }

              return { ...c, value };
            }

            return c;
          }),
        }));

        callback(resp.data.data);
      })
      .catch(() => callback([]));
  }, 200);

  fetchMediaCategories = debounce((inputValue, callback) => {
    const { auth /*, lng*/ } = this.props;
    //const nameAttr = `name${lng.charAt(0).toUpperCase() + lng.slice(1)}`;

    if (!auth || !auth.navCommunity) {
      return callback([]);
    }

    const { token, navCommunity } = auth;

    // if (this.mediaCategories && this.mediaCategories.length > 0) {
    //   const filteredCatagories = this.mediaCategories.filter(
    //     (c) =>
    //       c[nameAttr] &&
    //       c[nameAttr].toLowerCase().includes(inputValue.toLowerCase())
    //   );
    //   return callback(filteredCatagories);
    // }

    const requestUrl = `${TTP_API_URL}/media/category`;
    const filters = [{ property: "type", value: "MEDIA", operator: "like" }];

    if (this.lastMediaRequestSource) {
      this.lastMediaRequestSource.cancel();
    }

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.lastMediaRequestSource = source;

    axios
      .get(requestUrl, {
        cancelToken: source.token,
        params: {
          access_token: token,
          organization_id: navCommunity ? navCommunity.id : null,
          filter: JSON.stringify(filters),
        },
      })
      .then((resp) => {
        this.mediaCategories = resp.data.data;
        this.setState(({ conditions }) => ({
          conditions: conditions.map((c) => {
            if (
              c.property &&
              c.property.value === "category" &&
              c.operator &&
              c.operator.value &&
              c.value &&
              Object.keys(c.value).length === 1 &&
              Object.keys(c.value)[0] === "id"
            ) {
              let value = c.value;
              if (c.operator.value === "eq") {
                const category = this.mediaCategories.find(
                  (cat) => cat.id === c.value.id
                );
                value = category;
              }

              return { ...c, value };
            }

            return c;
          }),
        }));

        callback(resp.data.data);
      })
      .catch(() => callback([]));
  }, 200);

  fetchWebtoolCategories = debounce((inputValue, callback) => {
    const { auth } = this.props;

    if (!auth || !auth.navCommunity) {
      return callback([]);
    }

    const { token, navCommunity } = auth;

    const requestUrl = `${TTP_API_URL}/media/category`;
    const filters = [{ property: "type", value: "WEBTOOL", operator: "like" }];

    if (this.lastMediaRequestSource) {
      this.lastMediaRequestSource.cancel();
    }

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.lastMediaRequestSource = source;

    axios
      .get(requestUrl, {
        cancelToken: source.token,
        params: {
          access_token: token,
          organization_id: navCommunity ? navCommunity.id : null,
          filter: JSON.stringify(filters),
        },
      })
      .then((resp) => {
        this.webtoolCategories = resp.data.data;
        this.setState(({ conditions }) => ({
          conditions: conditions.map((c) => {
            if (
              c.property &&
              c.property.value === "category" &&
              c.operator &&
              c.operator.value &&
              c.value &&
              Object.keys(c.value).length === 1 &&
              Object.keys(c.value)[0] === "id"
            ) {
              let value = c.value;
              if (c.operator.value === "eq") {
                const category = this.webtoolCategories.find(
                  (cat) => cat.id === c.value.id
                );
                value = category;
              }

              return { ...c, value };
            }

            return c;
          }),
        }));

        callback(resp.data.data);
      })
      .catch(() => callback([]));
  }, 200);

  addCondition = () => {
    this.setState({
      conditions: [
        ...this.state.conditions,
        {
          id: nanoid(),
          property: null,
          operator: null,
          value: null,
        },
      ],
    });
  };

  deleteCondition = (condition) => {
    const { conditions } = this.state;
    const newConditions = conditions.filter((c) => c.id !== condition.id);

    this.setState({ conditions: newConditions });
  };

  getPageData = () => {
    const {
      language,
      title,
      subtitle,
      resource,
      conditions,
      template,
      asMagazine,
    } = this.state;
    const { navCommunity, user } = this.props.auth;

    const filters = conditions
      .filter(
        ({ property, operator, value }) =>
          property !== null && operator !== null && value !== null
      )
      .map((condition) => {
        let property = condition.property.value;
        let operator = condition.operator.value;
        let value = condition.value !== undefined ? condition.value.id : null;

        if (property === "category") {
          property = "category.id";
        }
        if (property === "tag") {
          property = "tag.id";
        }

        if (property === "theme") {
          property = "theme.id";
        }

        if (property === "type") {
          switch (resource.value) {
            case "BLOG":
              property = "type.id";
              break;
            case "MEDIA":
              property = "docType";
              operator = operator === "neq" ? "nin" : "in";
              if (Array.isArray(condition.value)) {
                value = condition.value.map((v) => v.id);
                value = value[0].split(",");
              } else if (typeof value === "string" || value instanceof String) {
                value = value.split(",");
              }
              break;
            case "EVENT":
              property = "type";
              break;
            case "EMAILING":
              property = "language";
              break;
            default:
              break;
          }
        }

        return {
          property,
          operator,
          value,
        };
      });

    return {
      title,
      subtitle,
      language: language.value,
      resource: resource.value,
      filters,
      template: template,
      communityId: navCommunity.id,
      userId: user.id,
      asMagazine,
    };
  };

  save = () => {
    console.log("save ...");
    const { match, history } = this.props;
    const { pageId } = match.params;
    //const { language, title, resource, conditions, template } = this.state;

    // TODO validation

    const data = this.getPageData();
    data.id = pageId;
    console.log(data);

    this.props
      .savePage(data)
      .then(() => {
        history.push("/");
      })
      .catch(() => {
        toast.error("Could not save page");
      });
  };

  /*<div className={styles.header}>
        <div className={styles.headerContent}>
          <div className={styles.headerIcon}>
            <img src="/img/icons/articles.svg" alt="page" />
          </div>
          <div className={styles.headerInfo}>
            <div>
              <h3>{"Mise en page"}</h3>
              <p>{"Configurer la mise en page"}</p>
            </div>
          </div>
        </div>
    </div>*/
  renderHeader() {
    return (
      <div className={styles.header}>
        <NavLink to="/" className={styles.back}>
          <span className={styles.arrow} />
          {_("Home")}
        </NavLink>
        <h1>{_("Create a new Page")}</h1>
      </div>
    );
  }

  renderConditionValue = (condition) => {
    const { resource, language } = this.state;
    const lng = language.value;

    const nameAttr = `name${lng.charAt(0).toUpperCase() + lng.slice(1)}`;

    if (!condition.property | !condition.operator) {
      return (
        <Select
          styles={selectStyles}
          menuPlacement="auto"
          placeholder=""
          options={[]}
          noOptionsMessage={() => null}
          openMenuOnClick={false}
          isDisabled
        />
      );
    }

    if (condition.property.value === "tag") {
      return (
        <AsyncSelect
          styles={selectStyles}
          loadOptions={this.fetchTags}
          defaultOptions
          getOptionLabel={(option) => option[nameAttr]}
          getOptionValue={(option) => option.id}
          value={condition.value}
          onChange={(value) =>
            this.handleConditionValueChange(value, condition)
          }
          placeholder={""}
          loadingMessage={() => _("Loading...")}
          noOptionsMessage={() => null}
          openMenuOnClick={true}
        />
      );
    }

    if (resource && resource.value === "BLOG") {
      if (condition.property.value === "category") {
        return (
          <AsyncSelect
            styles={selectStyles}
            loadOptions={this.fetchBlogCategories}
            defaultOptions
            getOptionLabel={(option) => option[nameAttr]}
            getOptionValue={(option) => option.id}
            value={condition.value}
            onChange={(value) =>
              this.handleConditionValueChange(value, condition)
            }
            placeholder={""}
            loadingMessage={() => _("Loading...")}
            noOptionsMessage={() => null}
            openMenuOnClick={true}
          />
        );
      }

      if (condition.property.value === "theme") {
        return (
          <AsyncSelect
            styles={selectStyles}
            loadOptions={this.fetchBlogThemes}
            defaultOptions
            getOptionLabel={(option) => option.title}
            getOptionValue={(option) => option.id}
            value={condition.value}
            onChange={(value) =>
              this.handleConditionValueChange(value, condition)
            }
            placeholder={""}
            loadingMessage={() => _("Loading...")}
            noOptionsMessage={() => null}
            openMenuOnClick={true}
          />
        );
      }

      if (condition.property.value === "type") {
        return (
          <AsyncSelect
            styles={selectStyles}
            loadOptions={this.fetchBlogTypes}
            defaultOptions
            getOptionLabel={(option) => option.abbreviation || option.name}
            getOptionValue={(option) => option.id}
            value={condition.value}
            onChange={(value) =>
              this.handleConditionValueChange(value, condition)
            }
            placeholder={""}
            loadingMessage={() => _("Loading...")}
            noOptionsMessage={() => null}
            openMenuOnClick={true}
          />
        );
      }
    }

    if (resource && resource.value === "MEDIA") {
      if (condition.property.value === "category") {
        return (
          <AsyncSelect
            styles={selectStyles}
            loadOptions={this.fetchMediaCategories}
            defaultOptions
            getOptionLabel={(option) => option[nameAttr]}
            getOptionValue={(option) => option.id}
            value={condition.value}
            onChange={(value) =>
              this.handleConditionValueChange(value, condition)
            }
            placeholder={""}
            loadingMessage={() => _("Loading...")}
            noOptionsMessage={() => null}
            openMenuOnClick={true}
          />
        );
      }

      if (condition.property.value === "type") {
        return (
          <Select
            styles={selectStyles}
            options={mediaTypes}
            value={condition.value}
            onChange={(value) =>
              this.handleConditionValueChange(value, condition)
            }
            noOptionsMessage={() => null}
            openMenuOnClick={true}
          />
        );
      }
    }
    if (resource && resource.value === "EVENT") {
      if (condition.property.value === "type") {
        return (
          <Select
            styles={selectStyles}
            options={
              /*[
              { label: "Paid", id: 6 },
              { label: "Free", id: 7 },
              { label: "Customized", id: 5 },
            ]*/
              [
                { label: "This week", id: "current" },
                { label: "The upcoming", id: "future" },
                { label: "In replay", id: "replay" },
                { label: "Cycle", id: "cycle" },
                { label: "My space", id: "my_space" },
                { label: "My replays", id: "my_replays" },
              ]
            }
            value={condition.value}
            onChange={(value) =>
              this.handleConditionValueChange(value, condition)
            }
            noOptionsMessage={() => null}
            openMenuOnClick={true}
          />
        );
      }
    }
    if (resource && resource.value === "EMAILING") {
      if (condition.property.value === "language") {
        return (
          <Select
            styles={selectStyles}
            options={[
              { label: "fr", id: "fr" },
              { label: "en", id: "en" },
              { label: "nl", id: "nl" },
            ]}
            value={condition.value}
            onChange={(value) =>
              this.handleConditionValueChange(value, condition)
            }
            noOptionsMessage={() => null}
            openMenuOnClick={true}
          />
        );
      }
    }
    if (resource && resource.value === "WEBTOOL") {
      if (condition.property.value === "category") {
        return (
          <AsyncSelect
            styles={selectStyles}
            loadOptions={this.fetchWebtoolCategories}
            defaultOptions
            getOptionLabel={(option) => option[nameAttr]}
            getOptionValue={(option) => option.id}
            value={condition.value}
            onChange={(value) =>
              this.handleConditionValueChange(value, condition)
            }
            placeholder={""}
            loadingMessage={() => _("Loading...")}
            noOptionsMessage={() => null}
            openMenuOnClick={true}
          />
        );
      }
    }

    return (
      <Select
        styles={selectStyles}
        menuPlacement="auto"
        placeholder=""
        options={[]}
        noOptionsMessage={() => null}
        openMenuOnClick={false}
        isDisabled
      />
    );
  };

  renderLayout = () => {
    const { resource, template, conditions } = this.state;

    if (resource && resource.value === "BLOG") {
      return (
        <div className={`${styles.layout} ${styles.layout_blog}`}>
          {["T1", "T2", "T3", "T4", "T5", "T6", "T7"].map((item) => {
            return (
              <div
                key={`templ-${item}`}
                className={`${styles.layoutModel} ${
                  template === item ? styles.layoutModelSelected : ""
                }`}
                onClick={() => this.setState({ template: item })}
              >
                <img
                  src={`/img/models/model-${item}.svg`}
                  alt={`model-${item}`}
                />
              </div>
            );
          })}
        </div>
      );
    }
    if (resource && ["MEDIA", "FORM", "ALBUM"].indexOf(resource.value) !== -1) {
      let typeIsAlbum = false;
      if (resource.value === "MEDIA") {
        for (let i = 0; i < conditions.length; i++) {
          if (
            conditions[i] &&
            conditions[i].value &&
            conditions[i].value.id === "ALBUM"
          ) {
            typeIsAlbum = true;
            break;
          }
        }
      }
      return (
        <div className={styles.layout}>
          <div
            className={`${styles.layoutModel} ${
              template === "T1" ? styles.layoutModelSelected : ""
            }`}
            onClick={() => this.setState({ template: "T1" })}
          >
            <svg viewBox="0 0 80 110" fill="none">
              <rect width="19" height="16" rx="1" fill="#D8DDE2" />
              <rect x="42" width="19" height="16" rx="1" fill="#D8DDE2" />
              <rect x="21" width="19" height="16" rx="1" fill="#D8DDE2" />
              <rect y="19" width="19" height="16" rx="1" fill="#D8DDE2" />
              <rect
                y="19"
                x="42"
                width="19"
                height="16"
                rx="1"
                fill="#D8DDE2"
              />
              <rect
                y="19"
                x="21"
                width="19"
                height="16"
                rx="1"
                fill="#D8DDE2"
              />
              <rect y="38" width="19" height="16" rx="1" fill="#D8DDE2" />
              <rect
                y="38"
                x="42"
                width="19"
                height="16"
                rx="1"
                fill="#D8DDE2"
              />
              <rect
                y="38"
                x="21"
                width="19"
                height="16"
                rx="1"
                fill="#D8DDE2"
              />
              <rect y="57" width="19" height="16" rx="1" fill="#D8DDE2" />
              <rect
                y="57"
                x="42"
                width="19"
                height="16"
                rx="1"
                fill="#D8DDE2"
              />
              <rect
                y="57"
                x="21"
                width="19"
                height="16"
                rx="1"
                fill="#D8DDE2"
              />
              <rect y="76" width="19" height="16" rx="1" fill="#D8DDE2" />
              <rect
                y="76"
                x="42"
                width="19"
                height="16"
                rx="1"
                fill="#D8DDE2"
              />
              <rect
                y="76"
                x="21"
                width="19"
                height="16"
                rx="1"
                fill="#D8DDE2"
              />
              <rect y="95" width="19" height="16" rx="1" fill="#D8DDE2" />
              <rect
                y="95"
                x="42"
                width="19"
                height="16"
                rx="1"
                fill="#D8DDE2"
              />
              <rect
                y="95"
                x="21"
                width="19"
                height="16"
                rx="1"
                fill="#D8DDE2"
              />

              <rect x="64" width="16" height="52" rx="1" fill="#EBEEF0" />
              <rect
                x="64"
                y="54"
                width="16"
                height="16"
                rx="1"
                fill="#EBEEF0"
              />
              <rect
                x="64"
                y="72"
                width="16"
                height="16"
                rx="1"
                fill="#EBEEF0"
              />
            </svg>
          </div>
          {resource.value === "FORUM" || typeIsAlbum
            ? null
            : [
                <div
                  className={`${styles.layoutModel} ${
                    template === "T2" ? styles.layoutModelSelected : ""
                  }`}
                  onClick={() => this.setState({ template: "T2" })}
                >
                  <svg viewBox="0 0 80 110" fill="none">
                    <rect width="19" height="30" rx="1" fill="#D8DDE2" />
                    <rect x="42" width="19" height="30" rx="1" fill="#D8DDE2" />
                    <rect x="21" width="19" height="30" rx="1" fill="#D8DDE2" />
                    <rect x="64" width="19" height="30" rx="1" fill="#D8DDE2" />

                    <rect y="33" width="19" height="30" rx="1" fill="#D8DDE2" />
                    <rect
                      y="33"
                      x="42"
                      width="19"
                      height="30"
                      rx="1"
                      fill="#D8DDE2"
                    />
                    <rect
                      y="33"
                      x="21"
                      width="19"
                      height="30"
                      rx="1"
                      fill="#D8DDE2"
                    />
                    <rect
                      y="33"
                      x="64"
                      width="19"
                      height="30"
                      rx="1"
                      fill="#D8DDE2"
                    />
                    <rect y="66" width="19" height="30" rx="1" fill="#D8DDE2" />
                    <rect
                      y="66"
                      x="42"
                      width="19"
                      height="30"
                      rx="1"
                      fill="#D8DDE2"
                    />
                    <rect
                      y="66"
                      x="21"
                      width="19"
                      height="30"
                      rx="1"
                      fill="#D8DDE2"
                    />
                    <rect
                      y="66"
                      x="64"
                      width="19"
                      height="30"
                      rx="1"
                      fill="#D8DDE2"
                    />
                    <rect y="99" width="19" height="20" rx="1" fill="#D8DDE2" />
                    <rect
                      y="99"
                      x="42"
                      width="19"
                      height="20"
                      rx="1"
                      fill="#D8DDE2"
                    />
                    <rect
                      y="99"
                      x="21"
                      width="19"
                      height="20"
                      rx="1"
                      fill="#D8DDE2"
                    />
                    <rect
                      y="99"
                      x="64"
                      width="19"
                      height="20"
                      rx="1"
                      fill="#D8DDE2"
                    />
                  </svg>
                </div>,
                <div
                  className={`${styles.layoutModel} ${
                    template === "T6" ? styles.layoutModelSelected : ""
                  }`}
                  onClick={() => this.setState({ template: "T6" })}
                >
                  <svg viewBox="0 0 80 110" fill="none">
                    <rect width="83" height="40" rx="1" fill="#D8DDE2" />

                    <rect y="42" width="26" height="30" rx="1" fill="#D8DDE2" />
                    <rect
                      y="42"
                      x="28"
                      width="27"
                      height="30"
                      rx="1"
                      fill="#D8DDE2"
                    />
                    <rect
                      y="42"
                      x="57"
                      width="26"
                      height="30"
                      rx="1"
                      fill="#D8DDE2"
                    />
                    <rect y="74" width="26" height="30" rx="1" fill="#D8DDE2" />
                    <rect
                      y="74"
                      x="28"
                      width="55"
                      height="30"
                      rx="1"
                      fill="#D8DDE2"
                    />

                    <rect
                      y="106"
                      width="40"
                      height="30"
                      rx="1"
                      fill="#D8DDE2"
                    />
                    <rect
                      y="106"
                      x="42"
                      width="41"
                      height="30"
                      rx="1"
                      fill="#D8DDE2"
                    />
                  </svg>
                </div>,
              ]}
          {!typeIsAlbum
            ? null
            : [
                <div
                  className={`${styles.layoutModel} ${
                    template === "T3" ? styles.layoutModelSelected : ""
                  }`}
                  onClick={() => this.setState({ template: "T3" })}
                >
                  <svg viewBox="0 0 80 110" fill="none">
                    <rect width="60" height="5" rx="1" fill="#EBEEF0" />
                    <rect
                      x="8"
                      y="8"
                      width="19"
                      height="19"
                      rx="1"
                      fill="#D8DDE2"
                    />
                    <rect
                      x="50"
                      y="8"
                      width="19"
                      height="19"
                      rx="1"
                      fill="#D8DDE2"
                    />
                    <rect
                      x="29"
                      y="8"
                      width="19"
                      height="19"
                      rx="1"
                      fill="#D8DDE2"
                    />
                    <rect
                      x="72"
                      y="8"
                      width="19"
                      height="19"
                      rx="1"
                      fill="#D8DDE2"
                    />

                    <rect
                      x="8"
                      y="30"
                      width="19"
                      height="19"
                      rx="1"
                      fill="#D8DDE2"
                    />
                    <rect
                      y="30"
                      x="50"
                      width="19"
                      height="19"
                      rx="1"
                      fill="#D8DDE2"
                    />
                    <rect
                      y="30"
                      x="29"
                      width="19"
                      height="19"
                      rx="1"
                      fill="#D8DDE2"
                    />
                    <rect
                      y="30"
                      x="72"
                      width="19"
                      height="19"
                      rx="1"
                      fill="#D8DDE2"
                    />

                    <rect y="52" width="60" height="5" rx="1" fill="#EBEEF0" />
                    <rect
                      x="8"
                      y="60"
                      width="19"
                      height="19"
                      rx="1"
                      fill="#D8DDE2"
                    />
                    <rect
                      y="60"
                      x="50"
                      width="19"
                      height="19"
                      rx="1"
                      fill="#D8DDE2"
                    />
                    <rect
                      y="60"
                      x="29"
                      width="19"
                      height="19"
                      rx="1"
                      fill="#D8DDE2"
                    />
                    <rect
                      y="60"
                      x="72"
                      width="19"
                      height="19"
                      rx="1"
                      fill="#D8DDE2"
                    />

                    <rect
                      x="8"
                      y="82"
                      width="19"
                      height="19"
                      rx="1"
                      fill="#D8DDE2"
                    />
                    <rect
                      y="82"
                      x="50"
                      width="19"
                      height="19"
                      rx="1"
                      fill="#D8DDE2"
                    />
                    <rect
                      y="82"
                      x="29"
                      width="19"
                      height="19"
                      rx="1"
                      fill="#D8DDE2"
                    />
                    <rect
                      y="82"
                      x="72"
                      width="19"
                      height="19"
                      rx="1"
                      fill="#D8DDE2"
                    />

                    <rect
                      x="8"
                      y="104"
                      width="19"
                      height="19"
                      rx="1"
                      fill="#D8DDE2"
                    />
                    <rect
                      y="104"
                      x="50"
                      width="19"
                      height="19"
                      rx="1"
                      fill="#D8DDE2"
                    />
                    <rect
                      y="104"
                      x="29"
                      width="19"
                      height="19"
                      rx="1"
                      fill="#D8DDE2"
                    />
                    <rect
                      y="104"
                      x="72"
                      width="19"
                      height="19"
                      rx="1"
                      fill="#D8DDE2"
                    />
                  </svg>
                </div>,
                <div
                  className={`${styles.layoutModel} ${
                    template === "T4" ? styles.layoutModelSelected : ""
                  }`}
                  onClick={() => this.setState({ template: "T4" })}
                >
                  <svg viewBox="0 0 80 110" fill="none">
                    <rect width="60" height="5" rx="1" fill="#EBEEF0" />
                    <rect
                      x="8"
                      y="8"
                      width="19"
                      height="30"
                      rx="1"
                      fill="#D8DDE2"
                    />
                    <rect
                      x="50"
                      y="8"
                      width="19"
                      height="30"
                      rx="1"
                      fill="#D8DDE2"
                    />
                    <rect
                      x="29"
                      y="8"
                      width="19"
                      height="30"
                      rx="1"
                      fill="#D8DDE2"
                    />
                    <rect
                      x="72"
                      y="8"
                      width="19"
                      height="30"
                      rx="1"
                      fill="#D8DDE2"
                    />

                    <rect
                      x="8"
                      y="41"
                      width="19"
                      height="30"
                      rx="1"
                      fill="#D8DDE2"
                    />
                    <rect
                      y="41"
                      x="50"
                      width="19"
                      height="30"
                      rx="1"
                      fill="#D8DDE2"
                    />
                    <rect
                      y="41"
                      x="29"
                      width="19"
                      height="30"
                      rx="1"
                      fill="#D8DDE2"
                    />
                    <rect
                      y="41"
                      x="72"
                      width="19"
                      height="30"
                      rx="1"
                      fill="#D8DDE2"
                    />
                    <rect y="75" width="60" height="5" rx="1" fill="#EBEEF0" />

                    <rect
                      x="8"
                      y="83"
                      width="19"
                      height="30"
                      rx="1"
                      fill="#D8DDE2"
                    />
                    <rect
                      y="83"
                      x="50"
                      width="19"
                      height="30"
                      rx="1"
                      fill="#D8DDE2"
                    />
                    <rect
                      y="83"
                      x="29"
                      width="19"
                      height="30"
                      rx="1"
                      fill="#D8DDE2"
                    />
                    <rect
                      y="83"
                      x="72"
                      width="19"
                      height="30"
                      rx="1"
                      fill="#D8DDE2"
                    />
                  </svg>
                </div>,
                <div
                  className={`${styles.layoutModel} ${
                    template === "T5" ? styles.layoutModelSelected : ""
                  }`}
                  onClick={() => this.setState({ template: "T5" })}
                >
                  <svg viewBox="0 0 80 110" fill="none">
                    <rect width="70" height="5" rx="1" fill="#D8DDE2" />
                    <rect
                      x="8"
                      y="8"
                      width="10"
                      height="10"
                      rx="1"
                      fill="#EBEEF0"
                    />
                    <rect
                      x="20"
                      y="11"
                      width="60"
                      height="4"
                      rx="1"
                      fill="#EBEEF0"
                    />
                    <rect
                      x="8"
                      y="20"
                      width="10"
                      height="10"
                      rx="1"
                      fill="#EBEEF0"
                    />
                    <rect
                      x="20"
                      y="23"
                      width="60"
                      height="4"
                      rx="1"
                      fill="#EBEEF0"
                    />
                    <rect
                      x="8"
                      y="32"
                      width="10"
                      height="10"
                      rx="1"
                      fill="#EBEEF0"
                    />
                    <rect
                      x="20"
                      y="35"
                      width="60"
                      height="4"
                      rx="1"
                      fill="#EBEEF0"
                    />
                    <rect
                      x="8"
                      y="44"
                      width="10"
                      height="10"
                      rx="1"
                      fill="#EBEEF0"
                    />
                    <rect
                      x="20"
                      y="47"
                      width="60"
                      height="4"
                      rx="1"
                      fill="#EBEEF0"
                    />

                    <rect y="60" width="70" height="5" rx="1" fill="#D8DDE2" />
                    <rect
                      x="8"
                      y="68"
                      width="10"
                      height="10"
                      rx="1"
                      fill="#EBEEF0"
                    />
                    <rect
                      x="20"
                      y="71"
                      width="60"
                      height="4"
                      rx="1"
                      fill="#EBEEF0"
                    />
                    <rect
                      x="8"
                      y="80"
                      width="10"
                      height="10"
                      rx="1"
                      fill="#EBEEF0"
                    />
                    <rect
                      x="20"
                      y="83"
                      width="60"
                      height="4"
                      rx="1"
                      fill="#EBEEF0"
                    />
                    <rect
                      x="8"
                      y="92"
                      width="10"
                      height="10"
                      rx="1"
                      fill="#EBEEF0"
                    />
                    <rect
                      x="20"
                      y="95"
                      width="60"
                      height="4"
                      rx="1"
                      fill="#EBEEF0"
                    />
                  </svg>
                </div>,
              ]}
        </div>
      );
    }

    if (resource && resource.value === "EVENT") {
      return (
        <div className={styles.layout}>
          <div
            className={`${styles.layoutModel} ${
              template === "T1" ? styles.layoutModelSelected : ""
            }`}
            onClick={() => this.setState({ template: "T1" })}
          >
            <svg viewBox="0 0 80 110" fill="none">
              <rect width="19" height="20" rx="1" fill="#D8DDE2" />
              <rect x="42" width="19" height="20" rx="1" fill="#D8DDE2" />
              <rect x="21" width="19" height="20" rx="1" fill="#D8DDE2" />
              <rect y="23" width="19" height="20" rx="1" fill="#D8DDE2" />
              <rect
                y="23"
                x="42"
                width="19"
                height="20"
                rx="1"
                fill="#D8DDE2"
              />
              <rect
                y="23"
                x="21"
                width="19"
                height="20"
                rx="1"
                fill="#D8DDE2"
              />
              <rect y="46" width="19" height="20" rx="1" fill="#D8DDE2" />
              <rect
                y="46"
                x="42"
                width="19"
                height="20"
                rx="1"
                fill="#D8DDE2"
              />
              <rect
                y="46"
                x="21"
                width="19"
                height="20"
                rx="1"
                fill="#D8DDE2"
              />
              <rect y="69" width="19" height="20" rx="1" fill="#D8DDE2" />
              <rect
                y="69"
                x="42"
                width="19"
                height="20"
                rx="1"
                fill="#D8DDE2"
              />
              <rect
                y="69"
                x="21"
                width="19"
                height="20"
                rx="1"
                fill="#D8DDE2"
              />
              <rect y="92" width="19" height="20" rx="1" fill="#D8DDE2" />
              <rect
                y="92"
                x="42"
                width="19"
                height="20"
                rx="1"
                fill="#D8DDE2"
              />
              <rect
                y="92"
                x="21"
                width="19"
                height="20"
                rx="1"
                fill="#D8DDE2"
              />
              <rect y="115" width="19" height="20" rx="1" fill="#D8DDE2" />
              <rect
                y="115"
                x="42"
                width="19"
                height="20"
                rx="1"
                fill="#D8DDE2"
              />
              <rect
                y="115"
                x="21"
                width="19"
                height="20"
                rx="1"
                fill="#D8DDE2"
              />

              <rect x="64" width="16" height="52" rx="1" fill="#EBEEF0" />
              <rect
                x="64"
                y="54"
                width="16"
                height="16"
                rx="1"
                fill="#EBEEF0"
              />
              <rect
                x="64"
                y="72"
                width="16"
                height="16"
                rx="1"
                fill="#EBEEF0"
              />
            </svg>
          </div>
        </div>
      );
    }

    if (resource && resource.value === "WEBTOOL") {
      return (
        <div className={styles.layout}>
          <div
            className={`${styles.layoutModel} ${
              template === "T1" ? styles.layoutModelSelected : ""
            }`}
            onClick={() => this.setState({ template: "T1" })}
          >
            <svg viewBox="0 0 80 110" fill="none">
              <rect width="19" height="20" rx="1" fill="#D8DDE2" />
              <rect x="42" width="19" height="20" rx="1" fill="#D8DDE2" />
              <rect x="21" width="19" height="20" rx="1" fill="#D8DDE2" />
              <rect y="23" width="19" height="20" rx="1" fill="#D8DDE2" />
              <rect
                y="23"
                x="42"
                width="19"
                height="20"
                rx="1"
                fill="#D8DDE2"
              />
              <rect
                y="23"
                x="21"
                width="19"
                height="20"
                rx="1"
                fill="#D8DDE2"
              />
              <rect y="46" width="19" height="20" rx="1" fill="#D8DDE2" />
              <rect
                y="46"
                x="42"
                width="19"
                height="20"
                rx="1"
                fill="#D8DDE2"
              />
              <rect
                y="46"
                x="21"
                width="19"
                height="20"
                rx="1"
                fill="#D8DDE2"
              />
              <rect y="69" width="19" height="20" rx="1" fill="#D8DDE2" />
              <rect
                y="69"
                x="42"
                width="19"
                height="20"
                rx="1"
                fill="#D8DDE2"
              />
              <rect
                y="69"
                x="21"
                width="19"
                height="20"
                rx="1"
                fill="#D8DDE2"
              />
              <rect y="92" width="19" height="20" rx="1" fill="#D8DDE2" />
              <rect
                y="92"
                x="42"
                width="19"
                height="20"
                rx="1"
                fill="#D8DDE2"
              />
              <rect
                y="92"
                x="21"
                width="19"
                height="20"
                rx="1"
                fill="#D8DDE2"
              />
              <rect y="115" width="19" height="20" rx="1" fill="#D8DDE2" />
              <rect
                y="115"
                x="42"
                width="19"
                height="20"
                rx="1"
                fill="#D8DDE2"
              />
              <rect
                y="115"
                x="21"
                width="19"
                height="20"
                rx="1"
                fill="#D8DDE2"
              />

              <rect x="64" width="16" height="52" rx="1" fill="#EBEEF0" />
              <rect
                x="64"
                y="54"
                width="16"
                height="16"
                rx="1"
                fill="#EBEEF0"
              />
              <rect
                x="64"
                y="72"
                width="16"
                height="16"
                rx="1"
                fill="#EBEEF0"
              />
            </svg>
          </div>
        </div>
      );
    }

    if (resource && resource.value === "EMAILING") {
      return (
        <div className={styles.layout}>
          <div
            className={`${styles.layoutModel} ${
              template === "T1" ? styles.layoutModelSelected : ""
            }`}
            onClick={() => this.setState({ template: "T1" })}
          >
            <svg viewBox="0 0 80 110" fill="none">
              <rect width="19" height="30" rx="1" fill="#D8DDE2" />
              <rect x="42" width="19" height="30" rx="1" fill="#D8DDE2" />
              <rect x="21" width="19" height="30" rx="1" fill="#D8DDE2" />
              <rect y="33" width="19" height="30" rx="1" fill="#D8DDE2" />
              <rect
                y="33"
                x="42"
                width="19"
                height="30"
                rx="1"
                fill="#D8DDE2"
              />
              <rect
                y="33"
                x="21"
                width="19"
                height="30"
                rx="1"
                fill="#D8DDE2"
              />
              <rect y="66" width="19" height="30" rx="1" fill="#D8DDE2" />
              <rect
                y="66"
                x="42"
                width="19"
                height="30"
                rx="1"
                fill="#D8DDE2"
              />
              <rect
                y="66"
                x="21"
                width="19"
                height="30"
                rx="1"
                fill="#D8DDE2"
              />
              <rect y="115" width="19" height="20" rx="1" fill="#D8DDE2" />
              <rect
                y="115"
                x="42"
                width="19"
                height="20"
                rx="1"
                fill="#D8DDE2"
              />
              <rect
                y="115"
                x="21"
                width="19"
                height="20"
                rx="1"
                fill="#D8DDE2"
              />

              <rect x="64" width="16" height="52" rx="1" fill="#EBEEF0" />
              <rect
                x="64"
                y="54"
                width="16"
                height="16"
                rx="1"
                fill="#EBEEF0"
              />
              <rect
                x="64"
                y="72"
                width="16"
                height="16"
                rx="1"
                fill="#EBEEF0"
              />
            </svg>
          </div>
        </div>
      );
    }
  };

  renderForm() {
    const { saving } = this.props;
    const {
      language,
      title,
      subtitle,
      resource,
      conditions,
      albums,
      loading,
      template,
    } = this.state;

    let showAskMagazine =
      resource && resource.value === "ALBUM" && template === "T2"
        ? true
        : false;
    if (resource && resource.value === "MEDIA" && template === "T2") {
      for (let i = 0; i < conditions.length; i++) {
        if (
          conditions[i] &&
          conditions[i].value &&
          conditions[i].value.label === "Document"
        ) {
          showAskMagazine = true;
          break;
        }
      }
    }

    return (
      <div className={styles.formContainer}>
        <h2>{_("configure your new page")}</h2>
        <p>{_("sed do eiusmod tempor incididunt ut labore et dolore")}</p>

        <div className={styles.form}>
          <div className={styles.formControl}>
            <label className={styles.formLabel}>{_("Language")}</label>
            <Select
              styles={selectStyles}
              menuPlacement="auto"
              placeholder=""
              options={languages}
              value={language}
              onChange={(language) => this.setState({ language })}
            />
          </div>
          <div className={styles.formControl}>
            <label className={styles.formLabel}>{_("Title")}</label>
            <input
              className={styles.formInput}
              type="text"
              value={title}
              onChange={this.handleTitleChange}
            />
          </div>
          <div className={styles.formControl}>
            <label className={styles.formLabel}>{_("Subtitle")}</label>
            <input
              className={styles.formInput}
              type="text"
              value={subtitle}
              onChange={this.handleSubtitleChange}
            />
          </div>
          <div className={styles.formControl}>
            <label className={styles.formLabel}>{_("Resource")}</label>
            <Select
              styles={selectStyles}
              menuPlacement="auto"
              placeholder=""
              options={resources}
              value={resource}
              onChange={(resource) => this.handleResourceChange(resource)}
            />
          </div>
          {resource.value === "DASHBOARD" ||
          resource.value === "PUBLIC_PORTAL" ? null : resource.value ===
            "ALBUM" ? (
            <div
              className={classnames(styles.formControl, styles.albumConditions)}
            >
              <label className={classnames(styles.formLabel, styles.bold)}>
                {_("Albums")}
              </label>
              {loading && <div>{_("Loading...")}</div>}
              <ul className="grid-x grid-margin-x">
                {albums.map((media) =>
                  media.countMedias > 0 ? (
                    <li
                      className="cell smal-12 medium-6 large-4"
                      key={`media-container-${media.id}`}
                    >
                      <AlbumCondition
                        album={media}
                        language={language.value}
                        selected={conditions.find(
                          (condition) =>
                            condition.value !== null &&
                            condition.value.id === media.id
                        )}
                        selectAlbum={this.handleAlbumChange}
                      />
                    </li>
                  ) : null
                )}
              </ul>
            </div>
          ) : (
            <div className={classnames(styles.formControl, styles.conditions)}>
              <div className={styles.conditionsTop}>
                <label className={classnames(styles.formLabel, styles.bold)}>
                  {_("Conditions")}
                </label>
                <a onClick={this.resetConditions}>{_("Clear all")}</a>
              </div>
              {conditions.map((condition, index) => {
                return (
                  <div key={condition.id} className={styles.condition}>
                    <div className={styles.conditionInput}>
                      <Select
                        styles={selectStyles}
                        menuPlacement="auto"
                        placeholder=""
                        options={properties[resource.value]}
                        value={condition.property}
                        onChange={(property) =>
                          this.handleConditionPropertyChange(
                            property,
                            condition
                          )
                        }
                      />
                    </div>
                    <div className={styles.conditionInput}>
                      <Select
                        styles={selectStyles}
                        menuPlacement="auto"
                        placeholder=""
                        options={operators}
                        value={condition.operator}
                        onChange={(operator) =>
                          this.handleConditionOperatorChange(
                            operator,
                            condition
                          )
                        }
                      />
                    </div>
                    <div className={styles.conditionInput}>
                      {this.renderConditionValue(condition)}
                    </div>

                    <span
                      className={`${styles.minus} ${styles.conditionActions} ${
                        index === 0 ? styles.disabled : ""
                      }`}
                      onClick={
                        index === 0
                          ? null
                          : () => this.deleteCondition(condition)
                      }
                    />
                    <span
                      className={`${styles.plus} ${styles.conditionActions}`}
                      onClick={this.addCondition}
                    />
                  </div>
                );
              })}
            </div>
          )}
          {resource.value === "DASHBOARD" ||
          resource.value === "PUBLIC_PORTAL" ? null : (
            <div className={styles.formControl}>
              <div className={styles.conditionsTop}>
                <label className={classnames(styles.formLabel, styles.bold)}>
                  {_("Page layout")}
                </label>
                {showAskMagazine && (
                  <div className={styles.displayMagazine}>
                    <label className={styles.displayMagazine__label}>
                      {_("Display as magazine")}
                    </label>
                    <Switch
                      name="asMagazine"
                      keyAttr="asMagazine"
                      isChecked={this.state.asMagazine}
                      onChange={this.handleDisplayAsMagChange}
                    />
                  </div>
                )}
              </div>
              {this.renderLayout()}
            </div>
          )}
        </div>

        <div className={styles.formActions}>
          <NavLink to="/" className={styles.formCancel}>
            {_("Cancel")}
          </NavLink>
          <div className={styles.formDelete}>{_("Delete")}</div>
          <div className={styles.formSave} onClick={this.save}>
            {saving ? <IconLoader /> : _("Save")}
          </div>
        </div>
      </div>
    );
  }

  renderPreview() {
    const { token, auth } = this.props;
    const { previewPage } = this.state;

    const encodedToken = encodeURIComponent(token);
    const encodedPage = encodeURIComponent(JSON.stringify(previewPage));

    return (
      <Preview
        token={encodedToken}
        page={encodedPage}
        community={auth.navCommunity}
        user={auth.user}
      />
    );
  }

  render() {
    return (
      <div className={styles.PageForm}>
        {this.renderHeader()}
        <section className={styles.section}>
          <div className={`${styles.sectionContent}`}>
            {this.renderForm()}
            {this.renderPreview()}
          </div>
        </section>
      </div>
    );
  }
}

// Connected Component
const mapStateToProps = (state) => ({
  auth: state.auth,
  token: state.auth && state.auth.token,
  lng: state.params.lng,
  saving: state.pages.saving,
});

const mapDispatchToProps = (dispatch) => ({
  savePage: (params) => dispatch(savePage(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PageForm));
