import React, { Component } from "react";
import { connect } from "react-redux";
import { TTP_API_URL } from "config";
import { getTeam } from "api";
import _ from "i18n";

class Team extends Component {
  state = {
    users: [],
  };
  componentDidMount() {
    this.fetchTeam();
  }

  fetchTeam() {
    const { community, token } = this.props;

    getTeam({
      token,
      limit: 6,
      communityId: community.id,
    })
      .then((res) => {
        this.setState({
          users:
            res.data && res.data.data && res.data.data.length > 0
              ? res.data.data
              : [],
        });
      })
      .catch((e) => {
        console.error(e);
      });
  }

  render() {
    const { community } = this.props;
    const { users } = this.state;

    if (!users || users.length === 0) {
      return null;
    }
    let help = [];
    if (
      community &&
      community.portalPreferences &&
      community.portalPreferences.team
    ) {
      const { team } = community.portalPreferences;
      for (let i = 0; i < team.length; i++) {
        const current = users.filter(
          (user) => parseInt(user.id, 10) === parseInt(team[i].id, 10)
        );
        if (current && current.length > 0) {
          help.push({ ...current[0], title: team[i].title });
        }
      }
    } else {
      help = [...users];
    }

    return (
      <section id="fiduciary-team">
        <h2>Team</h2>
        <h3>{_("Experts serving their customers")}</h3>
        {help.length > 6 ? (
          <i
            className="icon icon-arrow-down show-more"
            onClick={(e) => {
              document
                .getElementsByClassName("big-team")
                .item(0)
                .classList.remove("big-team");
              e.currentTarget.style.display = "none";
            }}
          />
        ) : null}
        <ul
          className={`grid-x team-list grid-margin-x${
            help.length > 6 ? " big-team" : ""
          }`}
        >
          {help.map((user) => (
            <li className="cell small-12 medium-6 large-4 grid-x">
              <div
                className="official-avatar cell small-6"
                style={{
                  backgroundImage: `url(${
                    user.avatarUrl || user.avatar
                      ? user.avatarUrl
                        ? user.avatarUrl
                        : `${TTP_API_URL}/${user.avatar}`
                      : "/img/default_avatar.jpg"
                  }`,
                }}
              />
              <div className="official-info cell small-6">
                <h4>{user.firstName + " " + user.lastName}</h4>
                <span className="function">
                  {user.title
                    ? user.title
                    : user.function
                    ? user.function
                    : null}
                </span>
                {!user.mainPhone ? null : (
                  <span className="other-infos">{user.mainPhone}</span>
                )}
                {!user.mainEmail ? null : (
                  <span className="other-infos">{user.mainEmail}</span>
                )}
                <ul className="official-social">
                  <li>
                    <i className="icon icon-social-facebook" />
                  </li>
                  <li>
                    <i className="icon icon-social-linkedin" />
                  </li>
                  <li>
                    <i className="icon icon-social-twitter" />
                  </li>
                  <li>
                    <i className="icon icon-social-instagram" />
                  </li>
                </ul>
              </div>
            </li>
          ))}
        </ul>
      </section>
    );
  }
}

// Connected Component

export default Team;
