import React, { PureComponent } from "react";
import _ from "i18n";

export default class Docs extends PureComponent {
  render() {
    return (
      <section id="portal-docs">
        <h2>{_("Documents")}</h2>
        <h3>{_("Here you can download the documents")}</h3>
        <div className="docs-container grid-x">
          <div className="doc-presentation cell small-5">
            <h4>{_("GDPR")}</h4>
            <p>{_("Personal data protection policy")}</p>
          </div>
          <div className="doc-download cell small-7">
            <p>{_("Personal data protection policy")}</p>
            <div className="download-file">
              <span className="doc-infos">20/01/2020 [PDF]</span>
              <a href="/docs/gdpr.pdf" download className="new-btn download">
                {_("Download")}
              </a>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
