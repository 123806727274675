import React, { Component } from "react";
import { connect } from "react-redux";
import { getArticles } from "api";
import { getDateLabel } from "utils";
import Content from "./Content";
import _ from "i18n";

class News extends Component {
  state = {
    articles: [],
  };
  componentDidMount() {
    this.fetchLatestArticles();
  }

  fetchLatestArticles() {
    const { token, community, lng } = this.props;

    getArticles({
      token,
      limit: 6,
      language: lng,
      communityId: community.id,
    }).then((res) => {
      this.setState({
        articles:
          res.data && res.data.data && res.data.data.length > 0
            ? res.data.data
            : [],
      });
    });
  }

  changeArticle(articleId, e) {
    const currentTag =
      e.target.tagName === "LI" ? $(e.target) : $(e.target).parents("li");
    if (currentTag.hasClass("active")) {
      return null;
    }
    $(".display-full-news").show();
    $(".news-content").addClass("news-content-absolute");

    $(".news-titles > li").removeClass("active");
    currentTag.addClass("active");

    $(".article-content").removeClass("active");
    $(`.${currentTag.attr("id")}`).addClass("active");
  }

  displayFullArticle(e) {
    $(".display-full-news").hide();
    $(".news-content").removeClass("news-content-absolute");
  }

  renderTitles() {
    const { articles } = this.state;
    const { lng } = this.props;

    let bloc = [];
    for (let i = 0; i < Math.min(articles.length, 6); i++) {
      const { category } = articles[i];
      const { colorCode } = category;

      const nameAttr = `name${lng.charAt(0).toUpperCase() + lng.slice(1)}`;

      bloc.push(
        <li
          id={`art-${articles[i].id}`}
          key={`art-${articles[i].id}`}
          className={i === 0 ? "active" : ""}
          style={{ borderColor: colorCode }}
          onClick={this.changeArticle.bind(this, articles[i].id)}
        >
          <span className="news-date">
            {getDateLabel(articles[i].publishedAt)}
          </span>
          <h4>{articles[i].title}</h4>
          <span
            className="news-category"
            style={{ backgroundColor: colorCode }}
          >
            {category[nameAttr]}
          </span>
        </li>
      );
    }

    return <ul className="news-titles cell small-6 medium-4">{bloc}</ul>;
  }
  renderContents() {
    const { articles } = this.state;
    const { lng, community } = this.props;

    let bloc = [];
    for (let i = 0; i < Math.min(articles.length, 6); i++) {
      bloc.push(
        <Content
          key={`content-${articles[i].id}`}
          article={articles[i]}
          lng={lng}
          community={community}
          active={i === 0}
        />
      );
    }
    return (
      <ul className="news-content news-content-absolute cell small-6 medium-8">
        {bloc}
      </ul>
    );
  }

  render() {
    const { articles } = this.state;

    if (!articles || articles.length === 0) {
      return null;
    }
    return (
      <section id="fiduciary-news">
        <h2>{_("News")}</h2>
        <h3>{_("All the news you need")}</h3>
        <div className="news-container grid-x">
          {this.renderTitles()}
          {this.renderContents()}
        </div>
        <div className="grid-x display-full-news">
          <div
            className="cell small-6 medium-4 help-display-full"
            onClick={(e) => {
              $(".news-titles > li:last").click();
            }}
          />
          <div className="cell small-6 medium-8 display-news">
            <i
              className="icon icon-arrow-down show-more"
              onClick={this.displayFullArticle.bind(this)}
            />
          </div>
        </div>
      </section>
    );
  }
}

// Connected Component
const mapStateToProps = (state) => ({
  lng: state.params.lng,
});

export default connect(mapStateToProps)(News);
