import React from "react";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";

import PageList from "../components/PageList";
import PageForm from "../components/PageForm";
import Page from "../components/Page";
import NotFound from "../components/NotFound/NotFound";

export const urls = {
  index: "/",
  pages: "/pages/:pageId",
  form: "/form/:pageId?",
  notFound: "*",
};

const PrivateContent = ({ children }) => {
  const user = useSelector((state) => state.auth.user);

  if (!user) {
    return null;
  }

  return <>{children}</>;
};

const PrivateRoute = (props) => {
  return (
    <Route {...props}>
      <PrivateContent>{props.children}</PrivateContent>
    </Route>
  );
};

export const getPageRoutes = () => {
  return (
    <Route
      path={urls.pages}
      onUpdate={() => window.scrollTo(0, 0)}
      component={Page}
    />
  );
};

export const getAppRoutes = () => {
  return (
    <Switch>
      <PrivateRoute exact path="/" onUpdate={() => window.scrollTo(0, 0)}>
        <PageList />
      </PrivateRoute>

      <PrivateRoute path={urls.form} onUpdate={() => window.scrollTo(0, 0)}>
        <PageForm />
      </PrivateRoute>

      <Route path={urls.notFound}>
        <NotFound />
      </Route>
    </Switch>
  );
};
