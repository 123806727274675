import React, { PureComponent } from "react";

import styles from "./NotFound.module.scss";
// import Button from "Common/Button/Button";
import _ from "i18n";

export default class NotFound extends PureComponent {
  handleRetry = () => {
    const { onRetry } = this.props;
    onRetry && onRetry();
  };

  render() {
    const { onRetry, errorCode } = this.props;

    const title =
      errorCode && errorCode !== 404
        ? "Something went wrong ..."
        : "Oops, Resources Could Not Be Found!!";

    const description =
      errorCode && errorCode !== 404
        ? "We encoutered an unexpected problem. Please try again."
        : "You can add items by clicking the 'Add' button in the top bar";

    return (
      <div className={styles.container}>
        {/* <div className={styles.main}> */}
        <div className={`${styles.message}`}>
          <p className={styles.title}>{_(title)}</p>
          {/* <p className={styles.description}>{_(description)}</p> */}
          {onRetry && (
            <button icon="refresh" size="lg">
              {_("Retry")}
            </button>
          )}
        </div>
        {/* </div> */}
      </div>
    );
  }
}
