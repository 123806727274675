export const showSearchLayer = (visible) => ({
  type: "SHOW_SEARCH_LAYER",
  visible,
});

export const setLanguage = (language) => {
  let lng = language.toLowerCase();
  localStorage.setItem("lng", lng);

  return {
    type: "SET_LANGUAGE",
    language: lng,
  };
};

export const setCurrentNavPage = (currentNavPage) => ({
  type: "SET_CURRENT_NAV_PAGE",
  currentNavPage,
});

export const pushSourceToken = (sourceName, sourceToken) => ({
  type: "PUSH_SOURCE_TOKEN",
  sourceName,
  sourceToken,
});

export const clearSourcesTokens = (sourceName, sourceToken) => ({
  type: "CLEAR_SOURCES_TOKENS",
});

export const fetchPublicCommunity = (community) => ({
  type: "FETCH_PUBLIC_COMMUNITY",
  payload: community,
});

export const initPublicCommunity = () => ({
  type: "INIT_PUBLIC_COMMUNITY",
});

export const showPellet = (isVisible) => ({
  type: "SHOW_PELLET",
  isVisible,
});

export const showDirectory = (isVisible) => ({
  type: "SHOW_DIRECTORY",
  isVisible,
});

export const showDashboard = (isVisible) => ({
  type: "SHOW_DASHBOARD",
  isVisible,
});

export const showPowerTeam = (isVisible) => ({
  type: "SHOW_POWER_TEAM",
  isVisible,
});

export const showDMS = (isVisible) => ({
  type: "SHOW_DMS",
  isVisible,
});

export const setSearchSelect = (options) => ({
  type: "SET_SEARCH_SELECT",
  payload: options,
});

export const hideFlashMessage = () => ({
  type: "HIDE_FLASH_MESSAGE",
});

export const displayFlashMessage = (status = "SUCCESS", message = "") => ({
  type: "DISPLAY_FLASH_MESSAGE",
  status,
  message,
});

export const setDrawerIframe = (url) => ({
  type: "SET_DRAWER_IFRAME",
  url,
});

export const closeDrawerIframe = () => ({
  type: "CLOSE_DRAWER_IFRAME",
});
