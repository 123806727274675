import React, { Component } from "react";
import Slider from "react-slick";

import { getMediasInTheNews } from "api";
import { TTP_API_URL } from "config";

class Media extends Component {
  state = {
    medias: [],
  };
  componentDidMount() {
    this.fetchMediaInTheNews();
  }

  fetchMediaInTheNews() {
    const { community, token } = this.props;
    getMediasInTheNews({
      token,
      communityId: community.id,
    })
      .then((res) => {
        this.setState({
          medias:
            res.data && res.data.data && res.data.data.length > 0
              ? res.data.data
              : [],
        });
      })
      .catch((e) => {
        console.error(e);
      });
  }

  render() {
    const { medias } = this.state;

    if (!medias) {
      return (
        <div className="media-caroussel cell small-6 medium-8">
          <div
            style={{
              backgroundImage: "url(/img/default_com.jpg)",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              width: "100%",
              height: "15rem",
            }}
          />
        </div>
      );
    }
    let hide = medias.length === 1 ? "hide" : "";

    var settings = {
      dots: true,
      dotsClass: "slick-dots " + hide,
      infinite: true,
      arrows: false,
      speed: 1000,
      autoplay: true,
      key: 11,
    };

    let imgDivs = (medias || []).map((media) => (
      <div
        key={`med-${media.id}`}
        style={{
          backgroundImage: `url(${
            media.fullMediaUrl
              ? media.fullMediaUrl
              : `${TTP_API_URL}/${media.webPath}`
          })`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          width: "100%",
          minHeight: "18rem",
        }}
      />
    ));
    return (
      <div className="media-caroussel cell small-6 medium-8">
        {imgDivs.length > 0 ? <Slider {...settings}>{imgDivs}</Slider> : null}
      </div>
    );
  }
}

export default Media;
