import React, { Component } from "react";
import { connect } from "react-redux";
import { Header } from "tamtam-components";
import { push } from "connected-react-router";

import { setNavCommunity } from "actions";
import { changeNavCommunity } from "thunks";
import {
  TTP_LOGO_URL,
  TTP_HUB_URL,
  TTP_EMAILING_URL,
  TTP_HOME_URL,
  APP_ENV,
} from "config";
import { setCookie, setLocalStorageCookie, redirectToHome } from "utils";
import _ from "i18n";
import { setLanguage } from "actions";

import moment from "moment";
import "moment/locale/fr";
import "moment/locale/nl";
import "moment/locale/en-gb";

export class HeaderMenu extends Component {
  toggleSetting(e) {
    const menuLogo = document.getElementsByClassName("menu__logo").item(0)
      .classList;
    //const optionBtn = document.getElementsByClassName("toggleSetting").item(0).classList;
    const options = document.getElementsByClassName("setting-options").item(0)
      .classList;

    if (menuLogo.contains("display-settings")) {
      menuLogo.remove("display-settings");
      options.remove("show");
    } else {
      menuLogo.add("display-settings");
      options.add("show");
    }
  }

  changeLng(lang) {
    const { dispatch } = this.props;
    dispatch(setLanguage(lang));

    if (this.props.currentNavPage === "ARTICLE") {
      dispatch(push("/"));
    }

    window.location.reload();
  }

  logout(e) {
    //const { dispatch } = this.props;
    e.preventDefault();
    //dispatch(setAuth(null));
    setLocalStorageCookie(null);

    let dtExpire = new Date();
    dtExpire.setTime(dtExpire.getTime() - 3600000 * 1000);

    setCookie(`ttp_auth_${APP_ENV}`, "", dtExpire, "/", "tamtam.pro");
    setCookie(`ttp_session_${APP_ENV}`, "", dtExpire, "/", "tamtam.pro");
    setCookie("XSRF-TOKEN", "", dtExpire, "/", "tamtam.pro");
    setCookie("laravel_session", "", dtExpire, "/", "tamtam.pro");

    setTimeout(() => {
      redirectToHome();
    }, 200);
  }

  handleSelectCommunity = (community) => {
    const { setNavCommunity, changeNavCommunity } = this.props;
    setNavCommunity(community);
    changeNavCommunity(community.id);
    setTimeout(() => {
      window.location.reload(true);
    }, 0);
  };

  render() {
    const { lng, loggedAs, loggedAsPost, currentNavPage, auth } = this.props;

    moment.locale(lng === "en" ? "en-gb" : lng);

    const App = {
      appName: "",
      appLogoUrl: TTP_LOGO_URL,
      appUrl: TTP_HUB_URL,
      homeUrl: TTP_HOME_URL,
    };

    const rightIcons = {
      home: {
        activated: false,
      },
      profile: {
        activated: true,
        url: `${TTP_HOME_URL}/landing`,
      },
      ebox: {
        activated: true,
        url: `${TTP_EMAILING_URL}/e_box`,
      },
      search: {
        activated: false,
      },
      notifs: {
        activated: true,
      },
      backoffice: {
        activated: false,
      },
      faq: {
        activated: false,
      },
      apps: {
        activated: true,
      },
    };

    if (!auth.user) {
      return (
        <Header
          auth={{
            navCommunity: null,
            user: null,
            token: null,
          }}
          lng={lng}
          onLanguageChange={(langue) => this.changeLng(langue)}
          app={App}
        />
      );
    } else {
      return (
        <Header
          rightIcons={rightIcons}
          app={App}
          auth={{
            navCommunity: auth.navCommunity,
            user: auth.user,
            token: auth.token,
          }}
          settings={[]}
          lng={lng}
          env={APP_ENV}
          onLanguageChange={(langue) => this.changeLng(langue)}
          onLogoutClick={(e) => this.logout(e)}
          notifications={[]}
          onSelectCommunity={(community) =>
            this.handleSelectCommunity(community)
          }
        />
      );
    }
  }
}

// Connected Component
const mapStateToProps = (state) => ({
  auth: state.auth,
  loggedAs: state.auth.loggedAs,
  loggedAsPost: state.auth.loggedAsPost,
  currentNavPage: state.params.currentNavPage,
  lng: state.params.lng,
});

const mapDispatchToProps = (dispatch) => ({
  setNavCommunity: (community) => dispatch(setNavCommunity(community)),
  changeNavCommunity: (communityId) =>
    dispatch(changeNavCommunity(communityId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderMenu);
