import React, { Component } from "react";
import { connect } from "react-redux";

import { login } from "../../actions/thunks";

import HeaderMenu from "Menu/HeaderMenu";

import Footer from "./Footer";

class Layout extends Component {
  componentDidMount() {
    this.props.login();
  }

  render() {
    return (
      <div className="off-canvas-wrapper">
        <HeaderMenu />
        {this.props.children}
        <Footer />
      </div>
    );
  }
}

// Connected Component
const mapStateToProps = (state) => ({
  navCommunity: state.auth.navCommunity,
  user: state.auth.user,
  loggedAs: state.auth.loggedAs,
  router: state.router,
});

const mapDispatchToProps = (dispatch) => ({
  login: () => dispatch(login()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
