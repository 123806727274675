import React, { PureComponent } from "react";
import OrganizationInfos from "./OrganizationInfos";
import { ReactBingmaps } from "react-bingmaps";
import _ from "i18n";

export default class Contact extends PureComponent {
  renderSchedulePart(schedule, moment) {
    if (!schedule[moment]) {
      return null;
    }
    if (!schedule[moment].FROM) {
      return <td className="houres disabled">{_("Office closed")}</td>;
    }
    return (
      <td className="houres">
        {schedule[moment].FROM === "NO_WORK"
          ? _("Office closed")
          : `${schedule[moment].FROM} > ${schedule[moment].TO}`}
      </td>
    );
  }

  renderSchedule() {
    const { bloc } = this.props;
    const { schedule } = bloc;

    let scheduleBlocs = [];

    for (const [key, value] of Object.entries(schedule)) {
      scheduleBlocs.push(
        <tr key={key}>
          <td className="day">{_(key)}</td>
          {this.renderSchedulePart(value, "MORNING")}
          {this.renderSchedulePart(value, "AFTERNOON")}
        </tr>
      );
    }
    return <table>{scheduleBlocs}</table>;
  }

  render() {
    const { community, bloc } = this.props;
    const { latitude, longitude } = bloc;

    return (
      <section id="portal-contact" style={{ paddingBottom: "8rem" }}>
        <h2>Contact</h2>
        <h3>{_("Where and when can you reach us?")}</h3>
        <div className="contact-container grid-x grid-margin-x">
          <div className="contact-map cell small-12 medium-6">
            {!latitude || !longitude ? null : (
              <ReactBingmaps
                bingmapKey="AlfqRT9EH4KtKEzZQp4yhxAIWxiujabG1jpSuUJaQ_GWxN9ONI_MQvd_e2kJpK5H"
                center={[parseFloat(latitude), parseFloat(longitude)]}
                navigationBarMode={"compact"}
                pushPins={[
                  {
                    location: [parseFloat(latitude), parseFloat(longitude)],
                    option: {
                      color: "#18A0FB",
                      //icon: "/img/pin.png"
                      //icon: "https://unpkg.com/leaflet@1.3.3/dist/images/marker-icon-2x.png"
                    },
                  },
                ]}
              />
            )}
          </div>

          <OrganizationInfos size="small-12 medium-3" community={community} />

          <div className="fiduciary-schedules cell small-12 medium-3">
            <h4>{_("Opening time")}</h4>
            {!bloc.schedule ? null : this.renderSchedule()}
          </div>
        </div>
      </section>
    );
  }
}
