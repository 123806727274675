export const closeMediaViewer = (viewer) => ({
  type: "CLOSE_MEDIA_VIEWER",
  viewer,
});

export const openMediaViewer = (viewer) => ({
  type: "OPEN_MEDIA_VIEWER",
  viewer,
});

export const setCurrentMedia = (media) => ({
  type: "SET_CURRENT_MEDIA_VIEWER",
  media: media,
});
