import axios from "axios";
import { TTP_API_URL } from "config";
import { throwCatchedError } from "utils";

/*const prepareFilters = (filters) => {
  let filterArray = [];

  if (filters.status) {
    filterArray.push({
      property: "article.status",
      value: filters.status,
      operator: "in",
    });
  }

  if (filters.category) {
    filterArray.push({
      property: "category.id",
      value: filters.category,
      operator: "eq",
    });
  }

  if (filters.language) {
    filterArray.push({
      property: "article.language",
      value: filters.language,
      operator: "in",
    });
  }

  if (filters.communitiesIds) {
    filterArray.push({
      property: "organization.id",
      value: filters.communitiesIds,
      operator: "in",
    });
  }

  if (filters.author) {
    filterArray.push({
      property: "author.id",
      value: filters.author,
      operator: "eq",
    });
  }

  return filterArray;
};

export const getTags = (token, filters) => {
  const requestUrl = `${TTP_API_URL}/blog/tag`;

  let params = {
    access_token: token,
    filter: JSON.stringify(prepareFilters(filters)),
  };

  return axios.get(requestUrl, params).catch(function (thrown) {
    throwCatchedError(thrown);
  });
};

export const getCategories = (token, filters) => {
  const requestUrl = `${TTP_API_URL}/blog/category`;

  let params = {
    access_token: token,
    filter: JSON.stringify(prepareFilters(filters)),
    nolimit: 1,
  };

  return axios.get(requestUrl, params).catch(function (thrown) {
    throwCatchedError(thrown);
  });
};

export const getTypes = (
  token,
  language = null,
  communityId = null,
  cancellationTokenSource = null
) => {
  let requestCancellationToken = getRequestCancellationToken(
    getThemesCTS,
    cancellationTokenSource
  );

  const requestUrl = `${TTP_API_URL}/blog/type`;

  let filters = [];
  if (language) {
    filters.push({
      property: "language",
      value: language,
      operator: "eq",
    });
  }
  if (communityId) {
    filters.push({
      property: "organization",
      value: communityId,
      operator: "inWithNull",
    });
  }

  let params = {
    access_token: token,
    filter: JSON.stringify(filters),
    fields: "*",
  };

  let requestConfig = getRequestConfig(params, requestCancellationToken);

  return axios.get(requestUrl, requestConfig).catch(function (thrown) {
    throwCatchedError(thrown);
  });
};*/

export const getCategories = (token, language = "fr", communityId = null) => {
  const requestUrl = `${TTP_API_URL}/blog/category`;

  let filter = [];

  filter.push({
    property: "language",
    value: language,
    operator: Array.isArray(language) ? "in" : "like",
  });

  if (communityId) {
    filter.push({
      property: "organization.id",
      value: communityId,
      operator: "eq",
    });
  }

  const params = {
    access_token: token,
    filter: JSON.stringify(filter),
    nolimit: 1,
  };

  return axios.get(requestUrl, { params }).catch(function (thrown) {
    throwCatchedError(thrown);
  });
};

/*export const getCategory = (
  categoryId,
  token,
  cancellationTokenSource = null
) => {
  // getCategorySourceToken = updateSourceToken(getCategorySourceToken, sourceToken);
  let requestCancellationToken = getRequestCancellationToken(
    getCategoryCTS,
    cancellationTokenSource
  );

  const filter = [
    {
      property: "id",
      value: categoryId,
      operator: "eq",
    },
  ];
  const fields = ["*"];
  const requestUrl = `${TTP_API_URL}/blog/category`;

  let params = {
    access_token: token,
    filter: JSON.stringify(filter),
    fields: fields.join(","),
  };

  let requestConfig = getRequestConfig(params, requestCancellationToken);

  return axios.get(requestUrl, requestConfig).catch(function (thrown) {
    throwCatchedError(thrown);
  });
};*/

export const getTags = (token, language = "fr", communityId = null) => {
  const requestUrl = `${TTP_API_URL}/blog/tag`;
  let filter = [
    { property: "article.status", value: "PUBLISHED", operator: "like" },
  ];

  if (communityId) {
    filter.push({
      property: "organization.id",
      value: communityId,
      operator: "eq",
    });
  }

  filter.push({
    property: "article.language",
    value: language,
    operator: Array.isArray(language) ? "in" : "like",
  });

  const sort = [
    {
      property: "counter",
      dir: "desc",
    },
  ];

  let params = {
    access_token: token,
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort),
    limit: 100,
  };

  return axios.get(requestUrl, { params }).catch(function (thrown) {
    throwCatchedError(thrown);
  });
};

export const getThemes = (token, language = "fr", communityId = null) => {
  const requestUrl = `${TTP_API_URL}/blog/theme`;

  let filters = [
    {
      property: "isDefault",
      value: 0,
      operator: "eq",
    },
  ];
  if (language) {
    filters.push({
      property: "language",
      value: language,
      operator: "like",
    });
  }
  if (communityId) {
    filters.push({
      property: "organization",
      value: communityId,
      operator: "eq",
    });
  }

  let params = {
    access_token: token,
    filter: JSON.stringify(filters),
    fields: "*,mediaThemes,pages",
  };

  return axios.get(requestUrl, { params }).catch(function (thrown) {
    throwCatchedError(thrown);
  });
};
