import React, { PureComponent } from "react";
import { TTP_LOGO_URL } from "config";
import _ from "i18n";

export default class Footer extends PureComponent {
  render() {
    //return null;
    return (
      <footer>
        <div id="footer-content">
          <div id="top-footer" className="grid-x">
            <div className="footer-log cell small-6 medium-3">
              <img src={TTP_LOGO_URL} alt="logo" style={{ width: "12rem" }} />
            </div>
            <ul className="footer-social cell small-6 medium-3">
              <li>
                <i className="icon icon-social-facebook" />
              </li>
              <li>
                <i className="icon icon-social-linkedin" />
              </li>
              <li>
                <i className="icon icon-social-twitter" />
              </li>
              <li>
                <i className="icon icon-social-instagram" />
              </li>
            </ul>
            <div className="footer-desc cell small-12 medium-6">
              <p>
                <span>Tamtam.pro</span>
                {_(
                  "est une suite d'outils qui permet à une communauté de communiquer, partager, organiser ou collaborer efficacement. La plateforme est équipée de diverses applications assemblées selon les besoins par le client. Grace à nos outils mutualisés, nos clients disposent des dernières technologies sans devoir en assurer le développement au profit leur core business."
                )}
              </p>
            </div>
          </div>
          <div id="bottom-footer">
            {_("© Copyright - 2019 Tamtam International")}
          </div>
        </div>
      </footer>
    );
  }
}
