import axios from "axios";
import { TTP_API_URL } from "config";

export const fetchPages = ({ token, communityId, pageId }) => {
  const requestUrl = `${TTP_API_URL}/media/hub`;

  const filters = [];

  if (pageId) {
    filters.push({
      property: "id",
      value: pageId,
      operator: "eq",
    });
  } else if (communityId) {
    filters.push({
      property: "organization",
      value: communityId,
      operator: "eq",
    });
    filters.push({
      property: "resource",
      value: "PUBLIC_PORTAL",
      operator: "neq",
    });
  }

  const fields = ["*", "organization", "user"];

  const params = {
    access_token: token,
    organization_id: communityId,
    fields: fields.join(","),
    filter: JSON.stringify(filters),
  };

  return axios.get(requestUrl, { params });
};

export const savePage = ({
  token,
  communityId,
  id,
  title,
  language,
  subtitle,
  resource,
  filters = [],
  template,
  asMagazine,
}) => {
  const requestUrl = `${TTP_API_URL}/media/hub`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("organization_id", communityId);
  formData.append("organization", communityId);

  if (id) {
    formData.append("id", id);
  }

  formData.append("title", title);
  formData.append("subtitle", subtitle);
  formData.append("language", language);
  formData.append("resource", resource);
  formData.append("template", template);
  formData.append("asMagazine", asMagazine ? 1 : 0);
  formData.append("filters", JSON.stringify(filters));

  return axios.post(requestUrl, formData);
};

export const deletePage = ({ token, pageId }) => {
  const requestUrl = `${TTP_API_URL}/media/hub/${pageId}`;

  var formData = new FormData();

  formData.append("access_token", token);
  formData.append("_method", "delete");

  return axios.post(requestUrl, formData);
};
