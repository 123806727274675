import React, { Component } from "react";

import styles from "./Switch.module.scss";

export default class Switch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isChecked: props.isChecked,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.isChecked !== nextProps.isChecked) {
      this.setState({ isChecked: !this.state.isChecked });
    }
  }

  render() {
    let { name } = this.props;

    return (
      <label>
        <input
          ref="switch"
          name={name}
          checked={this.state.isChecked}
          onChange={this._handleChange.bind(this)}
          className={styles["ttp-switch"]}
          type="checkbox"
        />
        <div>
          <span>
            <g className="icon icon-toolbar grid-view" />
          </span>
          <span>
            <g className="icon icon-toolbar ticket-view" />
          </span>
          <div />
        </div>
      </label>
    );
  }

  _handleChange() {
    this.setState({ isChecked: !this.state.isChecked }, function () {
      this.props.onChange(this.state.isChecked);
    });
  }
}
