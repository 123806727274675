import React, { Component } from "react";

import { getCategories, getTags, getThemes } from "../../api";

import styles from "./Filter.module.scss";
import classnames from "classnames";

import _ from "i18n";

export default class Filter extends Component {
  state = {
    categories: [],
    themes: [],
    tags: [],
    categoriesFetching: false,
    themesFetching: false,
    tagsFetching: false,
  };

  componentDidMount() {
    const { token, communityId, language } = this.props;

    this.setState({ categoriesFetching: true });
    getCategories(token, language, communityId)
      .then((resp) => {
        this.setState({
          categories: [...resp.data.data],
          categoriesFetching: false,
        });
      })
      .catch((e) => {
        console.error(e);
      });

    this.setState({ themesFetching: true });
    getTags(token, language, communityId)
      .then((resp) => {
        this.setState({ tags: [...resp.data.data], themesFetching: false });
      })
      .catch((e) => {
        console.error(e);
      });

    this.setState({ tagsFetching: true });
    getThemes(token, language, communityId)
      .then((resp) => {
        this.setState({ themes: [...resp.data.data], tagsFetching: false });
      })
      .catch((e) => {
        console.error(e);
      });
  }

  seeMore = (targetClass, e) => {
    const others = document
      .getElementsByClassName(targetClass)
      .item(0)
      .getElementsByClassName(styles.others);

    if (others) {
      for (let i = 0; i < others.length; i++) {
        others.item(i).classList.remove(styles.others);
      }
      e.currentTarget.parentNode.style.display = "none";
    }
  };

  renderCategories() {
    const { categories, categoriesFetching } = this.state;
    const { language, setFilter } = this.props;

    if (categoriesFetching) {
      return (
        <div className={styles.part}>
          <h5>{_("Categories")}</h5>
          <ul
            className={classnames(styles.fetching, styles.categoriesFetching)}
          >
            <li />
            <li />
            <li />
            <li />
            <li />
          </ul>
        </div>
      );
    }
    if (categories.length === 0) {
      return null;
    }
    const nameAttr = `name${
      language.charAt(0).toUpperCase() + language.slice(1)
    }`;

    let i = 0;

    return (
      <div className={styles.part}>
        <h5>{_("Categories")}</h5>
        <ul className={styles.categories}>
          {categories.map((category) => (
            <li
              className={i++ > 5 ? styles.others : ""}
              key={`cat-${category.id}`}
              onClick={setFilter.bind(
                this,
                {
                  property: "category.id",
                  value: category.id,
                  operator: "eq",
                },
                styles
              )}
            >
              <span className={styles.categoryName}>{category[nameAttr]}</span>
              <span
                className={styles.count}
                style={{ backgroundColor: `${category.colorCode}` }}
              >
                {/*category.count*/}
              </span>
            </li>
          ))}
        </ul>
        {i > 5 ? (
          <div className={styles.seeMore}>
            <span onClick={this.seeMore.bind(this, styles.categories)}>
              {_("See more")} <i className="icon-arrow-down" />
            </span>
          </div>
        ) : null}
      </div>
    );
  }

  renderTags() {
    const { tags, tagsFetching } = this.state;
    const { language, setFilter } = this.props;

    if (tagsFetching) {
      return (
        <div className={styles.part}>
          <h5>{_("Tags")}</h5>
          <ul className={classnames(styles.fetching, styles.tagsFetching)}>
            <li />
            <li />
            <li />
            <li />
            <li />
          </ul>
        </div>
      );
    }
    if (tags.length === 0) {
      return null;
    }
    const nameAttr = `name${
      language.charAt(0).toUpperCase() + language.slice(1)
    }`;

    let i = 0;

    return (
      <div className={styles.part}>
        <h5>{_("Tags")}</h5>
        <ul className={styles.tags}>
          {tags.map((tag) => (
            <li
              className={i++ > 12 ? styles.others : ""}
              key={`tag-${tag.id}`}
              onClick={setFilter.bind(
                this,
                {
                  property: "tag.id",
                  value: tag.id,
                  operator: "eq",
                },
                styles
              )}
            >
              {tag[nameAttr]}
            </li>
          ))}
        </ul>
        {i > 12 ? (
          <div className={styles.seeMore}>
            <span onClick={this.seeMore.bind(this, styles.tags)}>
              {_("See more")} <i className="icon-arrow-down" />
            </span>
          </div>
        ) : null}
      </div>
    );
  }

  renderThemes() {
    const { themes, themesFetching } = this.state;
    const { setFilter } = this.props;

    if (themesFetching) {
      return (
        <div className={styles.part}>
          <h5>{_("themes")}</h5>
          <ul className={classnames(styles.fetching, styles.themesFetching)}>
            <li />
            <li />
            <li />
            <li />
            <li />
          </ul>
        </div>
      );
    }
    if (themes.length === 0) {
      return null;
    }

    let i = 0;

    return (
      <div className={styles.part}>
        <h5>{_("themes")}</h5>
        <ul className={styles.themes}>
          {themes.map((theme) => (
            <li
              className={i++ > 12 ? styles.others : ""}
              key={`theme-${theme.id}`}
              onClick={setFilter.bind(
                this,
                {
                  property: "theme.id",
                  value: theme.id,
                  operator: "eq",
                },
                styles
              )}
            >
              {theme.title}
            </li>
          ))}
        </ul>
        {i > 12 ? (
          <div className={styles.seeMore}>
            <span onClick={this.seeMore.bind(this, styles.themes)}>
              {_("See more")} <i className="icon-arrow-down" />
            </span>
          </div>
        ) : null}
      </div>
    );
  }

  render() {
    return (
      <div className={styles.filter}>
        {this.renderCategories()}
        {this.renderThemes()}
        {this.renderTags()}
      </div>
    );
  }
}
