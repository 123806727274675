import React, { Component } from "react";
import { connect } from "react-redux";

import AlbumComponent from "./AlbumComponent";

export class Album extends Component {
  render() {
    const { community, album, language, openAlbumReveal } = this.props;

    return (
      <AlbumComponent
        album={album}
        community={community}
        language={language}
        openAlbumReveal={openAlbumReveal}
      />
    );
  }
}

// Connected Component
const mapStateToProps = (state) => ({
  language: state.params.lng,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Album);
