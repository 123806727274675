import axios from "axios";
import { TTP_API_URL } from "config";

export const getNotifs = ({ token, communityId, limit = 5 }) => {
  const requestUrl = `${TTP_API_URL}/organization/notification`;

  //const filter = [{"property":"appName","value":"WTB,HOME","operator":"in"}]"
  const sort = [{ property: "createdAt", dir: "DESC" }];

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      //filter: JSON.stringify(filter),
      limit,
      organization_id: communityId,
      sort: JSON.stringify(sort),
    },
  });
};
