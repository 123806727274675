export const setAuth = (auth) => ({
  type: "SET_AUTH",
  auth,
});

export const setExpiresIn = (expiresIn) => ({
  type: "SET_EXPIRES_IN",
  expiresIn,
});

export const setTokenCreatedAt = (createdAt) => ({
  type: "SET_TOKEN_CREATED_AT",
  createdAt,
});

export const setAuthUser = (user) => ({
  type: "SET_AUTH_USER",
  user,
});

export const fetchAuthUser = (user) => ({
  type: "FETCH_AUTH_USER",
  payload: user,
});

export const setAuthToken = (token) => ({
  type: "SET_AUTH_TOKEN",
  token,
});

export const setAuthLoggedAs = (loggedAs) => ({
  type: "SET_AUTH_LOGGED_AS",
  loggedAs,
});

export const setAuthLoggedAsPost = (loggedAsPost) => ({
  type: "SET_AUTH_LOGGED_AS_POST",
  loggedAsPost,
});

export const setNavCommunity = (community) => ({
  type: "SET_NAV_COMMUNITY",
  community,
});

export const setActiveApps = (resp) => ({
  type: "SET_ACTIVE_APPS",
  payload: resp,
});

export const setCurrentPortal = (currentPortal) => ({
  type: "SET_CURRENT_PORTAL",
  currentPortal,
});

export const saveCommunityLayoutSettings = (resp) => ({
  type: "SAVE_COMMUNITY_LAYOUT_SETTINGS",
  payload: resp,
});

export const saveCommunityDrawersSettings = (resp) => ({
  type: "SAVE_COMMUNITY_DRAWERS_SETTINGS",
  payload: resp,
});
