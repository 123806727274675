import React, { PureComponent } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";

import { closeMediaViewer } from "actions";
import CloseViewer from "../CloseViewer";
import Content from "./Content";

import styles from "../../Page/Page.module.scss";

class MediaViewer extends PureComponent {
  handleAfterOpenModal() {}

  handleCloseModal() {
    this.props.closeMediaViewer();
  }

  render() {
    const { viewer, language, navCommunity, asMagazine } = this.props;

    if (!viewer.isOpen) {
      return false;
    }
    return (
      <div className={styles.mediaViewer}>
        <CloseViewer closeViewer={this.props.closeOpenViewer} />
        <Content
          viewer={viewer}
          language={language}
          navCommunity={navCommunity}
          asMagazine={asMagazine}
        />
      </div>
    );
  }
}

// Connected Component
const mapStateToProps = (state, ownProps) => ({
  viewer: state.viewer,
  language: state.params.lng,
  navCommunity: state.auth.navCommunity,
});

const mapDispatchToProps = (dispatch) => ({
  // closeMediaViewer: () => dispatch(closeMediaViewer()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MediaViewer);
