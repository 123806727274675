import React, { PureComponent } from "react";

import { TTP_API_URL } from "config";
import styles from "./Album.module.scss";
//import { getDateLabel } from "utils";
import _ from "i18n";

export default class AlbumComponent extends PureComponent {
  state = {
    play: false,
  };

  render() {
    const { language, album, openAlbumReveal } = this.props;
    const { medias, countMedias } = album;

    if (!medias || medias.length === 0) {
      return null;
    }

    const titleAttr = `title${
      language.charAt(0).toUpperCase() + language.slice(1)
    }`;

    const { fullMediaUrl, webPath, docType, preview } = medias[0];

    let path = null;
    if (
      (docType === "VIDEO" || docType === "PPT" || docType === "PDF") &&
      preview &&
      (preview.fullMediaUrl || preview.webPath)
    ) {
      path = preview.fullMediaUrl
        ? preview.fullMediaUrl
        : TTP_API_URL + "/" + preview.webPath;
    } else if (docType === "IMAGE") {
      path = fullMediaUrl ? fullMediaUrl : TTP_API_URL + "/" + webPath;
    }

    return (
      <div className={styles.album} onClick={openAlbumReveal.bind(this, album)}>
        {
          /*docType === "PPT" || docType === "PDF" ? (
          <div className={styles.docs}>
            <img src={`/img/${docType.toLowerCase()}.png`} alt="" />
            <div className={styles.docInfo}>
              <h3>{album[titleAttr]}</h3>
              <span className={styles.date}>{getDateLabel(createdAt)}</span>
            </div>
          </div>
        ) : */
          <div
            className={styles.mediaContent}
            style={{ backgroundImage: `url(${path})` }}
            onMouseEnter={(e) => {
              this.setState({ play: true });
            }}
          >
            {docType === "VIDEO" ? (
              <div className={styles.play}>
                <img src="/img/video.png" alt="" />
              </div>
            ) : null}
            {docType === "PPT" || docType === "PDF" ? (
              <div className={styles.pdf}>
                <img src={`/img/${docType.toLowerCase()}.png`} alt="" />
              </div>
            ) : null}
            <div className={styles.overlay}>
              <h3>{album[titleAttr]}</h3>
              <span className={styles.countMedias}>
                {countMedias}{" "}
                {countMedias === 1 ? _("Document") : _("Documents")}
              </span>
            </div>
          </div>
        }
      </div>
    );
  }
}

// Conn
