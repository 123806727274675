import React, { Component } from "react";
import { connect } from "react-redux";
import { getDateLabel, getUserNameForAvatar } from "utils";
import { TTP_API_URL } from "config";

import Slider from "react-slick";

class Content extends Component {
  state = {
    playVideo: false,
  };

  componentDidMount() {
    const { article } = this.props;
    let contentStateJSON = article.contentState;

    if (!contentStateJSON) {
      setTimeout(() => $(".article-file__content").prepend(article.content), 0);
      return null;
    }

    // const contentState = convertFromRaw(contentStateJSON);
    // const editorState = EditorState.createWithContent(contentState);
    // this.setState({ editorState });

    // setTimeout(() => {
    //   let i = 0;
    //   $(".article-content").each(function () {
    //     if (i++ > 0) {
    //       $(this).removeClass("active");
    //     }
    //   });
    // }, 0);
  }

  renderMedia() {
    let { playVideo } = this.state;

    const { main_media, albums } = this.props.article;

    if (
      albums &&
      albums.length > 0 &&
      albums[0].medias &&
      albums[0].medias.length > 0
    ) {
      return this.renderAlbum();
    }

    let style = {};
    let mediaClass = "news-media";
    let pictoDiv = null;
    let articleCoverUrl = "/img/article-cover-small.jpg";
    let isVideo = false;
    let isPDF = false;

    if (main_media) {
      if (
        main_media.preview &&
        (main_media.preview.fullMediaUrl || main_media.preview.webPath)
      ) {
        articleCoverUrl = main_media.preview.fullMediaUrl
          ? main_media.preview.fullMediaUrl
          : TTP_API_URL + "/" + main_media.preview.webPath;
        style.width = "100%";
        mediaClass += " " + main_media.docType.toLowerCase();

        isVideo = main_media.docType === "VIDEO";
        isPDF = ["PDF", "PPT"].indexOf(main_media.docType) !== -1;
        pictoDiv = (
          <span>
            <img src={`/img/${main_media.docType.toLowerCase()}.png`} alt="" />
          </span>
        );
      } else {
        if (main_media.fullCroppedWebPath) {
          articleCoverUrl = main_media.fullCroppedWebPath;
        } else {
          articleCoverUrl = main_media.fullMediaUrl
            ? main_media.fullMediaUrl
            : `${TTP_API_URL}/${main_media.webPath}`;
        }
      }
    }

    let bgImg = new Image();
    bgImg.src = articleCoverUrl + "?" + new Date().getTime();

    bgImg.onload = function (e) {
      if (bgImg.width >= 580) {
        $(".article-file__img img").css("width", "100%");
      }
    };

    if (playVideo) {
      return (
        <div className={mediaClass}>
          <video
            src={
              main_media.preview.fullMediaUrl
                ? main_media.preview.fullMediaUrl
                : `${TTP_API_URL}/${main_media.webPath}`
            }
            controls
            autoPlay
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
            onEnded={() => {
              return this.setState({ playVideo: false });
            }}
          />
        </div>
      );
    }

    if (isVideo) {
      return (
        <div className={mediaClass}>
          <img src={articleCoverUrl} style={style} alt="" />
          <span
            onClick={() => {
              return this.setState({ playVideo: true });
            }}
          >
            <img src={`/img/video.png`} alt="" />
          </span>
        </div>
      );
    }
    if (isPDF) {
      return (
        <div className={mediaClass}>
          <img
            alt=""
            src={articleCoverUrl}
            style={style}
            //onClick={this.displayPDF.bind(this, main_media)}
          />
          {pictoDiv}
        </div>
      );
    }
    return (
      <div className={mediaClass}>
        <img src={articleCoverUrl} alt="" />
        {pictoDiv}
      </div>
    );
  }

  renderAlbum() {
    const { albums } = this.props.article;
    const medias = albums[0].medias;

    let hide = medias.length === 1 ? "hide" : "";

    var settings = {
      dots: true,
      dotsClass: "slick-dots " + hide,
      infinite: true,
      arrows: false,
      speed: 500,
      autoplay: true,
    };

    return (
      <div className="news-media is-album">
        <Slider {...settings}>
          {medias.map((media) => {
            const path =
              media.preview &&
              (media.preview.fullMediaUrl || media.preview.webPath)
                ? media.preview.fullMediaUrl
                  ? media.preview.fullMediaUrl
                  : `${TTP_API_URL}/${media.preview.webPat}`
                : media.fullMediaUrl
                ? media.fullMediaUrl
                : `${TTP_API_URL}/${media.webPath}`;
            return (
              <div
                key={`slide-${media.id}`}
                style={{
                  background: `url(${path}) no-repeat center center`,
                  backgroundSize: "contain",
                  width: "100%",
                  height: "15rem",
                }}
              />
            );
          })}
        </Slider>
      </div>
    );
  }

  renderAuthors(authors) {
    if (!authors || authors.length === 0) {
      return null;
    }
    let authorsAvatar = [];
    let authorsName = [];
    for (let i = 0; i < authors.length; i++) {
      if (!authors[i] || !authors[i].enableAvatar) {
        continue;
      }
      const { firstName, lastName, avatar, avatarUrl } = authors[i];

      if (avatar) {
        authorsAvatar.push(
          avatar ? (
            <div
              key={`av-${authors[i].id}`}
              className="news-author-avatar"
              style={{
                backgroundImage: `url(${
                  avatarUrl ? avatarUrl : TTP_API_URL + "/" + avatar
                })`,
              }}
            />
          ) : (
            <div className="news-author-avatar empty-avatar">
              <span>{getUserNameForAvatar(firstName, lastName)}</span>
            </div>
          )
        );
      }
      authorsName.push(
        authors.length > 1 ? firstName : firstName + " " + lastName
      );
    }
    return (
      <div className={`news-author${authors.length > 1 ? " more" : ""}`}>
        {authorsAvatar}
        <div className="author-infos">
          <h6>{authorsName.join(", ")}</h6>
          <span className="news-date">
            {getDateLabel(this.props.article.publishedAt)}
          </span>
        </div>
      </div>
    );
  }

  render() {
    const { article, community, lng, active } = this.props;
    const {
      organization,
      title,
      author,
      category,
      content,
      introduction,
    } = article;

    const nameAttr = `name${lng.charAt(0).toUpperCase() + lng.slice(1)}`;

    if (!article) {
      return null;
    }
    return (
      <li className={`article-content art-${article.id} ${active && "active"}`}>
        {this.renderMedia()}
        {community && organization && community.id !== organization.id ? (
          <h5 style={{ borderColor: category.colorCode }}>
            {organization.abbreviation
              ? organization.abbreviation
              : organization.name}
          </h5>
        ) : null}
        <h4>{title}</h4>
        <div className="news-authors">
          {author ? this.renderAuthors(author) : null}
          <span
            className="news-category"
            style={{ backgroundColor: category.colorCode }}
          >
            {category[nameAttr]}
          </span>
        </div>
        <div className="news-text">
          {content ? (
            <div
              dangerouslySetInnerHTML={{
                __html: content,
              }}
            ></div>
          ) : (
            introduction
          )}
        </div>
      </li>
    );
  }
}

// Connected Component
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Content);
