import React, { Component } from "react";

import Subheader from "./Subheader";
import Infos from "./Infos";
import News from "./News";
import Team from "./Team";
import Docs from "./Docs";
import Contact from "./Contact";
import "./PublicPortal.scss";

class PublicPortal extends Component {
  render() {
    const { community, page, token } = this.props;

    let portalDetails = page.details;
    try {
      portalDetails = JSON.parse(page.details);
    } catch {}

    if (!community) {
      return <div id="portal-home" />;
    }
    return (
      <div id="portal-home">
        <Subheader blocs={portalDetails} community={community} />
        {portalDetails.media.activated && (
          <Infos token={token} community={community} />
        )}
        {portalDetails.news.activated && (
          <News token={token} community={community} />
        )}
        {portalDetails.team.activated && (
          <Team token={token} community={community} />
        )}
        {portalDetails.documents.activated && (
          <Docs token={token} community={community} />
        )}
        {portalDetails.contact.activated && (
          <Contact community={community} bloc={portalDetails.contact} />
        )}
      </div>
    );
  }
}

export default PublicPortal;
