import axios from "axios";
import { TTP_API_URL } from "config";

export const fetchNls = ({
  token,
  limit = 50,
  offset = 0,
  language,
  communityId,
  filters,
}) => {
  const requestUrl = `${TTP_API_URL}/mailing/nl`;
  //const requestUrl = `https://api2.tamtam.pro/mailing/nl`;

  filters.push({
    property: "organization.id",
    value: communityId,
    operator: "eq",
  });
  filters.push({
    property: "template.type",
    value: "TEMPLATE",
    operator: "nlike",
  });
  filters.push({
    property: "status",
    value: "SENT",
    operator: "like",
  });
  const sort = [
    {
      property: "createdAt",
      dir: "DESC",
    },
  ];

  const params = {
    access_token: token,
    //access_token: "a205593c6c888f3ddc086b82f099b77ec77dffde",
    fields: ["*", "tmpUrl", "template.id"].join(","),
    filter: JSON.stringify(filters),
    sort: JSON.stringify(sort),
    limit,
    start: offset,
  };

  return axios.get(requestUrl, { params });
};
