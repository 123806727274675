import React, { PureComponent } from "react";
import OrganizationInfos from "./OrganizationInfos";
import Media from "./Media";

export default class Infos extends PureComponent {
  render() {
    const { community, token } = this.props;
    return (
      <section id="portal-infos" className="grid-x">
        <Media token={token} community={community} />
        <OrganizationInfos size="small-6 medium-4" community={community} />
      </section>
    );
  }
}
