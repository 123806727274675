import axios from "axios";
import { TTP_API_URL } from "config";

export const fetchEventIds = ({ token, userId }) => {
  const requestUrl = `${TTP_API_URL}/event/event/get-events-access-for-contact`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      contact: userId,
    },
  });
};

export const fetchEvents = ({
  token,
  userId,
  communityId,
  language,
  period,
}) => {
  const requestUrl = `${TTP_API_URL}/event/event/get-list-for-user`;
  //const requestUrl = `https://api.tamtam.pro/event/event/get-list-for-user`;

  //const filter = [];
  const fields = [
    "id",
    "type",
    "nameFr",
    "nameNl",
    "nameEn",
    "placeFr",
    "placeNl",
    "placeEn",
    "descriptionFr",
    "descriptionNl",
    "descriptionEn",
    "urlBannerFr",
    "urlBannerNl",
    "urlBannerEn",
    "eventDate",
    "slotsCount",
    "client",
    "memberPrice",
    "nonMemberPrice",
    "languages",
    "isReplayable",
    "accreditationHours",
    "status",
    "replayStatus",
    "labelFr",
    "labelNl",
    "labelEn",
    "speakers-abstract",
    "user-registered",
  ];

  if (period !== "REPLAY") {
    fields.push("eventCycles");
  }

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      //access_token: "00000030f626ff6624f781119fdd35d7e90b6cf6",
      //filter: JSON.stringify(filter),
      limit: 50,
      start: 0,
      userId,
      communityIds: communityId,
      fields: fields.join(","),
      languages: language,
      period,
    },
  });
};

export const fetchMyEvents = ({ token, userId, communityId, language }) => {
  const requestUrl = `${TTP_API_URL}/event/get-user-registered-events`;
  //const requestUrl = `https://api.tamtam.pro/event/get-user-registered-events`;

  //const filter = [];
  const fields = [
    "id",
    "type",
    "nameFr",
    "nameNl",
    "nameEn",
    "placeFr",
    "placeNl",
    "placeEn",
    "descriptionFr",
    "descriptionNl",
    "descriptionEn",
    "urlBannerFr",
    "urlBannerNl",
    "urlBannerEn",
    "eventDate",
    "slotsCount",
    "client",
    "memberPrice",
    "nonMemberPrice",
    "languages",
    "isReplayable",
    "accreditationHours",
    "status",
    "replayStatus",
    "labelFr",
    "labelNl",
    "labelEn",
    "speakers-abstract",
  ];

  const params = {
    access_token: token,
    //access_token: "00000030f626ff6624f781119fdd35d7e90b6cf6",
    limit: 50,
    start: 0,
    userId,
    fields: fields.join(","),
    languages: language,
  };

  if (communityId) {
    params.communityIds = communityId;
  }

  return axios.get(requestUrl, {
    params,
  });
};

export const fetchCycles = ({ token, communityId, language }) => {
  const requestUrl = `${TTP_API_URL}/event/cycle`;
  //const requestUrl = `https://api.tamtam.pro/event/cycle`;

  const filters = [
    {
      property: "status",
      operator: "neq",
      value: 2,
    },
    {
      property: "languages",
      operator: "eq",
      value: language,
    },
    { property: "client", operator: "in", value: [communityId] },
  ];

  const sort = [
    {
      property: "startDateTime",
      dir: "ASC",
    },
  ];

  const fields = ["*", "eventsAbstract", "allEventsPrices"];

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      //access_token: "00000030f626ff6624f781119fdd35d7e90b6cf6",
      filter: JSON.stringify(filters),
      sort: JSON.stringify(sort),
      fields: fields.join(","),
      nolimit: 1,
    },
  });
};
