export const fetchPages = (resp) => ({
  type: "FETCH_PAGES",
  payload: resp,
});

export const savePage = (resp) => ({
  type: "SAVE_PAGE",
  payload: resp,
});

export const deletePage = (resp) => ({
  type: "DELETE_PAGE",
  payload: resp,
});
