import axios from "axios";
import { TTP_API_URL } from "config";

export const fetchMedias = ({
  token,
  filters = [],
  language,
  limit = 50,
  offset = 0,
  communityId,
}) => {
  const requestUrl = `${TTP_API_URL}/media/media`;

  const fields = [
    "*",
    "meta",
    "webPath",
    "title",
    "preview",
    "creator",
    "social",
    "organization",
  ];

  filters.push({
    property: "languages",
    operator: "flike",
    value: language,
  });

  if (
    communityId &&
    !filters.find((fil) => fil.property === "organization.id")
  ) {
    filters.push({
      property: "organization.id",
      operator: "eq",
      value: communityId,
    });
  }

  const allowedMediaTypes = ["IMAGE", "VIDEO", "PPT", "PDF"];

  filters.push({
    property: "objectType",
    operator: "in",
    value: [...allowedMediaTypes, "ARTICLE"],
  });

  const sort = [
    {
      property: "createdAt",
      dir: "desc",
    },
  ];

  const params = {
    access_token: token,
    fields: fields.join(","),
    filter: JSON.stringify(filters),
    sort: JSON.stringify(sort),
    limit,
    start: offset,
  };

  return axios.get(requestUrl, { params });
};

export const fetchAlbums = ({
  token,
  id,
  filters = [],
  limit = 25,
  offset = 0,
  communityId,
}) => {
  let filter = filters.filter((f) => f.property !== "docType");
  let fields = ["*", "color", "docType", "creator"];

  if (filters.filter((f) => f.property === "id").length === 0) {
    if (communityId) {
      filter.push({
        property: "organization",
        operator: "eq",
        value: communityId,
      });
    }
    fields.push("preview");
  } else {
    fields.push("medias", "tags");
  }

  const sort = [
    {
      property: "createdAt",
      dir: "desc",
    },
  ];

  const requestUrl = `${TTP_API_URL}/media/album`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filter),
      sort: JSON.stringify(sort),
      fields: fields.join(","),
      limit,
      start: offset,
    },
  });
};

export const fetchSingle = ({ token, id, communityId }) => {
  let filter = [];

  if (communityId) {
    filter.push({
      property: "organization",
      operator: "eq",
      value: communityId,
    });
  }

  filter.push({
    property: "id",
    operator: "eq",
    value: id,
  });

  const fields = ["*", "medias", "color", "docType", "creator"];
  const requestUrl = `${TTP_API_URL}/media/album`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filter),
      fields: fields.join(","),
    },
  });
};

export const fetchWebtools = ({
  token,
  filters = [],
  limit = 50,
  offset = 0,
  communityId,
}) => {
  const requestUrl = `${TTP_API_URL}/media/webtool`;

  const fields = ["*", "tags", "organization", "category", "icon"];

  if (
    communityId &&
    !filters.find((fil) => fil.property === "organization.id")
  ) {
    filters.push({
      property: "organization.id",
      operator: "eq",
      value: communityId,
    });
  }

  const sort = [
    {
      property: "createdAt",
      dir: "desc",
    },
  ];

  const params = {
    access_token: token,
    fields: fields.join(","),
    filter: JSON.stringify(filters),
    sort: JSON.stringify(sort),
    limit,
    start: offset,
  };

  return axios.get(requestUrl, { params });
};
