import { setLocalStorageAuth } from "utils";

const initialState = {
  fetching: false,
  fetched: false,
  token: "",
  expiresIn: null,
  createdAt: null,
  user: null,
  error: null,
  loggedAs: "EMPTY",
  loggedAsPost: null,
  navCommunity: null,
  currentPortal: "WORK",
  savingCommunityLayoutSettings: false,
  savingCommunityLayoutSettingsError: null,
  savingCommunityDrawersSettings: false,
  savingCommunityDrawersSettingsError: null,
  activeApps: {
    fetching: false,
    fetched: false,
    result: null,
    error: null,
  },
};

export const authReducer = (state = initialState, action) => {
  let result = { ...state };

  switch (action.type) {
    case "SET_AUTH": {
      result = action.auth == null ? { ...initialState } : { ...action.auth };
      break;
    }
    case "SET_AUTH_USER": {
      result = { ...state, user: action.user };
      break;
    }
    case "SET_AUTH_TOKEN": {
      result = { ...state, token: action.token };
      break;
    }
    case "SET_NAV_COMMUNITY": {
      const user = state.user;
      let loggedAsPost = null;
      if (user && user.roles && user.roles.length > 0) {
        const { roles } = user;
        for (let k = 0; k < roles.length; k++) {
          if (
            roles[k].type &&
            roles[k].organization &&
            roles[k].organization.id === action.community.id
          ) {
            loggedAsPost = roles[k].type;
            break;
          }
        }
      }
      result = { ...state, navCommunity: action.community, loggedAsPost };
      break;
    }
    case "SET_EXPIRES_IN": {
      result = { ...state, expiresIn: action.expiresIn };
      break;
    }
    case "SET_TOKEN_CREATED_AT": {
      result = { ...state, createdAt: action.createdAt };
      break;
    }
    case "SET_AUTH_LOGGED_AS": {
      result = { ...state, loggedAs: action.loggedAs };
      break;
    }
    case "SET_AUTH_LOGGED_AS_POST": {
      result = { ...state, loggedAsPost: action.loggedAsPost };
      break;
    }
    case "FETCH_AUTH_USER_PENDING": {
      result = { ...state, fetching: true };
      break;
    }
    case "FETCH_AUTH_USER_FULFILLED": {
      //let loggedAs = ?; //TODO Media role

      result = {
        ...state,
        fetching: false,
        fetched: true,
        //loggedAs,
        user: action.payload,
      };
      break;
    }
    case "FETCH_AUTH_USER_REJECTED": {
      result = {
        ...state,
        fetching: false,
        error: action.payload,
      };
      break;
    }

    case "UPDATE_GENERAL_SETTINGS": {
      const { navCommunity, user } = state;
      const {
        generalTypes,
        languages,
        publicProfile,
        defaultData,
      } = action.payload;

      navCommunity.mediaSettings = navCommunity.mediaSettings || {};
      navCommunity.mediaSettings.preferences = {
        generalTypes: generalTypes.join(","),
        languages: languages.join(","),
        defaultData: JSON.stringify(defaultData),
        publicProfile: publicProfile ? "1" : "0",
      };

      const communities = user.communities.map((c) => {
        if (c.id === navCommunity.id) {
          return navCommunity;
        }
        return c;
      });
      user.communities = communities;

      result = {
        ...state,
        navCommunity: { ...navCommunity },
        user: { ...user },
      };
      break;
    }
    case "UPDATE_USERS": {
      const { community, types, languages } = action.payload;

      if (community.id !== state.navCommunity.id) {
        result = state;
        break;
      }

      const mediaSettings = state.navCommunity.mediaSettings || {};
      const roles = mediaSettings.roles || {};
      const preferences = roles.preferences || {};

      result = {
        ...state,
        navCommunity: {
          ...state.navCommunity,
          mediaSettings: {
            ...mediaSettings,
            roles: {
              ...roles,
              imageRole: types["IMAGE"],
              videoRole: types["VIDEO"],
              pdfRole: types["PDF"],
              pptRole: types["PPT"],
              preferences: {
                ...preferences,
                languages: languages.join(","),
              },
            },
          },
        },
      };
      break;
    }
    case "SET_ACTIVE_APPS_PENDING": {
      result = {
        ...state,
        activeApps: { ...state.activeApps, fetching: true },
      };
      break;
    }
    case "SET_ACTIVE_APPS_FULFILLED": {
      result = {
        ...state,
        activeApps: {
          ...state.activeApps,
          fetching: false,
          fetched: true,
          result: action.payload.data.data,
        },
      };
      break;
    }
    case "SET_ACTIVE_APPS_REJECTED": {
      result = {
        ...state,
        activeApps: {
          ...state.activeApps,
          fetching: false,
          error: action.payload,
        },
      };
      break;
    }
    case "SET_CURRENT_PORTAL":
      result = {
        ...state,
        currentPortal: action.currentPortal,
      };
      break;
    case "SAVE_COMMUNITY_LAYOUT_SETTINGS_PENDING": {
      return {
        ...state,
        savingCommunityLayoutSettings: true,
        savingCommunityLayoutSettingsError: null,
      };
    }
    case "SAVE_COMMUNITY_LAYOUT_SETTINGS_FULFILLED": {
      let { layout_settings, organization_id } = action.payload.data.data;

      try {
        layout_settings = JSON.parse(layout_settings);
      } catch (e) {
        console.error(e);
        return state;
      }

      const newData = {};

      if (state.navCommunity) {
        const newHubPreferences = state.navCommunity.hubPreferences || {};
        newHubPreferences.layout_settings = layout_settings;
        const newNavCommunity = {
          ...state.navCommunity,
          hubPreferences: newHubPreferences,
        };
        newData.navCommunity = newNavCommunity;
      }

      const community =
        state.user &&
        state.user.communities &&
        state.user.communities.find((c) => c.id === +organization_id);

      if (community) {
        const newHubPreferences = community.hubPreferences || {};
        newHubPreferences.layout_settings = layout_settings;

        const newCommunity = {
          ...community,
          hubPreferences: newHubPreferences,
        };

        const newCommunities = state.user.communities.map((c) => {
          if (c.id !== newCommunity.id) {
            return c;
          }

          return newCommunity;
        });

        const newUser = { ...state.user, communities: newCommunities };
        newData.user = newUser;
      }

      return {
        ...state,
        savingCommunityLayoutSettings: false,
        savingCommunityLayoutSettingsError: null,
        ...newData,
      };
    }
    case "SAVE_COMMUNITY_LAYOUT_SETTINGS_REJECTED": {
      return {
        ...state,
        savingCommunityLayoutSettings: false,
        savingCommunityLayoutSettingsError: action.payload || "Unknown Error",
      };
    }
    case "SAVE_COMMUNITY_DRAWERS_SETTINGS_PENDING": {
      return {
        ...state,
        savingCommunityDrawersSettings: true,
        savingCommunityDrawersSettingsError: null,
      };
    }
    case "SAVE_COMMUNITY_DRAWERS_SETTINGS_FULFILLED": {
      let { drawers, organization_id } = action.payload.data.data;

      try {
        drawers = JSON.parse(drawers);
      } catch (e) {
        console.error(e);
        return state;
      }

      const newData = {};

      if (state.navCommunity) {
        const newHubPreferences = state.navCommunity.hubPreferences || {};
        newHubPreferences.drawers = drawers;
        const newNavCommunity = {
          ...state.navCommunity,
          hubPreferences: newHubPreferences,
        };
        newData.navCommunity = newNavCommunity;
      }

      const community =
        state.user &&
        state.user.communities &&
        state.user.communities.find((c) => c.id === +organization_id);

      if (community) {
        const newHubPreferences = community.hubPreferences || {};
        newHubPreferences.drawers = drawers;

        const newCommunity = {
          ...community,
          hubPreferences: newHubPreferences,
        };

        const newCommunities = state.user.communities.map((c) => {
          if (c.id !== newCommunity.id) {
            return c;
          }

          return newCommunity;
        });

        const newUser = { ...state.user, communities: newCommunities };
        newData.user = newUser;
      }

      return {
        ...state,
        savingCommunityDrawersSettings: false,
        savingCommunityDrawersSettingsError: null,
        ...newData,
      };
    }
    case "SAVE_COMMUNITY_DRAWERS_SETTINGS_REJECTED": {
      return {
        ...state,
        savingCommunityDrawersSettings: false,
        savingCommunityDrawersSettingsError: action.payload || "Unknown Error",
      };
    }
    default:
      return state;
  }

  setLocalStorageAuth(result);

  return result;
};
