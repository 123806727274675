import React, { Component } from "react";
import Modal from "react-modal";
import InfiniteScroll from "react-infinite-scroll-component";
import { ArticleList } from "tamtam-components";

import {
  fetchArticles,
  fetchMedias,
  fetchWebtools,
  //fetchEventIds,
  fetchEvents,
  fetchMyEvents,
  fetchCycles,
  fetchAlbums,
  fetchNls,
  getOrganization,
} from "../../api";

// import ArticleComponent from "../Article/ArticleComponent";
import ArticleOrganization from "../Article/ArticleOrganization";
import EmailingComponent from "../Emailing/EmailingComponent";

import MediaContainer from "../Media/MediaContainer";
import Album from "../Album";
import EventComponent from "../Event/EventComponent";
import CycleComponent from "../Cycle/CycleComponent";
import PublicPortal from "../PublicPortal";
import Webtool from "../Webtool";
import Filter from "../Filter";

import MediaViewer from "Reveal/MediaViewer";

import styles from "./Page.module.scss";
import classnames from "classnames";

import { APP_ENV, TTP_DASHBOARD_URL } from "config";

import _ from "i18n";

const modalStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(41, 57, 77, 0.4)",
    zIndex: 10,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: "transparent",
    border: "none",
    overflow: "none",
    padding: 0,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "#fff",
  },
};

const IconArrowLeft = (props) => (
  <svg {...props} width="14" height="14" viewBox="0 0 14 13" fill="none">
    <path
      d="M0.259444 6.71393L5.55299 11.9378C5.89429 12.2756 6.44627 12.2713 6.78408 11.9291C7.1219 11.5869 7.11842 11.0358 6.77536 10.698L2.99241 6.96469L13.1294 6.96469C13.6108 6.96469 14 6.57552 14 6.09406C14 5.6126 13.6108 5.22339 13.1294 5.22339L2.99241 5.22339L6.77536 1.49007C7.11838 1.15225 7.121 0.601139 6.78408 0.25898C6.61345 0.0865905 6.38881 -0.000463774 6.16418 -0.000463754C5.94302 -0.000463735 5.7219 0.0831096 5.55299 0.250299L0.259444 5.4742C0.0940155 5.63786 -5.53185e-07 5.86078 -5.32787e-07 6.0941C-5.12397e-07 6.32734 0.0931554 6.54936 0.259444 6.71393Z"
      fill="currentColor"
    />
  </svg>
);

const DEFAULT_LAYOUT_MODEL = [
  "default",
  "type2",
  "type3",
  "type4",
  "type5",
  "type6",
  "type7",
];
const LAYOUT_MODELS = {
  T1: ["default"],
  T2: ["default", "type1"],
  T3: ["default", "type2", "type5", "type4", "type1"],
  T4: ["default", "type4", "type1", "type2", "type6"],
  T5: ["type1", "type5", "type6", "type4", "default"],
  T6: ["type1", "type2", "default", "type5", "type4"],
  T7: ["type1", "type4", "default", "type6", "type2"],
};

export class PagePreview extends Component {
  state = {
    loading: false,
    loadingArticles: true,
    articles: [],
    medias: [],
    events: [],
    cycles: [],
    webtools: [],
    myEventsType: null,
    nls: [],
    currentAlbum: null,
    offset: 0,
    dataLength: 0,
    currentMediaId: null,
    filterFired: false,
    community: null,
  };

  componentDidMount() {
    this.fetchResource();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.page !== this.props.page) {
      this.fetchResource();
    }
  }

  fetchResource = (customFilter = null) => {
    const { offset } = this.state;
    const { token, page, communityId, userId } = this.props;

    if (!page || !token) {
      return;
    }
    this.setState({ loading: true });
    let { resource, language, filters, organization, user } = page;

    if (typeof filters === "string") {
      filters = JSON.parse(filters);
    }
    let helpFilters = [...filters];
    if (customFilter) {
      helpFilters = Array.isArray(customFilter)
        ? [...customFilter]
        : [customFilter];
    }

    let limit = 10;
    if (resource === "BLOG") {
      limit = 30;
      fetchArticles({
        token,
        offset,
        limit,
        filters: helpFilters,
        language,
        communityId: organization
          ? organization.id
          : communityId || (page && page.communityId ? page.communityId : null),
      })
        .then((res) => {
          const { articles, filterFired } = this.state;
          this.setState({
            loading: false,
            loadingArticles: false,
            articles: filterFired
              ? [...res.data.data]
              : articles.concat(res.data.data),
            dataLength: res.data.nbResult,
            offset: offset + limit,
          });
          if (filterFired) {
            this.setState({ filterFired: false });
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }

    if (resource === "EMAILING") {
      fetchNls({
        token,
        offset,
        limit,
        filters,
        language,
        communityId: organization
          ? organization.id
          : communityId || (page && page.communityId ? page.communityId : null),
      })
        .then((res) => {
          this.setState({
            loading: false,
            nls: this.state.nls.concat(res.data.data),
            dataLength: res.data.nbResult,
            offset: offset + limit,
          });
        })
        .catch((e) => {
          console.error(e);
        });
    }

    if (resource === "WEBTOOL") {
      fetchWebtools({
        token,
        offset,
        limit,
        filters,
        communityId: organization
          ? organization.id
          : communityId || (page && page.communityId ? page.communityId : null),
      })
        .then((res) => {
          this.setState({
            loading: false,
            webtools: this.state.webtools.concat(res.data.data),
            dataLength: res.data.nbResult,
            offset: offset + limit,
          });
        })
        .catch((e) => {
          console.error(e);
        });
    }

    if (resource === "MEDIA") {
      let help = null;
      if (page.filters) {
        help = !Array.isArray(page.filters)
          ? JSON.parse(page.filters)
          : page.filters;
      }
      if (
        help &&
        help.length > 0 &&
        help.filter((f) => f.property === "docType" && f.value[0] === "ALBUM")
          .length > 0
      ) {
        fetchAlbums({
          token,
          filters,
          language,
          communityId: organization
            ? organization.id
            : communityId ||
              (page && page.communityId ? page.communityId : null),
        })
          .then((res) => {
            console.log(res);
            this.setState({ loading: false, medias: res.data.data });
          })
          .catch((e) => {
            console.error(e);
          });
      } else {
        fetchMedias({
          token,
          filters,
          language,
          communityId: organization
            ? organization.id
            : communityId ||
              (page && page.communityId ? page.communityId : null),
        })
          .then((res) => {
            console.log(res);
            this.setState({
              loading: false,
              medias: this.state.medias.concat(res.data.data),
              dataLength: res.data.nbResult,
              offset: offset + limit,
            });
          })
          .catch((e) => {
            console.error(e);
          });
      }
    }
    if (resource === "ALBUM" && filters.length > 0) {
      fetchAlbums({
        token,
        filters,
        language,
        communityId: organization
          ? organization.id
          : communityId || (page && page.communityId ? page.communityId : null),
      })
        .then((res) => {
          console.log(res);
          this.setState({ loading: false, medias: res.data.data });
        })
        .catch((e) => {
          console.error(e);
        });
    }
    if (resource === "EVENT") {
      let period = "current";
      let help = null;
      if (page.filters) {
        help = !Array.isArray(page.filters)
          ? JSON.parse(page.filters)
          : page.filters;
      }
      if (help && help.length > 0) {
        const periodRow = help.filter((f) => f.property === "type");
        if (periodRow && periodRow.length > 0) {
          period = periodRow[0].value;
        }
      }
      if (period === "cycle") {
        fetchCycles({
          token,
          communityId: organization
            ? organization.id
            : communityId ||
              (page && page.communityId ? page.communityId : null),
          language,
        }).then((res) => {
          this.setState({ loading: false, cycles: res.data.data });
        });
      } else if (period === "my_space") {
        fetchMyEvents({
          token,
          userId: userId || (user ? user.id : null),
          language,
        }).then((res) => {
          this.setState({
            loading: false,
            events: res.data.data,
            myEventsType: "SPACE",
          });
        });
      } else {
        const isMyReplays = period === "my_replays";
        fetchEvents({
          token,
          userId: userId || (user ? user.id : null),
          communityId: isMyReplays
            ? null
            : organization
            ? organization.id
            : communityId ||
              (page && page.communityId ? page.communityId : null),
          language,
          period: isMyReplays ? "my-replay" : period,
        }).then((res) => {
          this.setState({
            loading: false,
            events: res.data.data,
            myEventsType: isMyReplays ? "REPLAY" : null,
          });
        });
      }
      /*fetchEventIds({ token, filters, userId: user ? user.id : userId || null })
        .then((resp) => {
          if (resp && resp.data && resp.data.data && resp.data.data.events) {
            fetchEvents({ token, ids: resp.data.data.events }).then((res) => {
              this.setState({ loading: false, events: res.data.data });
            });
          }
        })
        .catch((e) => {
          console.error(e);
        });*/
    }
    if (resource === "PUBLIC_PORTAL") {
      getOrganization({
        token,
        communityId: organization
          ? organization.id
          : communityId || (page && page.communityId ? page.communityId : null),
      })
        .then((res) => {
          this.setState({
            loading: false,
            community:
              res.data && res.data.data && res.data.data.length > 0
                ? res.data.data[0]
                : null,
          });
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  handleFilter = (filter, filterStyles, e) => {
    const current =
      e.currentTarget.tagName === "LI"
        ? e.currentTarget
        : e.currentTarget.parentNode;
    if (current.classList.contains(filterStyles.selected)) {
      return false;
    }
    $(`.${filterStyles.part} li`).removeClass(filterStyles.selected);
    current.classList.add(filterStyles.selected);
    this.setState({ filterFired: true });
    this.fetchResource(filter);
  };

  renderFetchingArticles() {
    return (
      <div className={styles.fetching}>
        <div className={styles.bigArticle} />
        <div className={styles.bigArticle} />
        <div className={styles.bigArticle} />
        <div className={styles.bigArticle} />
      </div>
    );
  }

  /*renderBlogT1() {
    const { articles, loadingArticles } = this.state;
    const { token, communityId, page } = this.props;

    return (
      <div className={classnames(styles.bloc, "grid-x")}>
        <div className="grid-x cell small-12 medium-9">
          {page.title ? (
            <h1 style={{ marginBottom: page.subtitle ? "1rem" : "2rem" }}>
              {page.title}
            </h1>
          ) : null}
          {page.subtitle ? <h2>{page.subtitle}</h2> : null}
          {loadingArticles ? (
            this.renderFetchingArticles()
          ) : (
            <InfiniteScroll
              dataLength={this.state.articles.length}
              next={this.fetchResource}
              hasMore={this.state.dataLength > this.state.articles.length}
              loader={this.renderFetchingArticles()}
            >
              {articles.map((article) => (
                <div
                  className="cell smal-12"
                  key={`article-container-${article.id}`}
                >
                  <ArticleComponent
                    article={article}
                    community={article.organization}
                    index={0}
                    template={"T1"}
                    auth={this.props.auth}
                  />
                </div>
              ))}
            </InfiniteScroll>
          )}
        </div>
        <div
          className="cell small-0 medium-3 blocSideBar"
          style={{ paddingLeft: "1rem" }}
        >
          <Filter
            token={token}
            communityId={communityId}
            language={page ? page.language : "fr"}
            setFilter={this.handleFilter}
          />
          <ArticleOrganization
            communityId={communityId}
            organization={page.organization}
          />
        </div>
      </div>
    );
  }

  renderBlogT2() {
    const { articles, loadingArticles } = this.state;
    const { token, communityId, page } = this.props;

    let blocArticles = [];

    for (let i = 0; i < articles.length; i++) {
      blocArticles.push(
        <div key={`article-t2-${i}`} className="grid-x">
          {articles[i] ? (
            <div className="small-12 medium-8">
              <ArticleComponent
                article={articles[i]}
                community={articles[i++].organization}
                index={0}
                template={"T2"}
                auth={this.props.auth}
              />
            </div>
          ) : null}

          <div className="small-12 medium-4 grid-x">
            {articles[i] ? (
              <div
                className="cell smal-12"
                key={`article-container-${articles[i].id}`}
              >
                <ArticleComponent
                  article={articles[i]}
                  community={articles[i++].organization}
                  index={1}
                  template={"T2"}
                  auth={this.props.auth}
                />
              </div>
            ) : null}
            {articles[i] ? (
              <div
                className="cell smal-12"
                key={`article-container-${articles[i].id}`}
              >
                <ArticleComponent
                  article={articles[i]}
                  community={articles[i++].organization}
                  index={1}
                  template={"T2"}
                  auth={this.props.auth}
                />
              </div>
            ) : null}
          </div>
        </div>
      );
      if (articles[i]) {
        blocArticles.push(
          <div key={`article-t2-${i}`} className="grid-x">
            <div className="small-12">
              <ArticleComponent
                article={articles[i]}
                community={articles[i++].organization}
                index={0}
                template={"T2"}
                auth={this.props.auth}
              />
            </div>
          </div>
        );
      }
      blocArticles.push(
        <div key={`article-t2-${i}`} className="grid-x">
          <div className="small-12 medium-4 grid-x">
            {articles[i] ? (
              <div
                className="cell smal-12"
                key={`article-container-${articles[i].id}`}
              >
                <ArticleComponent
                  article={articles[i]}
                  community={articles[i++].organization}
                  index={1}
                  template={"T2"}
                  auth={this.props.auth}
                />
              </div>
            ) : null}
            {articles[i] ? (
              <div
                className="cell smal-12"
                key={`article-container-${articles[i].id}`}
              >
                <ArticleComponent
                  article={articles[i]}
                  community={articles[i++].organization}
                  index={1}
                  template={"T2"}
                  auth={this.props.auth}
                />
              </div>
            ) : null}
          </div>
          {articles[i] ? (
            <div className="small-12 medium-8">
              <ArticleComponent
                article={articles[i]}
                community={articles[i].organization}
                index={0}
                template={"T2"}
                auth={this.props.auth}
              />
            </div>
          ) : null}
        </div>
      );
    }
    return (
      <div className={classnames("grid-x", styles.bloc)}>
        <div className="cell small-12 medium-9">
          {page.title ? (
            <h1 style={{ marginBottom: page.subtitle ? "1rem" : "2rem" }}>
              {page.title}
            </h1>
          ) : null}
          {page.subtitle ? <h2>{page.subtitle}</h2> : null}

          {loadingArticles ? (
            this.renderFetchingArticles()
          ) : (
            <InfiniteScroll
              dataLength={this.state.articles.length}
              next={this.fetchResource}
              hasMore={this.state.dataLength > this.state.articles.length}
              loader={this.renderFetchingArticles()}
            >
              {blocArticles}
            </InfiniteScroll>
          )}
        </div>
        <div
          className="cell small-12 medium-3 blocSideBar"
          style={{ paddingLeft: "1rem" }}
        >
          <Filter
            token={token}
            communityId={communityId}
            language={page ? page.language : "fr"}
            setFilter={this.handleFilter}
          />
          <ArticleOrganization
            communityId={communityId}
            organization={page.organization}
          />
        </div>
      </div>
    );
  }

  renderBlogT3() {
    const { articles, loadingArticles } = this.state;
    const { token, communityId, page } = this.props;

    let blocArticles = [];

    for (let i = 0; i < articles.length; i++) {
      if (articles[i]) {
        blocArticles.push(
          <div key={`article-t2-${i}`} className="grid-x">
            <div className="small-12">
              <ArticleComponent
                article={articles[i]}
                community={articles[i++].organization}
                index={0}
                template={"T2"}
                auth={this.props.auth}
              />
            </div>
          </div>
        );
      }
      blocArticles.push(
        <div key={`article-t2-${i}`} className="grid-x">
          {articles[i] ? (
            <div
              className="cell smal-12 medium-4"
              key={`article-container-${articles[i].id}`}
            >
              <ArticleComponent
                article={articles[i]}
                community={articles[i++].organization}
                index={1}
                template={"T3"}
                auth={this.props.auth}
              />
            </div>
          ) : null}
          {articles[i] ? (
            <div
              className="cell smal-12 medium-4"
              key={`article-container-${articles[i].id}`}
            >
              <ArticleComponent
                article={articles[i]}
                community={articles[i++].organization}
                index={1}
                template={"T3"}
                auth={this.props.auth}
              />
            </div>
          ) : null}
          {articles[i] ? (
            <div
              className="cell smal-12 medium-4"
              key={`article-container-${articles[i].id}`}
            >
              <ArticleComponent
                article={articles[i]}
                community={articles[i++].organization}
                index={1}
                template={"T3"}
                auth={this.props.auth}
              />
            </div>
          ) : null}
        </div>
      );

      if (articles[i]) {
        blocArticles.push(
          <div key={`article-t2-${i}`} className="grid-x">
            <div className="small-12">
              <ArticleComponent
                article={articles[i]}
                community={articles[i++].organization}
                index={0}
                template={"T2"}
                auth={this.props.auth}
              />
            </div>
          </div>
        );
      }
      blocArticles.push(
        <div key={`article-t2-${i}`} className="grid-x">
          {articles[i] ? (
            <div
              className="cell smal-12 small-8"
              key={`article-container-${articles[i].id}`}
            >
              <ArticleComponent
                article={articles[i]}
                community={articles[i++].organization}
                index={1}
                template={"T4"}
                auth={this.props.auth}
              />
            </div>
          ) : null}
          {articles[i] ? (
            <div
              className="cell smal-12 small-4"
              key={`article-container-${articles[i].id}`}
            >
              <ArticleComponent
                article={articles[i]}
                community={articles[i++].organization}
                index={1}
                template={"T4_2"}
                auth={this.props.auth}
              />
            </div>
          ) : null}
        </div>
      );
    }
    return (
      <div className={classnames("grid-x", styles.bloc)}>
        <div className="cell small-12 medium-9">
          {page.title ? (
            <h1 style={{ marginBottom: page.subtitle ? "1rem" : "2rem" }}>
              {page.title}
            </h1>
          ) : null}
          {page.subtitle ? <h2>{page.subtitle}</h2> : null}
          {loadingArticles ? (
            this.renderFetchingArticles()
          ) : (
            <InfiniteScroll
              dataLength={this.state.articles.length}
              next={this.fetchResource}
              hasMore={this.state.dataLength > this.state.articles.length}
              loader={this.renderFetchingArticles()}
            >
              {blocArticles}
            </InfiniteScroll>
          )}
        </div>
        <div
          className="cell small-12 medium-3 blocSideBar"
          style={{ paddingLeft: "1rem" }}
        >
          <Filter
            token={token}
            communityId={communityId}
            language={page ? page.language : "fr"}
            setFilter={this.handleFilter}
          />
          <ArticleOrganization
            communityId={communityId}
            organization={page.organization}
          />
        </div>
      </div>
    );
  }

  renderBlogT4() {
    const { articles, loadingArticles } = this.state;
    const { token, communityId, page } = this.props;

    let blocArticles = [];

    for (let i = 0; i < articles.length; i++) {
      if (articles[i]) {
        blocArticles.push(
          <div key={`article-t2-${i}`} className="grid-x">
            <div className="small-12">
              <ArticleComponent
                article={articles[i]}
                community={articles[i++].organization}
                index={0}
                template={"T2"}
                auth={this.props.auth}
              />
            </div>
          </div>
        );
      }
      blocArticles.push(
        <div key={`article-t2-${i}`} className="grid-x">
          {articles[i] ? (
            <div
              className="cell smal-12 small-8"
              key={`article-container-${articles[i].id}`}
            >
              <ArticleComponent
                article={articles[i]}
                community={articles[i++].organization}
                index={1}
                template={"T4"}
                auth={this.props.auth}
              />
            </div>
          ) : null}
          {articles[i] ? (
            <div
              className="cell smal-12 small-4"
              key={`article-container-${articles[i].id}`}
            >
              <ArticleComponent
                article={articles[i]}
                community={articles[i++].organization}
                index={1}
                template={"T4_2"}
                auth={this.props.auth}
              />
            </div>
          ) : null}
        </div>
      );
      blocArticles.push(
        <div key={`article-t2-${i}`} className="grid-x">
          {articles[i] ? (
            <div
              className="cell smal-12 medium-4"
              key={`article-container-${articles[i].id}`}
            >
              <ArticleComponent
                article={articles[i]}
                community={articles[i++].organization}
                index={1}
                template={"T3"}
                auth={this.props.auth}
              />
            </div>
          ) : null}
          {articles[i] ? (
            <div
              className="cell smal-12 medium-4"
              key={`article-container-${articles[i].id}`}
            >
              <ArticleComponent
                article={articles[i]}
                community={articles[i++].organization}
                index={1}
                template={"T3"}
                auth={this.props.auth}
              />
            </div>
          ) : null}
          {articles[i] ? (
            <div
              className="cell smal-12 medium-4"
              key={`article-container-${articles[i].id}`}
            >
              <ArticleComponent
                article={articles[i]}
                community={articles[i++].organization}
                index={1}
                template={"T3"}
                auth={this.props.auth}
              />
            </div>
          ) : null}
        </div>
      );
      if (articles[i]) {
        blocArticles.push(
          <div key={`article-t2-${i}`} className="grid-x">
            <div className="small-12">
              <ArticleComponent
                article={articles[i]}
                community={articles[i++].organization}
                index={0}
                template={"T2"}
                auth={this.props.auth}
              />
            </div>
          </div>
        );
      }
    }
    return (
      <div className={classnames("grid-x", styles.bloc)}>
        <div className="cell small-12 medium-9">
          {page.title ? (
            <h1 style={{ marginBottom: page.subtitle ? "1rem" : "2rem" }}>
              {page.title}
            </h1>
          ) : null}
          {page.subtitle ? <h2>{page.subtitle}</h2> : null}
          {loadingArticles ? (
            this.renderFetchingArticles()
          ) : (
            <InfiniteScroll
              dataLength={this.state.articles.length}
              next={this.fetchResource}
              hasMore={this.state.dataLength > this.state.articles.length}
              loader={this.renderFetchingArticles()}
            >
              {blocArticles}
            </InfiniteScroll>
          )}
        </div>
        <div
          className="cell small-12 medium-3 blocSideBar"
          style={{ paddingLeft: "1rem" }}
        >
          <Filter
            token={token}
            communityId={communityId}
            language={page ? page.language : "fr"}
            setFilter={this.handleFilter}
          />
          <ArticleOrganization
            communityId={communityId}
            organization={page.organization}
          />
        </div>
      </div>
    );
  }*/

  renderBlogPage() {
    const { token, communityId, page } = this.props;
    const { template, user } = page;
    const { articles, loadingArticles } = this.state;

    // let bloc = this.renderBlogT1();
    // switch (template) {
    //   case "T2":
    //     bloc = this.renderBlogT2();
    //     break;
    //   case "T3":
    //     bloc = this.renderBlogT3();
    //     break;
    //   case "T4":
    //     bloc = this.renderBlogT4();
    //     break;
    //   default:
    // }

    // return bloc;

    return (
      <div className={classnames(styles.bloc, "grid-x grid-margin-x")}>
        <div className="cell small-12 medium-8 large-9">
          {page.title ? (
            <h1 style={{ marginBottom: page.subtitle ? "1rem" : "2rem" }}>
              {page.title}
            </h1>
          ) : null}
          {page.subtitle ? <h2>{page.subtitle}</h2> : null}

          {loadingArticles ? (
            this.renderFetchingArticles()
          ) : (
            <InfiniteScroll
              dataLength={this.state.articles.length}
              next={this.fetchResource}
              hasMore={this.state.dataLength > this.state.articles.length}
              loader={this.renderFetchingArticles()}
            >
              <ArticleList
                articles={articles}
                dispositions={
                  LAYOUT_MODELS[template]
                    ? LAYOUT_MODELS[template]
                    : DEFAULT_LAYOUT_MODEL
                }
                navCommunityId={communityId}
                env={APP_ENV}
                user={user}
                // host={host}
              />
            </InfiniteScroll>
          )}
        </div>
        <div
          className="cell small-0 medium-4 large-3 blocSideBar"
          style={{ paddingLeft: "1rem" }}
        >
          <Filter
            token={token}
            communityId={communityId}
            language={page ? page.language : "fr"}
            setFilter={this.handleFilter}
          />
          <ArticleOrganization
            communityId={communityId}
            organization={page.organization}
          />
        </div>
      </div>
    );
  }

  renderEmailingPage() {
    const { nls } = this.state;

    return (
      <div className={styles.bloc}>
        <InfiniteScroll
          dataLength={this.state.nls.length}
          next={this.fetchResource}
          hasMore={this.state.dataLength > this.state.nls.length}
          className="grid-x"
        >
          {nls.map((nl) => (
            <div
              className="cell small-12 medium-4"
              key={`article-container-${nl.id}`}
              style={{
                padding: ".25rem",
              }}
            >
              <EmailingComponent
                nl={nl}
                community={nl.organization}
                index={0}
                template={"T1"}
              />
            </div>
          ))}
        </InfiniteScroll>
      </div>
    );
  }

  renderDashboardPage() {
    return (
      <div className={styles.bloc}>
        <iframe
          src={`${TTP_DASHBOARD_URL}?without_header`}
          title="Dashboard"
          sandbox="allow-same-origin allow-storage-access-by-user-activation allow-scripts"
        />
      </div>
    );
  }

  renderPublicPortalPage() {
    const { community } = this.state;
    const { page, token } = this.props;

    return (
      <div className={styles.bloc}>
        <PublicPortal community={community} page={page} token={token} />
      </div>
    );
  }

  handleOpenMediaViewer = (currentMedia, ref) => {
    this.setState({ currentMediaId: currentMedia.id });
    window.scrollTo({ behavior: "smooth", top: ref.current.offsetTop + 200 });
  };

  handleCloseMediaViewer = (e) => {
    this.setState({ currentMediaId: null });
  };

  renderMediaPage() {
    const { medias } = this.state;
    const { template, asMagazine } = this.props.page;

    let bloc = this.renderMediaT1(medias, template, asMagazine);
    switch (template) {
      case "T6":
        bloc = this.renderMediaT6(medias);
        break;
      default:
    }
    return bloc;
  }

  renderMediaT1(medias, template, asMagazine) {
    return (
      <ul>
        <InfiniteScroll
          dataLength={this.state.medias.length}
          next={this.fetchResource}
          hasMore={this.state.dataLength > this.state.medias.length}
          className="grid-x"
        >
          {medias.map((media) => {
            const ref = React.createRef();
            return (
              <li
                className={classnames(
                  `cell smal-12 medium-6 large-3`,
                  this.state.currentMediaId === media.id
                    ? styles.mediaSelected
                    : ""
                )}
                key={`media-container-${media.id}`}
                ref={ref}
              >
                <MediaContainer
                  media={media}
                  language={this.props.page.language}
                  template={template}
                  openViewer={this.handleOpenMediaViewer.bind(this, media, ref)}
                />
                {this.state.currentMediaId === media.id && (
                  <MediaViewer
                    asMagazine={asMagazine}
                    closeOpenViewer={this.handleCloseMediaViewer}
                  />
                )}
              </li>
            );
          })}
        </InfiniteScroll>
      </ul>
    );
  }

  renderMiniMediaBloc(media, col, isTop = false) {
    const { template } = this.props.page;
    const ref = React.createRef();

    let helpCol = col;
    if (isTop) {
      helpCol += 12;
    }
    return (
      <li
        className={classnames(
          `cell smal-12 medium-${col}`,
          this.state.currentMediaId === media.id ? styles.mediaSelected : ""
        )}
        key={`media-container-${media.id}`}
        ref={ref}
      >
        <MediaContainer
          media={media}
          language={this.props.page.language}
          template={template}
          openViewer={this.handleOpenMediaViewer.bind(this, media, ref)}
          col={helpCol}
        />
        {this.state.currentMediaId === media.id && (
          <MediaViewer
            asMagazine={false}
            closeOpenViewer={this.handleCloseMediaViewer}
          />
        )}
      </li>
    );
  }

  renderMediaT6(medias, album = null) {
    if (!medias || medias.length === 0) {
      return null;
    }
    const couples = ["12", "8-4", "6-6", "4-8", "4-4-4"];

    let blocs = [
      this.renderMiniMediaBloc(
        album ? { ...medias[0], album } : medias[0],
        12,
        true
      ),
    ];
    let i = 1;
    while (i < medias.length) {
      const help = couples[Math.floor(Math.random() * couples.length)].split(
        "-"
      );
      switch (help.length) {
        case 1:
          blocs.push(this.renderMiniMediaBloc(medias[i++], help[0]));
          break;
        case 2:
          blocs.push(this.renderMiniMediaBloc(medias[i++], help[0]));
          if (i < medias.length) {
            blocs.push(this.renderMiniMediaBloc(medias[i++], help[1]));
          }
          break;
        case 3:
          blocs.push(this.renderMiniMediaBloc(medias[i++], help[0]));
          if (i < medias.length) {
            blocs.push(this.renderMiniMediaBloc(medias[i++], help[1]));
          }
          if (i < medias.length) {
            blocs.push(this.renderMiniMediaBloc(medias[i++], help[2]));
          }
          break;
        default:
          break;
      }
    }

    return (
      <ul>
        {/* <InfiniteScroll
          dataLength={this.state.medias.length}
          next={this.fetchResource}
          hasMore={this.state.dataLength > this.state.medias.length}
          className="grid-x"
        > */}
        <div className="grid-x">
          {
            blocs /*medias.map((media) => {
            const ref = React.createRef();
            return (
              <li
                className={classnames(
                  `cell smal-12 medium-6 large-3`,
                  this.state.currentMediaId === media.id
                    ? styles.mediaSelected
                    : ""
                )}
                key={`media-container-${media.id}`}
                ref={ref}
              >
                <MediaContainer
                  media={media}
                  language={this.props.page.language}
                  template={template}
                  openViewer={this.handleOpenMediaViewer.bind(this, media, ref)}
                />
                {this.state.currentMediaId === media.id && (
                  <MediaViewer
                    asMagazine={false}
                    closeOpenViewer={this.handleCloseMediaViewer}
                  />
                )}
              </li>
            );
          })*/
          }
        </div>
        {/* </InfiniteScroll> */}
      </ul>
    );
  }

  renderWebtoolPage() {
    const { webtools } = this.state;

    return (
      <ul>
        <InfiniteScroll
          dataLength={this.state.webtools.length}
          next={this.fetchResource}
          hasMore={this.state.dataLength > this.state.webtools.length}
          className="grid-x grid-margin-x"
        >
          {webtools.map((webtool) => {
            const ref = React.createRef();
            return (
              <li
                className={`cell smal-12 medium-6 large-3`}
                key={`webtool-${webtool.id}`}
                ref={ref}
              >
                <Webtool webtool={webtool} lng={this.props.page.language} />
              </li>
            );
          })}
        </InfiniteScroll>
      </ul>
    );
  }

  renderAlbumPage() {
    const { template } = this.props.page;

    let bloc = this.renderAlbumT1();
    switch (template) {
      case "T3":
        bloc = this.renderAlbumT3();
        break;
      case "T4":
        bloc = this.renderAlbumT4();
        break;
      case "T5":
        bloc = this.renderAlbumT5();
        break;
      default:
    }

    return bloc;
  }

  renderAlbumT1() {
    const { medias } = this.state;
    const { page, token } = this.props;

    return (
      <div className={styles.bloc}>
        <div className={styles.album_head}>
          {page.title ? (
            <h1 style={{ marginBottom: page.subtitle ? "1rem" : 0 }}>
              {page.title}
            </h1>
          ) : null}
          {page.subtitle ? <h2>{page.subtitle}</h2> : null}
        </div>
        <ul className="grid-x">
          {medias.map((media) => (
            <li
              className="cell smal-12 medium-6 large-3"
              key={`media-container-${media.id}`}
              onClick={(e) => {
                fetchAlbums({
                  token,
                  filters: [
                    { property: "id", operator: "eq", value: media.id },
                  ],
                }).then((res) => {
                  console.log(res.data.data);
                  debugger;
                  if (res.data && res.data.data && res.data.data.length > 0)
                    this.setState({
                      loading: false,
                      currentAlbum: res.data.data[0],
                    });
                });
              }}
            >
              <Album
                openAlbumReveal={this.handleOpenAlbumReveal}
                album={media}
                language={this.props.page.language}
              />
            </li>
          ))}
        </ul>
        {this.renderAlbumMedias()}
      </div>
    );
  }

  renderAlbumT3() {
    const { medias } = this.state;
    const { page } = this.props;
    const { language, template, asMagazine } = page;

    return (
      <div className={styles.bloc}>
        {page.title ? (
          <h1 style={{ marginBottom: page.subtitle ? "1rem" : "2rem" }}>
            {page.title}
          </h1>
        ) : null}
        {page.subtitle ? <h2>{page.subtitle}</h2> : null}
        {medias.map((album) => {
          const titleAttr = `title${
            language.charAt(0).toUpperCase() + language.slice(1)
          }`;
          if (!album || !album.medias) {
            return null;
          }
          return (
            <div className={styles.albumT3} key={`album-container-${album.id}`}>
              <h3>{album[titleAttr]}</h3>
              {!album.medias ? null : (
                <ul className="grid-x">
                  {album.medias.map((media) => {
                    const ref = React.createRef();
                    return (
                      <li
                        className={classnames(
                          `cell smal-12 medium-6 large-3`,
                          this.state.currentMediaId === media.id
                            ? styles.mediaSelected
                            : ""
                        )}
                        key={`media-container-${media.id}`}
                        ref={ref}
                      >
                        <MediaContainer
                          media={media}
                          language={this.props.page.language}
                          template={template}
                          openViewer={this.handleOpenMediaViewer.bind(
                            this,
                            media,
                            ref
                          )}
                        />
                        {this.state.currentMediaId === media.id && (
                          <MediaViewer
                            closeOpenViewer={this.handleCloseMediaViewer}
                            asMagazine={asMagazine}
                          />
                        )}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          );
        })}
      </div>
    );
  }

  renderAlbumT4() {
    const { medias } = this.state;
    const { page } = this.props;
    const { language, asMagazine } = page;

    return (
      <div className={styles.bloc}>
        {page.title ? (
          <h1 style={{ marginBottom: page.subtitle ? "1rem" : "2rem" }}>
            {page.title}
          </h1>
        ) : null}
        {page.subtitle ? <h2>{page.subtitle}</h2> : null}
        {medias.map((album) => {
          const titleAttr = `title${
            language.charAt(0).toUpperCase() + language.slice(1)
          }`;
          if (!album || !album.medias) {
            return null;
          }
          return (
            <div className={styles.albumT3} key={`album-container-${album.id}`}>
              <h3>{album[titleAttr]}</h3>
              {!album.medias ? null : (
                <ul className="grid-x">
                  {album.medias.map((media) => {
                    const ref = React.createRef();
                    return (
                      <li
                        className={classnames(
                          `cell smal-12 medium-6 large-3`,
                          this.state.currentMediaId === media.id
                            ? styles.mediaSelected
                            : ""
                        )}
                        key={`media-container-${media.id}`}
                        ref={ref}
                      >
                        <MediaContainer
                          media={media}
                          language={this.props.page.language}
                          template="T2"
                          openViewer={this.handleOpenMediaViewer.bind(
                            this,
                            media,
                            ref
                          )}
                        />
                        {this.state.currentMediaId === media.id && (
                          <MediaViewer
                            closeOpenViewer={this.handleCloseMediaViewer}
                            asMagazine={asMagazine}
                          />
                        )}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          );
        })}
      </div>
    );
  }

  renderAlbumT5() {
    const { medias } = this.state;
    const { page } = this.props;
    const { language, template, asMagazine } = page;

    return (
      <div className={styles.bloc}>
        {page.title ? (
          <h1 style={{ marginBottom: page.subtitle ? "1rem" : "2rem" }}>
            {page.title}
          </h1>
        ) : null}
        {page.subtitle ? <h2>{page.subtitle}</h2> : null}
        {medias.map((album) => {
          const titleAttr = `title${
            language.charAt(0).toUpperCase() + language.slice(1)
          }`;
          if (!album || !album.medias) {
            return null;
          }
          return (
            <div className={styles.albumT3} key={`album-container-${album.id}`}>
              <h3>{album[titleAttr]}</h3>
              {!album.medias ? null : (
                <ul>
                  {album.medias.map((media) => {
                    const ref = React.createRef();
                    return (
                      <li
                        className={classnames(
                          this.state.currentMediaId === media.id
                            ? styles.mediaSelected
                            : ""
                        )}
                        key={`media-container-${media.id}`}
                        ref={ref}
                      >
                        <MediaContainer
                          media={media}
                          language={this.props.page.language}
                          template={template}
                          openViewer={this.handleOpenMediaViewer.bind(
                            this,
                            media,
                            ref
                          )}
                        />
                        {this.state.currentMediaId === media.id && (
                          <MediaViewer
                            closeOpenViewer={this.handleCloseMediaViewer}
                            asMagazine={asMagazine}
                          />
                        )}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          );
        })}
      </div>
    );
  }

  renderMediaByAlbumPage() {
    const { medias } = this.state;
    const { template, asMagazine } = this.props.page;
    const currentalbum = medias.length > 0 ? medias[0] : { medias: [] };

    if (template === "T6") {
      return this.renderMediaT6(currentalbum.medias, currentalbum);
    }

    return (
      <ul className="grid-x">
        {currentalbum.medias.map((media) => {
          const ref = React.createRef();
          return (
            <li
              className={classnames(
                `cell smal-12 medium-6 large-3`,
                this.state.currentMediaId === media.id
                  ? styles.mediaSelected
                  : ""
              )}
              key={`media-container-${media.id}`}
              ref={ref}
            >
              <MediaContainer
                media={media}
                language={this.props.page.language}
                template={template}
                openViewer={this.handleOpenMediaViewer.bind(this, media, ref)}
              />
              {this.state.currentMediaId === media.id && (
                <MediaViewer
                  closeOpenViewer={this.handleCloseMediaViewer}
                  asMagazine={asMagazine}
                />
              )}
            </li>
          );
        })}
      </ul>
    );
  }

  renderEventPage() {
    const { events, myEventsType } = this.state;

    /*let passed = [];
    let notYet = [];
    let eventsTime = [];
    let eventsPassedTime = [];

    for (let i = 0; i < events.length; i++) {
      let help = new Date(events[i].eventDate).getTime();
      if (help < new Date().getTime()) {
        passed.push({ ...events[i] });
        eventsPassedTime.push(help);
      } else {
        notYet.push({ ...events[i] });
        eventsTime.push(help);
      }
    }

    let sortedPassedEvn = [...passed];
    for (let i = 0; i < sortedPassedEvn.length - 1; i++) {
      for (let j = i + 1; j < sortedPassedEvn.length; j++) {
        if (eventsPassedTime[j] <= eventsPassedTime[i]) {
          let help = { ...sortedPassedEvn[j] };
          sortedPassedEvn[j] = { ...sortedPassedEvn[i] };
          sortedPassedEvn[i] = help;
          let help1 = eventsPassedTime[j];
          eventsPassedTime[j] = eventsPassedTime[i];
          eventsPassedTime[i] = help1;
        }
      }
    }

    let sortedEvn = [...notYet];
    for (let i = 0; i < sortedEvn.length - 1; i++) {
      for (let j = i + 1; j < sortedEvn.length; j++) {
        if (eventsTime[j] <= eventsTime[i]) {
          let help = { ...sortedEvn[j] };
          sortedEvn[j] = { ...sortedEvn[i] };
          sortedEvn[i] = help;
          let help1 = eventsTime[j];
          eventsTime[j] = eventsTime[i];
          eventsTime[i] = help1;
        }
      }
    }

    sortedEvn = [...sortedEvn, ...sortedPassedEvn];*/

    return (
      <ul className="grid-x">
        {events.map((event) => (
          <li
            className="cell smal-12 medium-4 large-3"
            key={`event-container-${event.id}`}
          >
            <EventComponent
              event={event}
              language={this.props.page.language}
              myEventsType={myEventsType}
            />
          </li>
        ))}
      </ul>
    );
  }

  renderCyclePage() {
    const { cycles } = this.state;

    if (!cycles || cycles.length === 0) {
      return null;
    }

    return (
      <div className="grid-x">
        {cycles.map((cycle) => (
          <CycleComponent
            cycle={cycle}
            language={this.props.page.language}
            key={`event-container-${cycle.id}`}
          />
        ))}
      </div>
    );
  }

  handleOpenAlbumReveal = (album, e) => {
    this.setState({ currentAlbum: { ...album } });
  };

  renderAlbumMedias() {
    const { currentAlbum } = this.state;
    const { language } = this.props.page;
    const titleAttr = `title${
      language.charAt(0).toUpperCase() + language.slice(1)
    }`;

    if (
      !currentAlbum ||
      !currentAlbum.medias ||
      currentAlbum.medias.length === 0
    ) {
      return null;
    }
    return (
      <Modal
        isOpen={!!currentAlbum}
        onRequestClose={() => this.setState({ currentAlbum: null })}
        closeTimeoutMS={200}
        contentLabel="Album medias"
        style={modalStyles}
      >
        <div className={styles.modalContent}>
          <div className={styles.modalClose}>
            <IconArrowLeft
              onClick={() => this.setState({ currentAlbum: null })}
            />
            {currentAlbum ? currentAlbum[titleAttr] : ""}
          </div>
          {this.renderMediaT6(currentAlbum.medias, currentAlbum)}
          {/* <div className={styles.modalBody}>
            <ul className="grid-x">
              {currentAlbum.medias.map((media) => (
                <li
                  className="cell smal-12 medium-6 large-3"
                  key={`media-container-${media.id}`}
                >
                  <MediaContainer
                    media={media}
                    language={this.props.page.language}
                  />
                </li>
              ))}
            </ul>
          </div> */}
        </div>
      </Modal>
    );
  }

  render() {
    const { loading } = this.state;
    const { page } = this.props;

    if (!page) {
      return (
        <div
          style={{
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          {_("Loading...")}
        </div>
      );
    }

    if (page && page.resource === "BLOG") {
      return this.renderBlogPage();
    }

    if (page && page.resource === "WEBTOOL") {
      return this.renderWebtoolPage();
    }

    if (page && page.resource === "MEDIA") {
      let help = null;
      if (page.filters) {
        help = !Array.isArray(page.filters)
          ? JSON.parse(page.filters)
          : page.filters;
      }
      if (
        help &&
        help.length > 0 &&
        help.filter((f) => f.property === "docType" && f.value[0] === "ALBUM")
          .length > 0
      ) {
        return this.renderAlbumPage();
      }
      return this.renderMediaPage();
    }
    if (page && page.resource === "ALBUM") {
      return this.renderMediaByAlbumPage();
    }
    if (page && page.resource === "EVENT") {
      let help = null;
      if (page.filters) {
        help = !Array.isArray(page.filters)
          ? JSON.parse(page.filters)
          : page.filters;
      }

      if (
        help &&
        help.length > 0 &&
        help.filter((f) => f.value === "cycle").length > 0
      ) {
        return this.renderCyclePage();
      }
      return this.renderEventPage();
    }
    if (page && page.resource === "EMAILING") {
      return this.renderEmailingPage();
    }
    if (page && page.resource === "DASHBOARD") {
      return this.renderDashboardPage();
    }
    if (page && page.resource === "PUBLIC_PORTAL") {
      return this.renderPublicPortalPage();
    }

    return null;
  }
}
