import React, { Component } from "react";
// import { convertFromRaw } from "draft-js";
import { connect } from "react-redux";
import classnames from "classnames";

import styles from "./Webtool.module.scss";

export class Webtool extends Component {
  renderDescription() {
    const { lng, webtool } = this.props;
    const descAttr = `description${lng.charAt(0).toUpperCase() + lng.slice(1)}`;
    let description = webtool[descAttr];

    if (!description) {
      return;
    }

    try {
      description = JSON.parse(description);
    } catch (e) {}

    if (typeof description === "string") {
      return <p>{description}</p>;
    }

    // const contentState = convertFromRaw(description);
    // const text = contentState.getPlainText();
    // return <p>{text}</p>;
    return <p>Desc</p>;
  }

  render() {
    const { webtool, lng, navCommunity, size } = this.props;

    const nameAttr = `name${lng.charAt(0).toUpperCase() + lng.slice(1)}`;
    const titleAttr = `title${lng.charAt(0).toUpperCase() + lng.slice(1)}`;
    const urlAttr = `urlName${lng.charAt(0).toUpperCase() + lng.slice(1)}`;

    const { id, icon, category = {}, organization, tags } = webtool;

    const hasCommunity = organization && organization.id !== navCommunity?.id;

    const helpSize = size || "small-12 medium-4 large-3";

    const domaine = webtool[urlAttr]
      ? webtool[urlAttr].split("/")[
          webtool[urlAttr].indexOf("http") !== -1 ? 2 : 0
        ]
      : "";

    const iconClass = icon ? `webtool-${icon.label}` : "icon-layer";

    return (
      <div
        className={styles.webtoolContent}
        style={{ borderColor: category.colorCode }}
      >
        <div className={styles.category}>
          {hasCommunity ? (
            <h3 style={{ borderColor: category.colorCode }}>
              {organization.abbreviation
                ? organization.abbreviation
                : organization.name}
            </h3>
          ) : (
            <i />
          )}
          <span style={{ backgroundColor: category.colorCode }}>
            {category[nameAttr]}
          </span>
        </div>
        <div className={styles.infos}>
          <div className={classnames(styles.content)}>
            <i className={classnames(`icon ${iconClass}`, styles.icon)} />
            <div className={styles.textes}>
              <h2>{webtool[titleAttr]}</h2>
              <span
                className={styles.domaine}
                style={{ color: category.colorCode }}
              >
                <i className="icon icon-globe" />
                {domaine}
              </span>
              {this.renderDescription()}
            </div>
            <div className={styles.actions}>
              <ul>
                <li>
                  <a target="_blanc" href={webtool[urlAttr]}>
                    <i className="icon icon-link" />
                  </a>
                </li>
              </ul>
            </div>
          </div>

          {tags ? (
            <ul className={styles.tags}>
              {tags.map((tag) => (
                <li key={`tag-${tag.id}`}>{tag[nameAttr]}</li>
              ))}
            </ul>
          ) : null}
        </div>
      </div>
    );
  }
}

// Connected Component
const mapStateToProps = (state) => ({
  navCommunity: state.auth.navCommunity,
});

export default connect(mapStateToProps)(Webtool);
