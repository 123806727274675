export default {
  "Your registration was successful":
    "Votre inscription a été effectué avec succès",
  "Subscribe to our newsletter": "Inscrivez-vous à notre Newsletter",
  "Receive all the news published by our community":
    "Recevez toutes l'actualité publier par notre communauté",
  Register: "S'inscrire",
  Errors: "Erreurs",
  "You must provide a valid email address.":
    "Vous devez fournir une adresse e-mail valide.",
};
