import {
  TTP_API_URL,
  TTP_BLOG_URL,
  TTP_EVENT_URL,
  TTP_FORUM_URL,
  TTP_MEDIA_URL,
  COMMUNITY_NAME_MAX_LENGTH,
  COMMUNITY_DEFAULT_LOGO_URL,
} from "config";
import { slugify, addLandaSize } from "utils";

export const getCommunityDisplayName = (community) => {
  if (!community) {
    return "";
  }

  return (
    community.abbreviation ||
    (community.name.length <= COMMUNITY_NAME_MAX_LENGTH
      ? community.name
      : community.name.substring(0, COMMUNITY_NAME_MAX_LENGTH) + " ...")
  );
};

export const getCommunityRoute = (community) => {
  if (!community) {
    return "";
  }
  return `/community/${
    community.url ? community.url : slugify(community.name)
  }/${community.id}`;
};

export const getCommunityLogoUrl = (community) => {
  if (!community) {
    return "";
  }
  return community.avatarUrl
    ? addLandaSize(community.avatarUrl, 600)
    : community.avatarWebPath
    ? `${TTP_API_URL}/${community.avatarWebPath}`
    : COMMUNITY_DEFAULT_LOGO_URL;
};

export const getAllowedMediaTypes = (community) => {
  if (!community) {
    return getAllAllowedMediaTypes();
  }
  if (
    community &&
    community.mediaPreferences &&
    community.mediaPreferences.generalTypes
  ) {
    return community.mediaPreferences.generalTypes.split(",");
  }

  return [];
};

export const getAllAllowedMediaTypes = () => {
  return ["IMAGE", "VIDEO", "PDF", "PPT"];
};

export const getAllowedLanguages = (community) => {
  if (
    community &&
    community.mediaSettings &&
    community.mediaSettings.preferences &&
    community.mediaSettings.preferences.languages
  ) {
    return community.mediaSettings.preferences.languages.split(",");
  }

  return [];
};

export const getUserMediaRole = (community, type) => {
  if (community && community.mediaSettings && community.mediaSettings.roles) {
    return community.mediaSettings.roles[`${type.toLowerCase()}Role`];
  }

  return null;
};

export const getUserAllowedLanguages = (community) => {
  if (
    community &&
    community.mediaSettings &&
    community.mediaSettings.roles &&
    community.mediaSettings.roles.preferences &&
    community.mediaSettings.roles.preferences.languages !== undefined
  ) {
    return community.mediaSettings.roles.preferences.languages.split(",");
  }

  return getAllowedLanguages(community);
};

export const DEFAULT_LAYOUT_SETTINGS = [
  { sectionId: "blog", order: 1, enabled: true },
  { sectionId: "event", order: 2, enabled: true },
  { sectionId: "forum", order: 3, enabled: true },
  { sectionId: "media", order: 4, enabled: true },
];

export function getCommunityLayoutSettings(community) {
  const preferences = (community && community.hubPreferences) || {};
  const layoutSettings = preferences.layout_settings || DEFAULT_LAYOUT_SETTINGS;

  return layoutSettings;
}

export const DEFAULT_DRAWERS_SETTINGS = [
  { app: "BLOG", label: "Blog", url: TTP_BLOG_URL, enabled: true },
  { app: "EVENT", label: "Event", url: TTP_EVENT_URL, enabled: true },
  { app: "MEDIA", label: "Media", url: TTP_MEDIA_URL, enabled: true },
  { app: "FORUM", label: "Forum", url: TTP_FORUM_URL, enabled: true },
];

export function getCommunityDrawersSettings(community) {
  const preferences = (community && community.hubPreferences) || {};
  const drawersSettings = preferences.drawers || DEFAULT_DRAWERS_SETTINGS;

  return drawersSettings;
}
